import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import tracking from '@sportson/core-web/components/Tracking';
import Events from '@sportson/core-web/libs/Events';
import { BasketEvents } from '@sportson/core-web/libs/Events/constants';
import DeleteBasketItem from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/DeleteBasketItem';
import setBasketItemsAmountCookie from '@sportson/core-web/state/models/Basket/utils/setBasketItemsAmountCookie';
import store from '@sportson/core-web/state/store';
import buildCartProductTree from '@sportson/core-web/utils/buildCartProductTree';
import getErrorMesage from '@sportson/core-web/utils/getErrorMessage';

// import { sendBasketToVoyado } from '@sportson/core-web/components/Tracking/VoyadoClient';
// import addMemberPromotions from './addMemberPromotions';

export interface DeleteBasketItemData {
    basketId: string | number;
    lineNo: string | number;
}

export default createAsyncThunk(
    `basket/product/delete`,
    async (data: DeleteBasketItemData, { getState, dispatch, rejectWithValue, fulfillWithValue }) => {
        const mutexLock = await mutex('basket');
        try {
            const { basketId, lineNo } = data;
            const { basket } = store.getState().ecommerce;
            const basketItems = basket.items || [];

            const basketItem = basketItems.find(({ lineNo: itemLineNo }) => itemLineNo === lineNo) ?? {};

            const trackingData = {
                removedItem: basketItem,
                eventId: tracking.getUniqueId(),
                basketId,
            };

            const response = await DeleteBasketItem(basketId, lineNo);
            const items = response.data.items || [];
            const basketItemsTree = buildCartProductTree(items);
            setBasketItemsAmountCookie(basketItemsTree);

            mutexLock();
            return fulfillWithValue({
                ...response.data,
                items: basketItemsTree,
                trackingData,
            });
        } catch (error) {
            mutexLock();
            return rejectWithValue({ error: getErrorMesage(error) });
        }
    },
);

const pending = (state, action) => {
    state.status = 'pending';
};

const fulfilled = (state, action) => {
    const camelizedData = objectKeysToCamelCase(action.payload, {
        recursive: true,
        modifyInputObject: false,
    });

    try {
        Events.trigger(BasketEvents.PRODUCT_REMOVED, {
            basketId: camelizedData.trackingData.basketId,
            item: camelizedData.trackingData.removedItem,
            eventId: camelizedData.trackingData.eventId,
        });
    } catch (e) {
        //
    }

    return {
        ...state,
        ...camelizedData,
        status: 'idle',
    };
};

const rejected = (state, action) => {
    console.error(action.payload);
    return {
        ...state,
        status: 'idle',
    };
};

export const deleteBasketItemStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
