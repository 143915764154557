import React from 'react';

interface CrawlerMenuItemProps {
    menuItems: CrawlerMenuItem[];
    level?: number;
}

interface CrawlerMenuItem {
    link: CrawlerLink;
    links?: CrawlerMenuItem[];
}

interface CrawlerLink {
    linkId: string;
    linkSeoIndex: boolean;
    linkTitle: string;
    linkType: string;
    text: string;
    url: string;
}

const CrawlerLinks: React.FC<CrawlerMenuItemProps> = ({ menuItems }) => (
    <>
        {menuItems.map((item) => (
            <>
                <a key={item.link.linkId} href={item.link.url}>
                    {item.link.linkTitle}
                </a>

                {item.links && <CrawlerLinks menuItems={item.links} />}
            </>
        ))}
    </>
);

export default CrawlerLinks;
