import filters from './filters';
import sorts from './sorts';

export const CONSTANTS = {
    APPLICATION_ID: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    API_KEY: process.env.REACT_APP_ALGOLIA_API_KEY,
    PREFIX: process.env.REACT_APP_ALGOLIA_PREFIX || '',
};

export default {
    filters,
    sorts,
};
