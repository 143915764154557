import styled from '@grebban/style-system-react';
import { useHeaderNavigationContext } from '@sportson/core-web/contexts/HeaderNavigationContext';

import { useTranslation } from 'react-i18next';
import ChevronLeft from 'assets/media/icons/ChevronLeft';
import Text from 'components/Text';
import ChevronRight from 'assets/media/icons/ChevronRight';
import { type WordpressACFGrebCommerceLink } from 'libs/wordpress/types/acf/fields';
import { type ICMSIcon } from './TopNav';

const Div = styled('div')();

const MenuWrapper = styled('div')`
    z-index: 99;
    flex-direction: column;
    position: absolute;
    top: var(--header-height);
    left: 0;
    width: 100%;
    background-color: var(--color-base-white);
    height: calc(100dvh - var(--header-height));
    overflow: auto;
    visibility: hidden;

    &.open {
        display: flex;
        visibility: visible;
    }
`;

const BackButton = styled('button')`
    display: flex;
    gap: 4px;
    color: var(--color-neutrals-500);
    padding: 12px 16px;
`;

const Section = styled('div')`
    display: flex;
    flex-direction: column;
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-neutrals-200);
    }

    &.back-button {
        display: grid;
        grid-template-rows: 0fr;
        transition: all var(--transition-primary-fast);
        &.open {
            grid-template-rows: 1fr;
        }
    }
`;

const DirectLink = styled('a')`
    padding: 10px 0;
    & p {
        display: inline;
    }
`;

const MenuLinkButton = styled('button')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
`;

const MenuLink = styled('a')``;

const Icon = styled('img')`
    width: 16px;
    height: 16px;
`;

interface INavigationLinks {
    link: { url: string; text: string };
    links?: {
        link: { url: string; text: string };
        links?: { link: { url: string; text: string } }[];
        column: string;
    }[];
}

interface Props {
    menuOpen: boolean;
    menuLinks: INavigationLinks[];
    guideCustomPages: {
        guideLinks: {
            icon: ICMSIcon;
            link: WordpressACFGrebCommerceLink<true, true>;
        }[];
        customPageLinks: {
            link: WordpressACFGrebCommerceLink<true, true>;
        }[];
    };
    rightLinks: {
        link: WordpressACFGrebCommerceLink<true, true>;
    }[];
}

const getCurrentLinks = (links: any, searchText: string): any => {
    for (const linkItem of links) {
        if (linkItem.link.text === searchText) {
            return linkItem;
        }
        if (linkItem.links) {
            const nestedLink = getCurrentLinks(linkItem.links, searchText);
            if (nestedLink) {
                return nestedLink;
            }
        }
    }
    return undefined;
};

const MobileMenu = ({ menuOpen, menuLinks, guideCustomPages, rightLinks }: Props) => {
    const { t } = useTranslation();
    const { headerNavigationState, setHeaderNavigationState } = useHeaderNavigationContext();
    const subMenu = headerNavigationState.activeSubMenu;
    const { guideLinks, customPageLinks } = guideCustomPages || {};
    const activeMenuItem = !subMenu.length ? menuLinks : getCurrentLinks(menuLinks, subMenu);
    const currentMenuitems = Array.isArray(activeMenuItem) ? activeMenuItem : activeMenuItem?.links;

    if (!menuLinks) {
        return null;
    }

    return (
        <MenuWrapper className={menuOpen ? 'open ' : ''}>
            <div>
                <Section className={`back-button ${subMenu ? 'open' : ''}`}>
                    {!!subMenu.length && (
                        <Div overflow="hidden">
                            <BackButton
                                onClick={() =>
                                    setHeaderNavigationState({
                                        ...headerNavigationState,
                                        activeSubMenu: headerNavigationState.parentMenuName
                                            ? headerNavigationState.parentMenuName
                                            : '',
                                        parentMenuName: null,
                                    })
                                }
                            >
                                <ChevronLeft height="12px" width="12px" color="var(--color-neutrals-500" />

                                <Text typography="UI/14_100_0_450">
                                    {headerNavigationState.parentMenuName
                                        ? headerNavigationState.parentMenuName
                                        : t('main_menu')}
                                </Text>
                            </BackButton>
                        </Div>
                    )}
                </Section>
                <Section p={['20px 12px 50px 12px', null, null, null, null, '20px 40px 50px 40px']} flex="1">
                    {!!headerNavigationState.activeSubMenu && (
                        <Div>
                            <Div pb="20px" gap="4px" display="flex" justifyContent="space-between">
                                <Text typography="UI/24_100_0_450">{activeMenuItem.link?.text}</Text>
                                <MenuLink
                                    color="var(--color-neutrals-500)"
                                    href={activeMenuItem.link?.url}
                                    onClick={() =>
                                        setHeaderNavigationState({ ...headerNavigationState, mobileMenuActive: false })
                                    }
                                >
                                    <Text typography="UI/16_100_0_450">{t('show_all')}</Text>
                                </MenuLink>
                            </Div>
                        </Div>
                    )}
                    {(currentMenuitems || []).map((link) => {
                        const isSubMenu = !!link.links;
                        const fontStyle = `UI/${subMenu ? '16' : '24'}_100_0_450`;

                        return isSubMenu ? (
                            <MenuLinkButton
                                key={`mobile-${link.link.linkId}-${link.link.text}`}
                                onClick={() =>
                                    setHeaderNavigationState({
                                        ...headerNavigationState,
                                        parentMenuName: subMenu,
                                        activeSubMenu: link.link.text,
                                    })
                                }
                            >
                                <Text typography={fontStyle}>{link.link.text}</Text>
                                {!!link.links && (
                                    <ChevronRight height="12px" width="12px" color="var(--color-neutrals-300)" />
                                )}
                            </MenuLinkButton>
                        ) : (
                            <MenuLink
                                key={`mobile-${link.link.linkId}-${link.link.text}`}
                                p="8px 0px"
                                href={link?.link.url}
                            >
                                <Text typography={fontStyle}>{link.link.text}</Text>
                            </MenuLink>
                        );
                    })}
                </Section>
                {rightLinks && (
                    <Section p="16px 8px">
                        {(rightLinks || []).map(({ link }) => (
                            <DirectLink key={`mobile-${link.linkId}-${link.text}`} href={link.url}>
                                <Text typography="UI/16_100_0_450">{link.text}</Text>
                            </DirectLink>
                        ))}
                    </Section>
                )}
                {customPageLinks && (
                    <Section p="16px 8px">
                        {(customPageLinks || []).map(({ link }) => (
                            <DirectLink key={`mobile-${link.linkId}-${link.text}`} href={link.url}>
                                <Text typography="UI/16_100_0_450">{link.text}</Text>
                            </DirectLink>
                        ))}
                    </Section>
                )}
                <Section p="16px">
                    {(guideLinks || []).map((link) => (
                        <DirectLink
                            key={`mobile-${link.link.linkId}-${link.link.text}`}
                            display="flex"
                            gap="8px"
                            alignItems="center"
                            href={link.link.url}
                        >
                            <Icon src={link.icon.url} alt={link.icon.alt} />
                            <Text typography="UI/14_100_0_450">{link.link.text}</Text>
                        </DirectLink>
                    ))}
                </Section>
            </div>
        </MenuWrapper>
    );
};

export default MobileMenu;
