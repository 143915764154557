import React from 'react';
import sanitize from 'sanitize.css';
import { Global, css } from '@emotion/core';
import { themes } from 'config/branding/themes';
import { breakpoints } from 'config/breakpoints';
import styles from './styles.css';

const themeName = 'default'; // @TODO Fetch from redux
const theme = themes[themeName];

const Styles = () => (
    <Global
        styles={css`
            :root {
                --current-breakpoint: '${breakpoints[0].label}';
                ${Object.entries(theme.statics)
                    .map(([key, value]) => `${key}: ${value};`)
                    .join('')}
            }

            ${breakpoints.map(
                (breakpoint) => `
        @media screen and (min-width: ${breakpoint.from}) {
            :root {
                --current-breakpoint: '${breakpoint.label}';
                ${Object.entries(theme.responsives[breakpoint.label])
                    .map(([key, value]) => `${key}: ${value};`)
                    .join('')}
            }
        }
    `,
            )}

            ${sanitize}
    ${styles}
        `}
    />
);

export default Styles;
