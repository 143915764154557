import styled from '@grebban/style-system-react';

import Text from 'components/Text';
import Button from 'components/Button';
import { type WordpressFooterLinks } from 'libs/wordpress/types/contentSystem/groups';

const LinkSection = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;
    width: 100%;
`;

const LinksContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    height: 40px;
`;

type FeaturedLinks = WordpressFooterLinks;

export const FeaturedLinks = ({ linkSections }: FeaturedLinks) => {
    if (!linkSections || linkSections?.length === 0) {
        return null;
    }
    return (
        <>
            {linkSections.map(({ heading, links }) => (
                <LinkSection key={heading}>
                    <Text
                        as="h3"
                        textAlign="left"
                        color="var(--color-brand-yellow)"
                        typography="UI/12_100_0_450_uppercase"
                        marginBottom="8px"
                    >
                        {heading}
                    </Text>
                    <LinksContainer>
                        {links.map(({ link: { text, url } }) => (
                            <Button
                                key={text}
                                to={url}
                                theme="secondaryDark"
                                variant="text"
                                color="var(--color-base-white)"
                                padding="12px 16px 12px 16px"
                            >
                                {text}
                            </Button>
                        ))}
                    </LinksContainer>
                </LinkSection>
            ))}
        </>
    );
};
