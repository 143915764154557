export type MobileBreakpointLabels = 'mobile.sm' | 'mobile.md' | 'mobile.lg';
export type TabletBreakpointLabels = 'tablet.sm' | 'tablet.lg';
export type DesktopBreakpointLabels = 'desktop.sm' | 'desktop.md' | 'desktop.lg';
export type ValidBreakpointLabels = MobileBreakpointLabels | TabletBreakpointLabels | DesktopBreakpointLabels;

export type BreakpointValue = `${string}${'px' | 'em' | 'vw'}` | 0;

export interface IBreakpoint {
    label: ValidBreakpointLabels;
    from: BreakpointValue;
    to: BreakpointValue;
}

// Refers to the breakpoint decided here:
// https://www.notion.so/grebban/Breakpoints-cc8a13ef3c494abeb8b1acce9e2ea13f

export const breakpoints: IBreakpoint[] = [
    { label: 'mobile.sm', from: 0, to: '22.499em' }, // 0-359px
    { label: 'mobile.md', from: '22.5em', to: '47.999em' }, // 360-767px
    { label: 'mobile.lg', from: '48em', to: '63.999em' }, // 768-1023px
    { label: 'tablet.sm', from: '64em', to: '79.999em' }, // 1024-1279px
    { label: 'tablet.lg', from: '80em', to: '85.37499em' }, // 1280-1365px
    { label: 'desktop.sm', from: '85.375em', to: '95.999em' }, // 1366-1535px
    { label: 'desktop.md', from: '96em', to: '119.999em' }, // 1536-1919px
    { label: 'desktop.lg', from: '120em', to: '100vw' }, // 1920px-infinite
];

export const breakpointLabels = breakpoints.map((breakpoint) => breakpoint.label);

export const breakpointValues = breakpoints.map((breakpoint) => breakpoint.from);

export const getBreakpoint = (breakpointLabel: ValidBreakpointLabels): IBreakpoint | null => {
    const breakpoint = breakpoints.find((bp) => bp.label === breakpointLabel) ?? null;
    if (!breakpoint) {
        console.error(`The breakpoint label ${breakpointLabel} does not exist.`);
    }
    return breakpoint;
};
