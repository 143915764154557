import { type GridCssResponsiveVariables } from '../grid';
import { type RatiosCssResponsiveVariables } from '../ratios';
import {
    type EasingCssResponsiveVariables,
    type PacingCssResponsiveVariables,
    type TransitionsCssResponsiveVariables,
} from '../transitions';
import { type CssResponsiveVariables, type CssVariables } from '../types';
import { type ZIndexCssResponsiveVariables } from '../z-index';
import defaultTheme from './default';

interface ThemeVariables extends CssVariables<any> {
    // @TODO Extend the config interfaces
    // @TODO Add all the "static" ThemeVars here
    '--site-corridor-max-width': string; // @TODO Change this to Pixel|Percent|Vw
}
export interface Theme {
    statics: ThemeVariables;
    responsives: CssResponsiveVariables<
        GridCssResponsiveVariables &
            RatiosCssResponsiveVariables &
            PacingCssResponsiveVariables &
            EasingCssResponsiveVariables &
            TransitionsCssResponsiveVariables &
            ZIndexCssResponsiveVariables &
            Partial<ThemeVariables>
    >;
}
export interface Themes {
    default: Theme;
}

export const themes: Themes = {
    // Examples:
    // light: {},
    // dark: {},
    // xmas: {},
    default: defaultTheme,
};
export const validThemes = Object.keys(themes);

// @TODO Make it work with responsives as well or scrap?
// export const activateTheme = (themeName: string): void => {
//     if (validThemes.indexOf(themeName) === -1) {
//         return;
//     }
//     const theme = themes[themeName];
//     const root = document.documentElement;
//     Object.keys(theme).forEach(key => {
//         const value = theme[key];
//         root.style.setProperty(key, value);
//     });
// };

// @TODO Doesn't work?
// export const getCssVariableValue = (cssVariableName: string) => {
//     const root = document.documentElement;
//     return root.style.getPropertyValue(cssVariableName);
// };

export default themes;
