import AnimateHeight from '@grebban/react-core-components/AnimateHeight';
import styled from '@grebban/style-system-react';
import Text from 'components/Text';
import TextButton from 'components/TextButton';
import Plus from 'assets/media/icons/Plus';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import React, { useState } from 'react';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import { ChevronDown } from 'assets/media/icons/ChevronDown';

const StyledWrapper = styled('button')`
    width: 100%;
`;

const LabelWrapperButton = styled(TextButton)`
    align-items: center;
    display: flex;
    cursor: pointer;
    padding: 24px 0;
    width: 100%;
`;

const StyledText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 32px);
    text-overflow: ellipsis;
    display: block;

    &.wrap {
        white-space: normal;
        text-overflow: unset;
        text-align: left;
        word-wrap: normal;
        resize: none;
        box-sizing: border-box;
    }
`;

const StyledChildrenWrapper = styled('div')`
    padding-bottom: 12px;

    a {
        text-decoration: underline;
    }
`;

const StyledPlus = styled(Plus)`
    margin-top: 2px;
    #lineOne {
        transform-origin: center;
        transition: transform ${({ transition }) => transition};
        transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'none')};
    }
`;

// @TODO Remove the extend when style-package is fixed.
export interface AccordionProps extends Record<string, unknown> {
    borderBottom?: string | string[];
    borderTop?: string | string[];
    children: React.ReactNode | typeof React.Children;
    closeOnLabelClick?: boolean;
    icon?: React.ReactNode;
    label: string;
    open?: boolean;
    typography?: string | string[];
    iconColor?: CssVariable<CssColorKey>;
}

const Accordion: React.FunctionComponent<AccordionProps> = ({
    children,
    icon,
    label,
    borderBottom = '1px solid var(--color-neutrals-600)',
    borderTop = '1px solid var(--color-neutrals-600)',
    closeOnLabelClick = false,
    open = false,
    typography = 'UI/12_100_0_450_uppercase',
    wrappingLabel = false,
    hideBorder = false,
    iconColor = 'var(--color-brand-yellow)',
    ...rest
}) => {
    const [isOpen, setOpen] = useState(open);

    const animationTransition = 'var(--transition-primary)';

    const accordionIcon = icon || (
        <ChevronDown
            color={iconColor}
            $isOpen={isOpen}
            transform={isOpen && 'rotate(180deg)'}
            transition={animationTransition}
            ml="auto"
        />
    );

    const handleClick = (closeOnLabelClick) => {
        if (closeOnLabelClick) {
            setOpen(!isOpen);
        }
    };

    return (
        <StyledWrapper
            borderBottom={borderBottom}
            borderTop={borderTop}
            type="button"
            onClick={() => handleClick(!closeOnLabelClick)}
            {...rest}
        >
            <LabelWrapperButton type="button" onClick={() => handleClick(closeOnLabelClick)}>
                <Wysiwyg
                    data={label}
                    TextComponent={(props) => (
                        <StyledText
                            className={wrappingLabel ? 'wrap' : null}
                            userSelect="none"
                            typography={typography}
                            {...props}
                        />
                    )}
                />
                {accordionIcon}
            </LabelWrapperButton>
            <AnimateHeight minified={!isOpen} transition={animationTransition} maxHeight={0}>
                <StyledChildrenWrapper borderBottom={hideBorder ? 'none' : borderBottom}>
                    {children}
                </StyledChildrenWrapper>
            </AnimateHeight>
        </StyledWrapper>
    );
};

export default Accordion;
