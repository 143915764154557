import { createAsyncThunk } from '@reduxjs/toolkit';
import { cache, cacheKey } from '@sportson/core-server/util/ssrCache';
import ResolveApp from '@sportson/core-web/libs/GrebbCommerceAPI/Content/ResolveApp';
import { getState } from '@sportson/core-web/state';

export default createAsyncThunk('application/resolve', async (data: any) => {
    const { url = '', parameters = {}, origin } = data;
    const { currentHref } = getState().application;

    let resolvedUrl = url;

    if (url === '') {
        resolvedUrl = currentHref;
    }

    return cache(() => ResolveApp(resolvedUrl, parameters, origin), cacheKey(resolvedUrl, parameters, origin));
});
