import { FREIGHT_PRODUCT_TYPE, HANDLING_FEE_PART_NO, SALE_PRODUCT_NO } from 'config/constants';

// @TODO THIS FILE SHOULD BE REMOVED AND THIS FUNCTIONALITY SHOULD BE ADDED INTO THE REDUX, RIGHT NOW IT IS BEING RE-RENDERED SEVERAL TIMES WHICH IS NOT GOOD.

export default (basketItems) => {
    const tree = [];
    const basketItemsCopy = JSON.parse(JSON.stringify(basketItems));
    basketItemsCopy
        .filter(
            (item) =>
                item.type !== FREIGHT_PRODUCT_TYPE &&
                item.part_no !== SALE_PRODUCT_NO &&
                item.part_no !== HANDLING_FEE_PART_NO,
        )
        .forEach((item) => {
            if (!item.sub_products) {
                item.sub_products = [];
            }

            // Build sub_products (package items)
            if (item.parent_line_no && item.applied_promotions.length === 0) {
                let notIncludedInAnyParent = true;
                basketItemsCopy.forEach((parent) => {
                    if (parent.line_no === item.parent_line_no) {
                        if (!parent.sub_products) {
                            parent.sub_products = [];
                        }

                        if (parent.sub_products.indexOf(item) === -1 && parent.included_products) {
                            parent.included_products = parent.included_products.map((product) => {
                                notIncludedInAnyParent = false;
                                if (product?.variants.some((variant) => variant.part_no === item.part_no)) {
                                    return { ...product, ...item };
                                }
                                return product;
                            });
                        }
                    }
                });

                if (notIncludedInAnyParent) {
                    tree.push(item);
                }
            } else if (item.parent_line_no) {
                const parentItem = tree.find((basketItem) => basketItem.line_no === item.parent_line_no);
                tree.splice(tree.indexOf(parentItem) + 1, 0, item);
            } else {
                tree.push(item);
            }
        });

    return tree;
};
