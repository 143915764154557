import { get } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';

export default async (partNo) => {
    const response = await get(`/frontend/store/${partNo}/on-hand`, {
        application_id: getModel('application').site.applicationId,
        pageSize: 100,
    });

    return await response.body();
};
