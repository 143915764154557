import { createAsyncThunk } from '@reduxjs/toolkit';
import GetProductRating from '@sportson/core-web/libs/GrebbCommerceAPI/Products/GetProductRating';

export default createAsyncThunk(`page/product/rating`, async ({ partNo, url }, { getState }) => {
    const { applicationId } = getState().application;

    const response = await GetProductRating(partNo, null, url, applicationId, true);
    const { average_score: averageScore = 0, total_reviews: totalReviews = 0 } = response.data || {};

    return {
        averageScore,
        totalReviews,
    };
});

const pending = (state) => {
    state.data.product.reviewsData = { averageScore: 0, totalReviews: 0 };
};

const fulfilled = (state, action) => {
    state.data.product.reviewsData.averageScore = action.averageScore;
    state.data.product.reviewsData.totalReviews = action.totalReviews;
};

export const getProductRatingCallbacks = {
    pending,
    fulfilled,
};
