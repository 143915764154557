import Gtm from '@grebban/gtm';
import tracking from '@sportson/core-web/components/Tracking';
import DataLayer from './DataLayer';

export default ({ basketId, item }) => {
    try {
        if (!item) {
            return;
        }

        Gtm.addDataLayerEvent('remove_from_cart', {
            basket: {
                id: basketId,
            },
            ecommerce: {
                remove: {
                    products: [DataLayer.getProduct(item)],
                },
            },
            currency: item.currency || 'SEK',
            eventId: tracking.getUniqueId(),
        });
    } catch (e) {
        //
    }
};
