import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@grebban/style-system-react';
import Button from 'components/Button';
import Text from 'components/Text';
import { type WordpressACFGrebCommerceLink } from 'libs/wordpress/types/acf/fields';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const LinkButton = styled(Button)`
    border-color: var(--color-neutrals-600);

    &:hover {
        border-color: var(--button-secondary-dark-hover-border);
    }
    &:focus {
        outline: auto !important;
    }
    &:active {
        background-color: var(--button-secondary-dark-background);
    }
`;

const Icon = styled('img')`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`;

export interface ICMSIcon {
    alt: string;
    caption: string;
    description: string;
    title: string;
    url: string;
    meta: {
        filename: string;
        filesize: number;
        height: number;
        id: number;
        mimeType: string;
        name: string;
        ratio: number;
        width: number;
    };
}

const TopNav = () => {
    const topLinks: { links?: { icon: ICMSIcon; link: WordpressACFGrebCommerceLink<true, true> }[] } = useSelector(
        ({ application }) => application.header.data.navigation?.topNavigation,
    );

    if (!topLinks?.links) {
        return null;
    }

    return (
        <Wrapper>
            {topLinks.links.map(({ link, icon }) => (
                <LinkButton to={link.url} theme="secondaryDark">
                    <Icon src={icon.url} alt={icon.alt} />
                    <Text color="var(--color-base-white)" typography="UI/16_100_0_450">
                        {link.text}
                    </Text>
                </LinkButton>
            ))}
        </Wrapper>
    );
};

export default TopNav;
