import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import getUser, { getUserStateCallbacks } from './getUser';

export interface UserState {
    isFetching: boolean;
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    mobilePhone: string | null;
    street: string | null;
    zipCode: string | null;
    city: string | null;
}

// The initial state of this store.
export const initialState: UserState = {
    isFetching: false,
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    mobilePhone: null,
    street: null,
    zipCode: null,
    city: null,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signOut: () => {
            Cookies.remove('user_logged_in');
            return { ...initialState };
        },
    },

    extraReducers(builder) {
        builder
            .addCase(getUser.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(getUser.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                ...objectKeysToCamelCase(action.payload?.user || {}, {
                    recursive: true,
                    modifyInputObject: false,
                }),
            }))
            .addCase(getUser.rejected, getUserStateCallbacks.rejected);
    },
});

export const { signOut } = slice.actions;
export const { reducer } = slice;
