import Gtm from '@grebban/gtm';

export default ({ eventId, product, currencyCode }) => {
    try {
        if (!product) {
            return;
        }

        Gtm.addDataLayerEvent('product_click', {
            currencyCode,
            ecommerce: {
                click: {
                    // actionField: { list: data.list },
                    products: [product],
                },
            },
            eventId,
        });
    } catch (e) {
        //
    }
};
