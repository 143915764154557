import Events from '@grebban/events';

export const EVENTS = {
    WINDOW: {
        SCROLL: 'WINDOW.SCROLL',
        RESIZE: 'WINDOW.RESIZE',
    },
    DOCUMENT: {
        CLICK: 'DOCUMENT.CLICK',
        TOUCHEND: 'DOCUMENT.TOUCHEND',
    },
    APPLICATION: {
        RESOLVED: 'APPLICATION.RESOLVED',
        URL_UPDATE: 'NAVBAR.URL_UPDATE',
        SITE_MOUNTED: 'SITE.MOUNTED',
    },
    CUSTOMER: {
        CONSENT: 'CUSTOMER.CONSENT',
    },
    PAGE: {
        VIEW: 'ROUTE.VIEW',
        RESET: 'ROUTE.RESET',
        RESOLVED: 'ROUTE.RESOLVED',
    },
    NEWSLETTER: {
        SIGNUP: 'NEWSLETTER.SIGNUP',
    },
    ECOMMERCE: {
        BASKET: {
            // Basket.
            CREATED: 'BASKET.CREATED',
            SET: 'BASKET.SET',
            OPENED: 'BASKET.OPENED',
            CLOSED: 'BASKET.CLOSED',
            TOGGLED: 'BASKET.TOGGLED',
            RETRIEVED: 'BASKET.RETRIEVED',
            EMPTIED: 'BASKET.EMPTIED',
            REMOVED: 'BASKET.REMOVED',

            // Products.
            PRODUCT_ADDED: 'BASKET.PRODUCT_ADDED',
            PRODUCT_REMOVED: 'BASKET.PRODUCT_REMOVED',
            CHANGED: 'BASKET.AUTOMATIC_CHANGE',
            OUT_OF_STOCK: 'BASKET.OUT_OF_STOCK',

            // Vouchers.
            VOUCHER_ADDED: 'BASKET.VOUCHER_ADDED',
            VOUCHER_REMOVED: 'BASKET.VOUCHER_REMOVED',
            VOUCHER_FAILED: 'BASKET.VOUCHER_FAILED',
        },
        CHECKOUT: {
            VIEW: 'CHECKOUT.INIT',
            SHIPPING: 'CHECKOUT.SHIPPING',
            SHIPPING_LOADED: 'CHECKOUT.SHIPPING.LOADED',
            PAYMENT: 'CHECKOUT.PAYMENT',
            TRANSACTION: 'CHECKOUT.TRANSACTION',
            REFUND: 'CHECKOUT.REFUND',
            PAYMENT_LOADED: 'CHECKOUT.PAYMENT.LOADED',
        },
        PRODUCT: {
            VIEW: 'PRODUCT_DETAILED_PAGE.VIEW',
            CLICK: 'PRODUCT.CLICK',
            IMPRESSION: 'PRODUCT.IMPRESSION',
            ALGOLIA_IMPRESSION: 'PRODUCT.ALGOLIA_IMPRESSION',
        },
        PRODUCT_LISTING_PAGE: {
            FILTER_UPDATE: 'PRODUCT_LISTING_PAGE.FILTER.UPDATE',
            COUNT: 'PRODUCT.COUNT',
        },
    },
};

export default Events;
