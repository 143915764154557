import { post } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';
import { inServer } from '@sportson/core-web/constants';

export default async (partNumbers, applicationId) => {
    let response;

    if (inServer) {
        response = await post(`/frontend/product/partnos-store-availability?application_id=${applicationId}`, {
            part_nos: partNumbers,
            application_id: applicationId,
        });

        if (response) {
            return await response.body();
        }
    }
    response = await post('/frontend/product/partnos-store-availability', {
        part_nos: partNumbers,
        application_id: getModel('application').site.applicationId,
    });

    return await response.body();
};
