import { type Animation, type CssResponsiveVariables, type Curve, type Duration, type Transition } from './types';

export interface PacingCssResponsiveVariables {
    '--pacing-slow'?: Duration;
    '--pacing-fast'?: Duration;
    '--pacing-slide-indicator'?: Duration;
}
export interface EasingCssResponsiveVariables {
    '--easing-fast-to-slow'?: Curve;
}
export interface TransitionsCssResponsiveVariables {
    '--transition-primary-fast'?: Transition;
    '--transition-primary-slow'?: Transition;
}

export interface AnimationsCssResponsiveVariables {
    '--animation-infinite-spin'?: Animation;
}

export const transitionsCssResponsiveVariables: CssResponsiveVariables<
    PacingCssResponsiveVariables &
        EasingCssResponsiveVariables &
        TransitionsCssResponsiveVariables &
        AnimationsCssResponsiveVariables
> = {
    'mobile.sm': {
        '--pacing-slow': '0.3s',
        '--pacing-fast': '0.15s',
        '--pacing-slide-indicator': '4.0s',
        '--ease-out': 'cubic-bezier(0,0,.58,1)',
        '--transition-primary-fast': `var(--pacing-fast) var(--ease-out)`,
        '--transition-primary-slow': `var(--pacing-slow) var(--ease-out)`,
        '--animation-infinite-spin': `var(--spin) var(--pacing-slow) var(--linear-infinite)`,
        '--animation-slide-indicator': `var(--pacing-slide-indicator) linear`,
        '--spin': 'spin',
        '--linear-infinite': 'linear infinite',
    },
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {},
    'tablet.lg': {},
    'desktop.sm': {},
    'desktop.md': {},
    'desktop.lg': {},
};
