import loadable from '@loadable/component';
import Styles from '@sportson/core-web/App/Styles';
import ErrorCatcher from '@sportson/core-web/components/ErrorCatcher';
import SiteMeta from '@sportson/core-web/components/metadata/SiteMeta';
import OrganizationStructuredData from '@sportson/core-web/components/metadata/structuredData/OrganizationStructuredData';
import GTM from '@sportson/core-web/components/Tracking/GTM';
import { hydrateCompareProducts } from '@sportson/core-web/state/ecommerce/compare';
import Site from 'components/Site';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const ScrollRestore = loadable(() => import('@sportson/core-web/components/ScrollRestore'));

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hydrateCompareProducts());
    }, [dispatch]);

    return (
        <ErrorCatcher>
            <GTM />
            <Styles />
            <SiteMeta />
            <OrganizationStructuredData />
            <Site />
            <ScrollRestore />
        </ErrorCatcher>
    );
};

export default App;
