import { getState } from '@sportson/core-web/state';

export default () => {
    let returnValue = '';
    if (typeof window !== 'undefined') {
        returnValue = window.location.href;
    } else {
        returnValue = getState('application').site.currentHref;
    }
    return returnValue;
};
