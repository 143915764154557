import { buildQueryString, get, request } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { API_URL } from '@sportson/core-web/constants';

export default async (applicationId, path, parameters = {}, url) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);
    let slug = path;
    if (slug.length > 1 && slug.endsWith('/')) {
        slug = slug.slice(0, -1).toLowerCase();
    }

    const requestUrl = `/frontend/content/resolve-slug?application_id=${applicationId}&slug=${encodeURIComponent(
        slug,
    )}${builtParameters && `&${builtParameters}`}`;

    let response;
    if (!API_URL && url) {
        response = await request({ method: 'GET', path: requestUrl, baseUrl: url });
        if (response) {
            return await response.body();
        }
    }

    response = await get(requestUrl);

    return await response.body();
};
