import Path from '@grebban/react-core-components/svg/Path';
import PropTypes from 'prop-types';
import React from 'react';
import Svg from '@grebban/react-core-components/svg/Svg';

const ChevronRight = ({
    color = 'var(--color-base-black)',
    height = '11px',
    width = 'initial',
    viewBox = '0 0 6 11',
    ...rest
}) => (
    <Svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <Path
            d="M0.202772 9.77534C-0.0675907 10.0555 -0.0675907 10.5097 0.202772 10.7899C0.473135 11.07 0.91148 11.07 1.18184 10.7899L5.79723 6.00727C6.06759 5.72711 6.06759 5.27289 5.79723 4.99273L1.18184 0.210119C0.91148 -0.0700397 0.473135 -0.0700397 0.202772 0.210119C-0.0675907 0.490278 -0.0675907 0.944505 0.202772 1.22466L4.32862 5.5L0.202772 9.77534Z"
            fill={color}
        />
    </Svg>
);

ChevronRight.propTypes = {
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ChevronRight;
