import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Cookies from 'js-cookie';
import styled from 'libs/styled';
// import CookieDisclaimer from 'components/Cookies';

const Column = styled('div')`
    width: 100%;
    position: fixed;
    bottom: 0;
    background-size: cover;
    z-index: 99999;
    pointer-events: none;
`;

const NotificationWrapper = () => {
    const [isCookiesAccepted, setIsCookiesAccepted] = useState(Cookies.get('cookies_accepted'));

    return (
        <>
            <Helmet
                script={[
                    {
                        type: 'text/javascript',
                        innerHTML: `
                            if (typeof document !== 'undefined') {
                                const root = document.querySelector(':root');

                                root.style.setProperty('--display-cookie-consent', document.cookie.indexOf('cookies_accepted') !== -1 ? 'none' : 'block');
                            }
                        `,
                    },
                ]}
            />
            <Column>
                {/* <CookieDisclaimer
                    isCookiesAccepted={isCookiesAccepted}
                    onAcceptCookies={() => setIsCookiesAccepted(true)}
                /> */}
            </Column>
        </>
    );
};

export default NotificationWrapper;
