import Cookies from 'js-cookie';

export const COOKIES_CONSTANTS = {
    DEFAULT_LIFESPAN: 30,
    COOKIES_ACCEPTED_NAME: 'cookies_accepted',
    ECOMMERCE: {
        BASKET_COOKIE_NAME: 'basket_id',
        CAMPAIGN_COOKIE_NAME: 'campaign_id',
        CUSTOMER_SELECTED_COUNTRY_COOKIE_NAME: 'customer_selected_country',
        CUSTOMER_SELECTED_PRICELIST_COOKIE_NAME: 'customer_selected_pricelist',
        LAST_ORDER_COOKIE_NAME: 'last_order_id',
    },
    NEWSLETTER: {
        SIGNUP_COOKIE_NAME: 'newsletter_signed_up',
    },
};

export default Cookies;
