import CoreButton from '@grebban/react-core-components/Button';
import styled from '@grebban/style-system-react';
import Column from '@sportson/core-web/App/layouts/Column';
import Row from '@sportson/core-web/App/layouts/Row';
import Image from '@sportson/core-web/components/Image';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import { generateSrcSet, stormSrc } from '@sportson/core-web/libs/image';
import { clearCompareProducts, removeCompareProduct } from '@sportson/core-web/state/ecommerce/compare';
import { above } from '@sportson/core-web/utils/mediaqueries';
import Arrow from 'assets/media/icons/Arrow';
import { ChevronDown } from 'assets/media/icons/ChevronDown';
import Compare from 'assets/media/icons/Compare';
import Cross from 'assets/media/icons/Cross';
import { AnimateHeight } from 'components/AnimateHeight';
import Button from 'components/Button';
import Text from 'components/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type WordpressACFGrebCommerceLink } from 'libs/wordpress/types/acf/fields';

const Wrapper = styled('section')`
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    ${above['desktop.sm']} {
        flex-direction: row;
        gap: 16px;

        margin-top: 24px;
    }
`;
const ButtonText = styled('div')`
    display: flex;
    gap: 8px;
`;
const ShowButton = styled(CoreButton)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;
const RemoveButton = styled(CoreButton)``;

const Chevron = styled(ChevronDown)`
    &.closed {
        transform: rotate(180deg);
    }
`;

const MobileWrapper = styled('div')``;

const TransitionWrapper = styled('div')`
    position: fixed;
    bottom: 0;
    z-index: 9;

    left: 50%;
    transform: translate(-50%, 101%);
    width: calc(var(--site-corridor-max-width) - 24px);
    padding: 24px;

    background-color: var(--color-base-white);
    box-shadow: 0px -6px 12px 0px rgba(0, 0, 0, 0.15);

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    ${above['desktop.sm']} {
        width: calc(var(--site-corridor-max-width) - 48px);
    }

    transition: all var(--transition-primary-slow);
    &.fade {
        transform: ${({ showing }) => (showing ? 'translate(-50%, 0%)' : 'translate(-50%, 101%')};
    }
`;

const Product = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    background-color: var(--color-neutrals-100);
    padding: 8px 16px 8px 8px;

    ${above['desktop.sm']} {
        flex: 1;
    }
`;

const ImageWrapper = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 32px;
    height: 32px;

    overflow: hidden;
    border-radius: var(--corner-radius-round, 4px);

    & > * {
        z-index: 10;
        mix-blend-mode: multiply;
    }
`;

const ProductSection = styled(Column)`
    gap: 8px;

    ${above['desktop.sm']} {
        gap: 16px;
        flex-direction: row;
    }
`;

const ButtonWrapper = styled(Row)`
    margin-top: 24px;
    flex: 1;

    ${above['desktop.sm']} {
        margin-top: 0;
    }
`;
const MAX_PRODUCTS = 4;

interface CompareProductsProps {
    showing: boolean;
    products: { id: string; image: string; name: string }[];
}

export const CompareProducts: React.FC<CompareProductsProps> = ({ showing, products }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showProducts, setShowProducts] = useState(false);
    const comparePageLink: WordpressACFGrebCommerceLink<true, true> = useAppSelector(
        ({ application }) => application.config.options.specifiedPages.specifiedPages.compare,
    );

    const disableCompare = products.length < 2;

    return (
        <TransitionWrapper className="fade" showing={showing}>
            <MobileWrapper>
                <ShowButton onClick={() => setShowProducts(!showProducts)}>
                    <ButtonText>
                        <Compare color="var(--color-base-black)" />
                        <Text typography="UI/16_100_0_450">{t('compare.compare_products')}</Text>
                        <Text typography="UI/16_100_0_450">
                            {products.length}/{MAX_PRODUCTS}
                        </Text>
                    </ButtonText>
                    <Chevron className={showProducts ? '' : 'closed'} color="var(--color-base-black)" size="small" />
                </ShowButton>
                <AnimateHeight transition="var(--transition-primary-fast)" isOpen={showProducts}>
                    <Wrapper>
                        <ProductSection>
                            {products.map((product) => {
                                const widths = [200];
                                const imageSrc = stormSrc(`${product.image}.jpg`, {
                                    auto: 'format',
                                    q: 80,
                                    ar: '1',
                                    fit: 'fill',
                                });
                                const srcSet = generateSrcSet(imageSrc, widths);

                                return (
                                    <Product key={product.id}>
                                        <ImageWrapper>
                                            <Image
                                                src={{ url: imageSrc, width: widths }}
                                                srcSet={srcSet}
                                                fallbackImageAsPlaceholder={false}
                                                alt={product.name}
                                                title={product.name}
                                            />
                                        </ImageWrapper>
                                        <Text typography="UI/12_100_0_450" flex="1">
                                            {product.name}
                                        </Text>
                                        <RemoveButton
                                            onClick={() =>
                                                dispatch(
                                                    removeCompareProduct({
                                                        id: product.id,
                                                        name: '',
                                                        image: '',
                                                    }),
                                                )
                                            }
                                        >
                                            <Cross size="small" />
                                        </RemoveButton>
                                    </Product>
                                );
                            })}
                        </ProductSection>

                        <ButtonWrapper gap="8px">
                            <Button theme="secondary" onClick={() => dispatch(clearCompareProducts())}>
                                {t('compare.clear')}
                            </Button>
                            <Button
                                theme="primary"
                                flex="1"
                                disabled={disableCompare}
                                url={comparePageLink && comparePageLink.url}
                            >
                                <Text typography="UI/16_100_0_450">{t('compare.compare_products')}</Text>
                                <Arrow direction="right" width="12px" height="12px" />
                            </Button>
                        </ButtonWrapper>
                    </Wrapper>
                </AnimateHeight>
            </MobileWrapper>
        </TransitionWrapper>
    );
};
