import { getState } from '@sportson/core-web/state';
import { getMediaUrl } from '@sportson/core-web/utils/getMediaUrl';
import { breakpointValues } from '@sportson/core-web/config/breakpoints';
import { inBrowser } from '@sportson/core-web/constants';

interface StormParams {
    [key: string]: string | number | undefined;
    anchor?:
        | 'topleft'
        | 'topcenter'
        | 'topright'
        | 'middleleft'
        | 'middlecenter'
        | 'middleright'
        | 'bottomleft'
        | 'bottomcenter'
        | 'bottomright';
    bgcolor?: string;
    f?: 'gif' | 'jpg' | 'png' | 'webp';
    h?: number;
    m?: 'max' | 'pad' | 'crop' | 'carve' | 'stretch';
    q?: number;
    scale?: 'downscaleonly' | 'upscaleonly' | 'both' | 'upscalecanvas';
    w?: number;
}

interface ImgixParams {
    [key: string]: string | number | undefined;
    auto?: 'compress' | 'enhance' | 'format' | 'redeye';
    bg?: string;
    fit?: 'clamp' | 'clip' | 'crop' | 'facearea' | 'fill' | 'fillmax' | 'max' | 'min' | 'scale';
    fm?: 'gif' | 'jpg' | 'png' | 'webp' | 'avif';
    h?: number;
    q?: number;
    w?: number;
}

const mediaCondition = (minWidth: string | number, size: string) => `(min-width: ${minWidth}) ${size}`;

/**
 *
 * @param {string[]} widths - Array of widths specified in unit
 * @param {string[]} [breakpoints] = Array of breakpoints. Defaults to theme breakpoints.
 * @returns {string} - String with media queries based on widths and breakpoints.
 *
 * @example
 *      generateSizes(['50vw', null, '25vw'], ['375px', '768px', '1024px']);
 *      '(min-width: 1024px) 25vw, 50vw'
 *
 */
const generateSizes = (widths: string[], breakpoints: string[] = breakpointValues) => {
    const result = [];

    if (!widths || !widths.length) {
        return null;
    }

    if (!Array.isArray(widths)) {
        return widths;
    }

    for (let i = 0; i < widths.length; i++) {
        const width = widths[i];

        if (width === null || width === undefined) {
            continue;
        }

        result.push(!result.length ? width : mediaCondition(breakpoints[i], width));
    }

    return result.reverse().join(', ');
};

const canUseWebp = (isSupportingWebp?: boolean) => {
    if (typeof isSupportingWebp === 'undefined') {
        isSupportingWebp = getState('application').site.isSupportingWebp;
    }
    if (inBrowser && !isSupportingWebp) {
        const elem = document.createElement('canvas');
        if (elem.getContext && elem.getContext('2d')) {
            // was able or not to get WebP representation
            isSupportingWebp = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        } else {
            // very old browser like IE 8, canvas not supported
            isSupportingWebp = false;
        }
    }

    return isSupportingWebp;
};

/**
 *
 * @param {string} url - URL to concat with query strings.
 * @param {Object} params - Object with param options.
 * @returns {string} - URL with query strings.
 *
 * @example
 *      generateSrc('img.jpg', { width: 200 });
 *      'img.jpg?width=200'
 *
 */
const generateSrc = (url: string, params: StormParams | ImgixParams, isSupportingWebp?: boolean) => {
    let src = url;
    const clonedParams = JSON.parse(JSON.stringify(params));
    if (clonedParams.f === 'webp') {
        clonedParams.f = canUseWebp(isSupportingWebp) ? 'webp' : 'png';
    }

    if (src && clonedParams) {
        let prefix = src?.indexOf('?') > -1 ? '&' : null;

        for (const param in clonedParams) {
            const key = param;
            const rawValue = (clonedParams[param] || '').toString();
            const value = rawValue.indexOf('#') === 0 ? rawValue.slice(1) : rawValue;

            if (value) {
                if (src.indexOf(`${key}=`) > -1) {
                    src = src.replace(new RegExp(`(?:${key})\\=\\d+`, 'g'), `${key}=${value}`);
                } else {
                    // Since we dont have an index in this loop prefix starts with null and displays an ?. For remaning prefix is set to &
                    // If value starts with # it's a hex color, remove # since it's not valid in queryparams.
                    src = `${src}${(prefix = prefix ? '&' : '?')}${key}=${value}`;
                }
            }
        }
    }

    return src;
};

/**
 *
 * @param {string} url - URL to append width query string and height if supplied.
 * @param {number[]} widths - Range of different image widths
 * @param {number[]} [heights] - Range of different image heights, cannot be greater than widths.
 *
 * @example
 *      generateSrcSet('img.jpg', [100, 200], [100]);
 *      'img.jpg?w=100&h=100 100w, img.jpg?w=200 200w'
 *
 */
const generateSrcSet = (url: string, widths: number[], heights: number[] = []) => {
    const prefix = url.indexOf('?') > -1 ? '&' : '?';

    let src = url.endsWith('?') ? url.slice(0, -1) : url;

    // remove w and h query param from src if exsist
    src = src.replace(new RegExp(`(\\?|\\&){0,1}(?:${[heights].length ? 'w|h' : 'w'})\\=\\d+\\&{0,1}`, 'g'), '$1');
    if (src.endsWith('&')) {
        src = src.slice(0, -1);
    }

    return widths
        .map((width, index) => {
            const height = heights[index];

            return `${src}${prefix}w=${width}${height ? `&h=${height}` : ''} ${width}w`;
        })
        .join(', ');
};

/**
 *
 * @param {string} url - URL to an imgix image
 * @param {Object} params - Format options
 *
 * @example
 *      imgixSrc('img.jpg', { format: 'jpg', width: 200 });
 *      'img.jpg?fm=jpgw=200'
 *
 */
const imgixSrc = (url: string, params: ImgixParams) => generateSrc(url, params);

/**
 *
 * @param {string} url - URL to an storm image
 * @param {Object} params - Format options
 *
 * @example
 *      stormSrc('img.jpg', { mode: 'pad', width: 200 });
 *      'img.jpg?mode=padw=200'
 *
 */
const stormSrc = (url: string, params: StormParams, isSupportingWebp?: boolean) =>
    generateSrc(getMediaUrl(url), params, isSupportingWebp);

export { generateSizes, generateSrc, generateSrcSet, imgixSrc, stormSrc };
