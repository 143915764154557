import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@grebban/style-system-react';
import { useHeaderNavigationContext } from '@sportson/core-web/contexts/HeaderNavigationContext';
import Text from 'components/Text';
import { above } from '@sportson/core-web/utils/mediaqueries';
import Link from '@grebban/react-core-components/Link';

const Wrapper = styled('div')`
    display: none;

    &.hide {
        display: none;
    }

    ${above['desktop.sm']} {
        display: flex;
        padding: 8px 40px;
        gap: 30px;
    }
`;

const MenuItem = styled(Link)`
    color: var(--color-base-white);
    border-radius: 4px;

    &:hover {
        color: var(--color-neutrals-300);
        cursor: pointer;
    }

    &.active {
        background-color: var(--color-base-black);
        color: var(--color-base-white);
    }
`;

const RightSideLinks = () => {
    const { headerNavigationState } = useHeaderNavigationContext();
    const { links } = useSelector(({ application }) => application.header.data.navigation?.rightNavigation) || {};
    const menuIsOpen = !!headerNavigationState.activeSubMenu;

    if (!links) {
        return null;
    }
    return (
        <Wrapper className={menuIsOpen ? 'hide' : ''}>
            {links.map(({ link }) => (
                <MenuItem key={link.linkId} to={link.url}>
                    <Text typography="UI/16_100_0_450" color="var(--color-base-white)">
                        {link.text}
                    </Text>
                </MenuItem>
            ))}
        </Wrapper>
    );
};

export default RightSideLinks;
