import getProductByPartNo from '@sportson/core-web/state/page/dispatches/getProductByPartNo';
import handleProductData from '@sportson/core-web/state/page/dispatches/handleProductData';
import getQueryParams from '@sportson/core-web/utils/getQueryParams';

export const formatLocaleAlternates = (alternates, cmsLocalizations) => {
    if (cmsLocalizations && (!alternates?.length || alternates?.length <= 1)) {
        Object.keys(cmsLocalizations).forEach((locale) => {
            const { permalink } = cmsLocalizations[locale];
            const postId = cmsLocalizations[locale].id;
            const localeAlternate = {
                external_id: postId,
                locale,
                slug: permalink,
            };

            alternates.push(localeAlternate);
        });
    }

    return alternates;
};

export const fetchProductData = (data) => {
    let partNo = data.part_no || null;
    if (data.data.variant_part_nos.length > 0) {
        const { p: selectedPartNoParameter } = getQueryParams(location) || {};
        const selectedPartNoState = location.state?.presetPartNo;

        if (selectedPartNoState && data.data.variant_part_nos.includes(selectedPartNoParameter)) {
            partNo = selectedPartNoState;
        } else if (selectedPartNoParameter && data.data.variant_part_nos.includes(selectedPartNoParameter)) {
            partNo = selectedPartNoParameter;
        } else {
            const { variant_part_nos: variantPartNos } = data.data;
            const [firstPartNoIndex] = variantPartNos;
            partNo = firstPartNoIndex;
        }
    }
    const productData = data.data.product_data;
    if (partNo && productData) {
        partNo = productData.part_no;
        handleProductData(partNo, productData);
    } else if (partNo) {
        getProductByPartNo(partNo);
    }
};
