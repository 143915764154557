import PropTypes from 'prop-types';
import React from 'react';

const Span = ({ attrs, children }) => <span {...attrs}>{children}</span>;

Span.propTypes = {
    attrs: PropTypes.object,
    children: PropTypes.node.isRequired,
};

Span.defaultProps = {
    attrs: undefined,
};

export default Span;
