export const hashString = async (string, type = 'SHA-256') => {
    if (!string || typeof string !== 'string') {
        return string;
    }

    const msgUint8 = new TextEncoder().encode(string); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest(type, msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    // convert bytes to hex string

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};
