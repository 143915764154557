import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

const OrganizationStructuredData = () => {
    const organisationSchema =
        useSelector(({ application }) => application.config?.options?.organisationSchema?.organisationSchema) || {};
    const site = useSelector(({ application }) => application.site);

    const protocol = site.protocol ? site.protocol : '';
    const origin = site.origin ? site.origin : '';
    const baseUrl = protocol + origin;

    const organization = organisationSchema?.organization || {};
    const name = organization.companyName;
    const legalName = organization.companyLegalName;
    const { logo } = organization;
    const address = organization.address || {};
    const contactPoint = organisationSchema?.contactOptions || [];

    const schemas = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
    };

    if (name) {
        schemas.name = name;
    }

    if (legalName) {
        schemas.legalName = legalName;
    }

    if (baseUrl) {
        schemas.url = baseUrl;
    }

    if (logo?.url) {
        schemas.logo = logo.url;
    }

    if (Object.values(address).filter((a) => a).length) {
        schemas.address = {};
        if (address.street) {
            schemas.address.streetAddress = address.street;
        }
        if (address.city) {
            schemas.address.addressLocality = address.city;
        }
        if (address.city) {
            schemas.address.postalCode = address.postal_code;
        }
        if (address.city) {
            schemas.address.addressCountry = address.country_code;
        }
    }

    if (contactPoint.length) {
        schemas.contactPoint = [];
        contactPoint.forEach((contact) => {
            const obj = {};
            obj['@type'] = 'ContactPoint';
            obj.contactType = contact.type;
            if (contact.email) {
                obj.email = contact.email;
            }
            if (contact.phone) {
                obj.telephone = contact.phone;
            }
            if (contact.fax) {
                obj.faxNumber = contact.fax;
            }
            if (Object.keys(address).length) {
                schemas.contactPoint.push(obj);
            }
        });
    }

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schemas, null, 2)}</script>
        </Helmet>
    );
};

export default OrganizationStructuredData;
