import PropTypes from 'prop-types';
import React from 'react';

const Italic = ({ children }) => <em>{children}</em>;

Italic.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Italic;
