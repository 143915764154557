import { get } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';

export default async (id, parameters = {}) => {
    const response = await get(`/frontend/product/${id}/relations`, {
        application_id: getModel('application').site.applicationId,
        ...parameters,
    });

    return await response.body();
};
