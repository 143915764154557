import { breakpoints } from 'config/breakpoints';
import { colorsCssVariables } from '../colors';
import { gridCssResponsiveVariables } from '../grid';
import { opacityCssVariables } from '../opacity';
import { ratiosCssResponsiveVariables } from '../ratios';
import { spacingCssResponsiveVariables } from '../spacing';
import { transitionsCssResponsiveVariables } from '../transitions';
import { zIndexCssResponsiveVariables } from '../z-index';
import { type Theme } from './index';

const theme: Theme = {
    statics: {
        // Default Configs
        ...colorsCssVariables,
        ...opacityCssVariables,

        // Site
        '--site-body-background-color': 'var(--color-base-white)',
        '--site-corridor-max-width': '100vw',

        // Brand
        '--brand-color-primary': '',
        '--brand-color-accent': '',

        // Background
        '--background-color-default': 'var(--color-base-white)',
        '--background-color-light': 'var(--color-neutrals-400)',
        '--background-color-dark': 'var(--color-neutrals-400)',
        '--background-color-transparent': 'var(--color-transparent)',

        // Text
        '--text-color-default': 'var(--color-base-black)',
        '--text-color-black': 'var(--color-base-black)',
        '--text-color-white': 'var(--color-base-white)',
        '--text-color-light': 'var(--color-base-black)',
        '--text-color-dark': 'var(--color-base-black)',
        '--text-color-error': 'var(--color-semantic-red)',
        '--text-color-sale': 'var(--color-semantic-red)',
        '--text-color-warning': 'var(--color-semantic-yellow)',
        '--text-color-success': 'var(--color-green)',
        '--cta-module-text-color-default': 'var(--color-base-white)',
        '--discover-module-text-color-default': 'var(--color-base-black)',

        // Links
        '--default-link-color': 'var(--color-base-black)',

        // Shadows
        '--shadow-color': 'var(--color-base-black)',
        '--shadow-drop': '',

        // Notifications
        '--notification-background-color': 'var(--background-color-default)',

        // Overlay
        '--overlay-background-color': 'var(--background-color-default)',

        // Popups
        '--popup-background-color': 'var(--background-color-default)',

        // Footer
        '--footer-background-color': 'var(--background-color-default)',
        '--footer-accordions-background-color': 'var(--color-neutrals-400)',
        '--footer-height-desktop': '368px',
        '--footer-height-mobile': '250px',

        // Header - Some are automatically updated via HeaderContext
        '--header-background-color-default': 'var(--color-base-white)',
        '--header-background-color': 'var(--header-background-color-default)',
        '--header-text-color-default': 'var(--color-brand-indigo-blue)',
        '--header-text-color': 'var(--header-text-color-default)',
        '--header-transition': 'var(--transition-primary)',
        '--header-top-position': '0',
        '--header-height': '64px',
        '--header-height-total': '64px',
        '--header-search-height': '80px',
        '--bike-selector-header-height': '56px',

        // Banner
        '--banner-background-color': 'var(--color-neutrals-400)',
        '--banner-text-color': 'var(--color-base-black)',
        '--banner-height': '0px',

        // Products

        // Loading Spinner
        '--loading-animation-color-primary': 'var(--color-base-black)',

        // Button - Move to button for different themes.
        '--button-background-color': '',
        '--button-text-color': '',
        '--button-border-color': '',
        '--button-animation': '',
        '--button-padding': '',

        // Images
        '--image-placeholder': `url("https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-600w-1037719204.jpg")`,

        // Modules
        '--module-margin-top-default': '0px',
        '--module-margin-bottom-default': '0px',
        '--module-margin-bottom-small': '48px',

        // Button themes
        '--button-primary-background': 'var(--color-brand-yellow)',
        '--button-primary-border': '1px solid var(--color-brand-yellow)',
        '--button-primary-color': 'var(--color-base-black)',
        '--button-primary-hover-background': 'var(--color-brand-yellow)',
        '--button-primary-hover-border': '1px solid var(--color-brand-yellow)',
        '--button-primary-hover-color': 'var(--color-base-black)',
        '--button-primary-active-background': 'var(--color-base-black)',
        '--button-primary-active-border': '1px solid var(--color-base-black)',
        '--button-primary-active-color': 'var(--color-base-white)',
        '--button-primary-disabled-background': 'var(--color-neutrals-100)',
        '--button-primary-disabled-border': '1px solid var(--color-neutrals-100)',
        '--button-primary-disabled-color': 'var(--color-neutrals-500)',

        '--button-primary-outlined-background': 'var(--color-brand-yellow)',
        '--button-primary-outlined-border': '1px solid var(--color-base-black)',
        '--button-primary-outlined-color': 'var(--color-base-black)',
        '--button-primary-outlined-hover-background': 'var(--color-brand-yellow)',
        '--button-primary-outlined-hover-border': '1px solid var(--color-brand-yellow)',
        '--button-primary-outlined-hover-color': 'var(--color-base-black)',
        '--button-primary-outlined-active-background': 'var(--color-base-black)',
        '--button-primary-outlined-active-border': '1px solid var(--color-base-black)',
        '--button-primary-outlined-active-color': 'var(--color-base-white)',
        '--button-primary-outlined-disabled-background': 'var(--color-brand-yellow)',
        '--button-primary-outlined-disabled-border': '1px solid var(--color-brand-yellow)',
        '--button-primary-outlined-disabled-color': 'var(--color-neutrals-500)',

        '--button-secondary-background': 'transparent',
        '--button-secondary-border': '1px solid var(--color-neutrals-200);',
        '--button-secondary-color': 'var(--color-base-black)',
        '--button-secondary-hover-background': 'transparent',
        '--button-secondary-hover-border': '1px solid var(--color-base-black)',
        '--button-secondary-hover-color': 'var(--color-base-black)',
        '--button-secondary-active-background': 'var(--color-base-black)',
        '--button-secondary-active-border': '1px solid var(--color-base-black)',
        '--button-secondary-active-color': 'var(--color-base-white)',
        '--button-secondary-disabled-background': 'var(--color-base-white)',
        '--button-secondary-disabled-border': '1px solid var(--color-neutrals-300)',
        '--button-secondary-disabled-color': 'var(--color-neutrals-300)',

        '--button-primary-dark-background': 'var(--color-brand-yellow)',
        '--button-primary-dark-border': '1px solid var(--color-brand-yellow)',
        '--button-primary-dark-color': 'var(--color-base-black)',
        '--button-primary-dark-hover-background': 'var(--color-brand-yellow)',
        '--button-primary-dark-hover-border': '1px solid var(--color-brand-yellow)',
        '--button-primary-dark-hover-color': 'var(--color-base-black)',
        '--button-primary-dark-active-background': 'var(--color-base-white)',
        '--button-primary-dark-active-border': '1px solid var(--color-base-white)',
        '--button-primary-dark-active-color': 'var(--color-base-black)',
        '--button-primary-dark-disabled-background': 'var(--color-brand-yellow)',
        '--button-primary-dark-disabled-border': '1px solid var(--color-brand-yellow)',
        '--button-primary-dark-disabled-color': 'var(--color-neutrals-500)',

        '--button-secondary-dark-background': 'transparent',
        '--button-secondary-dark-border': '1px solid var(--color-neutrals-600);',
        '--button-secondary-dark-color': 'var(--color-base-white)',
        '--button-secondary-dark-hover-background': 'transparent',
        '--button-secondary-dark-hover-border': '1px solid var(--color-base-white)',
        '--button-secondary-dark-hover-color': 'var(--color-base-white)',
        '--button-secondary-dark-active-background': 'var(--color-base-white)',
        '--button-secondary-dark-active-border': '1px solid var(--color-base-white)',
        '--button-secondary-dark-active-color': 'var(--color-base-black)',
        '--button-secondary-dark-disabled-background': 'var(--color-base-black)',
        '--button-secondary-dark-disabled-border': '1px solid var(--color-neutrals-400)',
        '--button-secondary-dark-disabled-color': 'var(--color-neutrals-400)',

        '--button-secondary-blurred-background': 'var(--color-transparency-black-30)',
        '--button-secondary-blurred-border': '1px solid transparent',
        '--button-secondary-blurred-color': 'var(--color-base-white)',
        '--button-secondary-blurred-hover-background': 'var(--color-transparency-black-50)',
        '--button-secondary-blurred-hover-border': '1px solid transparent',
        '--button-secondary-blurred-hover-color': 'var(--color-base-white)',
        '--button-secondary-blurred-active-background': 'var(--color-base-black)',
        '--button-secondary-blurred-active-border': '1px solid var(--color-base-black)',
        '--button-secondary-blurred-active-color': 'var(--color-base-white)',
        '--button-secondary-blurred-disabled-background': 'transparent',
        '--button-secondary-blurred-disabled-border': '1px solid var(--color-neutrals-400)',
        '--button-secondary-blurred-disabled-color': 'var(--color-neutrals-400)',

        '--button-focus-outline-color-default': 'dotted 2px var(--color-base-black)',
        '--button-focus-outline-color-default-dark': 'dotted 2px var(--color-base-white)',

        '--boxbutton-filled-background': 'transparent',
        '--boxbutton-filled-border': '1px solid transparent',
        '--boxbutton-filled-color': 'var(--color-base-black)',
        '--boxbutton-filled-hover-background': 'var(--color-neutrals-100)',
        '--boxbutton-filled-hover-border': '1px solid var(--color-neutrals-100)',
        '--boxbutton-filled-hover-color': 'var(--color-base-black)',
        '--boxbutton-filled-active-background': 'var(--color-base-black)',
        '--boxbutton-filled-active-border': '1px solid var(--color-base-black)',
        '--boxbutton-filled-active-color': 'var(--color-base-white)',
        '--boxbutton-filled-selected-background': 'var(--color-neutrals-100)',
        '--boxbutton-filled-selected-border': '1px solid var(--color-neutrals-100)',
        '--boxbutton-filled-selected-color': 'var(--color-base-black)',
        '--boxbutton-filled-disabled-background': 'var(--color-neutrals-100)',
        '--boxbutton-filled-disabled-border': '1px solid var(--color-neutrals-100)',
        '--boxbutton-filled-disabled-color': 'var(--color-neutrals-400)',

        '--boxbutton-outlined-background': 'transparent',
        '--boxbutton-outlined-border': '1px solid var(--color-base-black)',
        '--boxbutton-outlined-color': 'var(--color-base-black)',
        '--boxbutton-outlined-hover-background': 'transparent',
        '--boxbutton-outlined-hover-border': '1px solid var(--color-base-black)',
        '--boxbutton-outlined-hover-color': 'var(--color-base-black)',
        '--boxbutton-outlined-active-background': 'var(--color-base-black)',
        '--boxbutton-outlined-active-border': '1px solid var(--color-base-black)',
        '--boxbutton-outlined-active-color': 'var(--color-base-white)',
        '--boxbutton-outlined-selected-background': 'transparent',
        '--boxbutton-outlined-selected-border': '1px solid var(--color-base-black)',
        '--boxbutton-outlined-selected-color': 'var(--color-base-black)',
        '--boxbutton-outlined-disabled-background': 'transparent)',
        '--boxbutton-outlined-disabled-border': '1px solid var(--color-neutrals-400)',
        '--boxbutton-outlined-disabled-color': 'var(--color-neutrals-400)',

        '--productcard-image-background-color': 'var(--color-neutrals-100)',

        '--toggleswitch-color': 'var(--color-neutrals-500)',
        '--toggleswitch-hover-color': 'var(--color-neutrals-400)',
        '--toggleswitch-background-color': 'var(--color-neutrals-200)',
        '--toggleswitch-handle-background-color': 'var(--color-neutrals-100)',
        '--toggleswitch-handle-border-color': 'var(--color-neutrals-500)',
        '--toggleswitch-handle-color': 'var(--color-neutrals-500)',
        '--toggleswitch-checked-handle-background-color': 'var(--color-base-black)',
        '--toggleswitch-checked-handle-border-color': 'var(--color-base-black)',
        '--toggleswitch-checked-handle-color': 'var(--color-base-black)',
    },
    responsives: {
        'mobile.sm': {},
        'mobile.md': {},
        'mobile.lg': {},
        'tablet.sm': {},
        'tablet.lg': {
            '--module-margin-bottom-default': '0',
            '--module-margin-bottom-small': '0',
        },
        'desktop.sm': {
            '--bike-selector-header-height': '88px',
        },
        'desktop.md': {},
        'desktop.lg': {
            '--site-corridor-max-width': '2000px',
        },
    },
};

breakpoints.forEach((breakpoint) => {
    theme.responsives[breakpoint.label] = {
        ...ratiosCssResponsiveVariables[breakpoint.label],
        ...gridCssResponsiveVariables[breakpoint.label],
        ...transitionsCssResponsiveVariables[breakpoint.label],
        ...zIndexCssResponsiveVariables[breakpoint.label],
        ...spacingCssResponsiveVariables[breakpoint.label],

        // Always write the theme variables last
        ...theme.responsives[breakpoint.label],
    };
});

export default theme;
