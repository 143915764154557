import validateEmail from '@grebban/utils/regex/expressions/validateEmail';
import React, { useState } from 'react';

import Input, { type InputProps } from './Input';

export type EmailInputProps = Omit<InputProps, 'type'> & {
    onErrorChange?: (hasError: boolean) => void;
};

const EmailInput = ({ onErrorChange, ...props }: EmailInputProps) => {
    /* Callback to pass error to parent in order to style submit button when error occurs */
    const handleErrorChange = (errorState: boolean) => {
        if (onErrorChange) {
            onErrorChange(errorState);
        }
    };

    return (
        <Input
            {...props}
            type="email"
            inverted
            pattern={validateEmail.toString() /* @TODO Might need to remove slashes from regex */}
            onErrorChange={handleErrorChange}
        />
    );
};

export default EmailInput;
