import Cookies from 'js-cookie';

export default (basketItems) => {
    const totalBasketItems = basketItems?.reduce((total, item) => total + (item.quantity || 0), 0);
    // Set total basket items amount for future usage.
    if (totalBasketItems > 0) {
        Cookies.set('basket_items_amount', totalBasketItems, {
            expires: 30,
        });
    } else {
        Cookies.remove('basket_items_amount');
    }
};
