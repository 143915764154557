import styled from '@grebban/style-system-react';
import Image from '@sportson/core-web/components/Image';
import Link from 'components/Link';

const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface Partners {
    partners: any; // TODO: FIX
}

export const Partners = ({ partners }: Partners) => {
    if (!partners || partners.length === 0) {
        return null;
    }

    return (
        partners && (
            <Wrapper gap="24px" p="16px 0" width={['100%', null, null, null, 'auto']}>
                {partners.map(({ icon, name, url }) => (
                    <Link noUnderline isVolatile key={name} to={url}>
                        <Image src={icon.url} alt={icon.alt ?? name} />
                    </Link>
                ))}
            </Wrapper>
        )
    );
};
