import Gtm from '@grebban/gtm';
import DataLayer from './DataLayer';

export default ({ basketId, items }) => {
    try {
        if (!basketId || !items) {
            return;
        }

        const products = items.map((item) => {
            const product = DataLayer.getProduct(item);

            return {
                ...product,
                price: product.price || 0,
                priceExVat: product.priceExVat || 0,
                brand: product.brand || 'N/A',
                variant_url: product.variant_url || '',
                item_variant: product.item_variant || '',
            };
        });

        Gtm.addDataLayerEvent('checkout', {
            ecommerce: {
                checkout: {
                    products,
                },
            },
        });
    } catch (e) {
        //
    }
};
