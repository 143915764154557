import styled from '@grebban/style-system-react';
import LoadingIcon from 'assets/media/icons/Loading';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import React from 'react';

const LoadingWrapper = styled('div')`
    animation: var(--animation-infinite-spin);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export interface LoadingProps {
    color?: CssVariable<CssColorKey> | CssVariable<CssColorKey>[];
    height?: string | string[];
    margin?: string | string[];
    strokeWidth?: string | string[];
    width?: string | string[];
    className?: string;
}
const Loading: React.FunctionComponent<LoadingProps> = ({
    color = 'var(--color-base-black)',
    height = '24px',
    width = '24px',
    strokeWidth = '1px',
    margin = '0',
    className,
}) => (
    <LoadingWrapper margin={margin}>
        <LoadingIcon className={className} width={width} height={height} color={color} strokeWidth={strokeWidth} />
    </LoadingWrapper>
);

export default Loading;
