import { createSlice } from '@reduxjs/toolkit';

export interface IServerState extends Record<string, any> {
    requestUrl: string;
    requestBody?: object;
}

const initialState = {
    requestUrl: '',
    requestBody: {},
};

const slice = createSlice({
    name: 'server',
    initialState,
    reducers: {
        setServerState: (state, action) => ({ ...state, ...(action?.payload || {}) }),
    },
});

export const { setServerState } = slice.actions;

export const { reducer } = slice;
