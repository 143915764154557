import styled from '@grebban/style-system-react';
import React from 'react';

const StyledContentWrapper = styled('div')`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

// @TODO Remove extend when styled package is correct.
export type ContentWrapperProps = Record<string, unknown>;

export const contentPadding = ['12px', /* sm */ null, /* md */ null, /* lg */ '48px'];

const ContentWrapper: React.FunctionComponent<ContentWrapperProps> = ({ ...props }) => (
    // @TODO Add paddings here by replacing the nulls.
    <StyledContentWrapper px={[null, null, null, null]} {...props} />
);

export default ContentWrapper;
