import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import AddVoucher from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/AddVoucher';
import getBasketId from '@sportson/core-web/state/ecommerce/norce/utils/getBasketId';
import buildCartProductTree from '@sportson/core-web/utils/buildCartProductTree';
import getErrorMesage from '@sportson/core-web/utils/getErrorMessage';

export default createAsyncThunk(
    `basket/voucher/add`,
    async (voucherCode: string, { getState, dispatch, rejectWithValue, fulfillWithValue }) => {
        const basketId = getBasketId();

        if (!basketId) {
            return rejectWithValue({
                error: 'BasketId is not set.',
            });
        }

        const mutexLock = await mutex('basket');

        try {
            const response = await AddVoucher(basketId, voucherCode);
            mutexLock();

            const responseStatus = await response.status();
            const responseIsOk = await response.ok();

            if (responseStatus === 404) {
                throw new Error('not_found');
            }

            if (!responseIsOk) {
                throw new Error('unidentified_error');
            }

            const responseBody = await response.body();

            const basketItems = buildCartProductTree(responseBody.data.items);
            const voucherData = objectKeysToCamelCase(
                { ...responseBody.data, items: basketItems },
                {
                    recursive: true,
                    modifyInputObject: false,
                },
            );

            const { appliedPromotions = [] } = voucherData;

            const isValid = appliedPromotions.some((promotion) => promotion.discountCode === voucherCode);

            return fulfillWithValue({ ...voucherData, isValid });
        } catch (error) {
            mutexLock();
            return rejectWithValue({ error: getErrorMesage(error) });
        }
    },
);

const pending = (state, action) => {
    state.status = 'pending';
};

const fulfilled = (state, action) => ({
    ...state,
    ...action.payload,
    status: 'idle',
});

const rejected = (state, action) => {
    console.error(action.payload);
    return {
        ...state,
        status: 'idle',
    };
};

export const addVoucherStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
