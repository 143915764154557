import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import { debugHelper } from '@sportson/core-web/utils/debugHelper';

const initialHeaderNavigationContextState: {
    activeSubMenu: string;
    mobileMenuActive: boolean; // Used in mobile...
    desktopMenuActive: boolean; // Used in desktop...
    parentMenuName: string | null;
} = {
    activeSubMenu: '', // Used for mobile and accessibility in desktop
    mobileMenuActive: false, // Used in mobile...
    desktopMenuActive: false, // Used in mobile...
    parentMenuName: null,
};

const initialHeaderNavigationContext = {
    headerNavigationState: initialHeaderNavigationContextState,
    setHeaderNavigationState: (state: HeaderNavigationContextState) => {}, // noop default callback
};

const HeaderNavigationContext = createContext(initialHeaderNavigationContext);
const useHeaderNavigationContext = () => useContext(HeaderNavigationContext);

interface HeaderNavigationContextProviderProps {
    children: React.ReactNode;
}

const HeaderNavigationContextProvider = ({ children }: HeaderNavigationContextProviderProps) => {
    const currentPageSlug = useAppSelector(({ page }) => page?.slug);
    const overlayActive = !!useAppSelector(({ application }) => application.overlay.view || ''); // @TODO Move to header overlay?
    const [headerState, setHeaderState] = useState(initialHeaderNavigationContextState);
    const isMobile = useBreakpoint({ to: 'tablet.sm' });

    // If a view is updated, we need to re-evaluate the header
    useEffect(() => {
        debugHelper({ context: 'HeaderNavigationContext', message: 'view changes' });
        setHeaderState({
            mobileMenuActive: false,
            desktopMenuActive: false,
            activeSubMenu: '',
            parentMenuName: null,
        });
    }, [currentPageSlug]);

    // If an overlay gets active, we close the header navigation
    useEffect(() => {
        if (overlayActive) {
            debugHelper({ context: 'HeaderNavigationContext', message: 'Another overlay activated' });
            setHeaderState({
                ...headerState,
                mobileMenuActive: false,
                activeSubMenu: '',
            });
        }
    }, [overlayActive]);

    // If the mobile-menu is activated, lock the scroll.
    useEffect(() => {
        debugHelper({ context: 'HeaderNavigationContext', message: 'Mobile Menu Toggled, update scroll' });
        // state.mobileMenuActive ? dispatch(disallowScroll("header")) : dispatch(allowScroll("header"));
    }, [headerState.mobileMenuActive]);

    debugHelper({
        context: 'HeaderNavigationContext',
        message: 'Render',
        additionalParameters: {
            headerState,
            currentPageSlug,
            overlayActive,
            isMobile,
        },
    });

    const headerNavigationContextProviderProps = useMemo<HeaderNavigationContext>(
        () => ({
            headerNavigationState: headerState,
            setHeaderNavigationState: setHeaderState,
        }),
        [headerState],
    );

    return (
        <HeaderNavigationContext.Provider value={headerNavigationContextProviderProps}>
            {children}
        </HeaderNavigationContext.Provider>
    );
};

export type HeaderNavigationContextState = typeof initialHeaderNavigationContextState;
export type HeaderNavigationContext = typeof initialHeaderNavigationContext;
export { HeaderNavigationContextProvider, useHeaderNavigationContext };
