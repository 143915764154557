import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import { type WordpressTextColor } from '../types/contentSystem/settings';

type ColorMapper = Record<WordpressTextColor, CssColorKey | undefined>;
const colorMapper: ColorMapper = {
    default: undefined,
    black: '--color-base-black',
    white: '--color-base-white',
};

export default (textColor: WordpressTextColor): CssVariable<CssColorKey> | undefined => {
    const color = colorMapper[textColor];
    if (!color) {
        return undefined;
    }
    return `var(${color})`;
};
