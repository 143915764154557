import { post } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';

export default async (basketId, paymentParameters) => {
    const {
        site: { applicationId, locale },
        config,
    } = getModel('application');
    const countryCode = locale.substr(3);
    const cmsPaths = config?.options?.specifiedPages?.specifiedPages; // Todo: fix naming in CMS

    const checkoutPath = cmsPaths?.checkout || '/';
    const orderConfirmationPath = cmsPaths?.orderConfirmation || '/';

    const data = {
        application_id: applicationId,
        ip_address: '127.0.0.1',
        user_agent: 'grebban',
        payment_parameters: {
            checkouturl: `${window.location.protocol}//${window.location.host}${checkoutPath}`,
            termsurl: `${window.location.protocol}//${window.location.host}/kopvillkor`, // todo: get from cms
            confirmationurl: `${window.location.protocol}//${window.location.host}${orderConfirmationPath}?basketId=${basketId}`,
            country: countryCode,
            // 'b2b': 'yes',
        },
    };

    if (typeof paymentParameters === 'object') {
        Object.assign(data.payment_parameters, paymentParameters);
    }

    const response = await post(`/frontend/payment/${basketId}`, data);

    return await response.body();
};
