import { post } from '@sportson/core-web/libs/GrebbCommerceAPI/util/authClient';
import { getModel } from '@sportson/core-web/state';

export default async (basketId, parameters = {}) => {
    const response = await post(`/frontend/basket/${basketId}/update`, {
        application_id: getModel('application').site.applicationId,
        ...parameters,
    });

    return await response;
};
