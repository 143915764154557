import styled from '@grebban/style-system-react';
import { type CssVariable } from 'config/branding/types';
import React from 'react';

const StyledBackgroundColor = styled('div')`
    width: 100%;
    height: 100%;
`;

export interface BackgroundColorProps {
    backgroundColor?: CssVariable<string> | CssVariable<string>[];
}
const BackgroundColor = ({ backgroundColor = 'var(--color-base-white)', ...rest }: BackgroundColorProps) => (
    <StyledBackgroundColor backgroundColor={backgroundColor} {...rest} />
);

export default BackgroundColor;
