import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createSlice } from '@reduxjs/toolkit';
import resolveApplication from '@sportson/core-web/state/application/resolve';

export type IConfigState = Record<string, any>;

const initialState: IConfigState = {};

const slice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(resolveApplication.fulfilled, (state, action) => ({
            ...state,
            ...objectKeysToCamelCase(action.payload?.data?.config || {}, {
                recursive: true,
                modifyInputObject: false,
            }),
        }));
    },
});

export {};
// export const {} = slice.actions;
export const { reducer } = slice;
