import { put } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';
import { hashString } from '@sportson/core-web/utils/hashString';
import tracking from '@sportson/core-web/components/Tracking';

export default async (basketId, partNo, basketItemId, quantity, pricelistId, eventId, manualPrice) => {
    const clientId = tracking.getClientId();
    const sessionId = tracking.getSessionId();
    const eventTimestamp = tracking.getEventTimestamp();
    const consentMarketing = tracking.getConsentMarketing();
    const consentStatistic = tracking.getConsentStatistic();
    const fbp = tracking.getFacebookFbp();
    const fbc = tracking.getFacebookFbc();
    const clientUserAgent = tracking.getFacebookClientUserAgent() || '';
    const clientIpAddress = getModel('application').ipAddress;
    const userEmail = getModel('user')?.email?.toLowerCase() || '';
    const hashedEmailSHA256 = await hashString(userEmail, 'SHA-256');

    const data = {
        quantity,
        part_no: partNo,
        price_list_id: pricelistId,
        application_id: getModel('application').site.applicationId,
        consent_marketing: consentMarketing,
        consent_statistic: consentStatistic,
    };

    if (manualPrice) {
        data.is_price_manual = true;
        data.price = manualPrice;
    }

    if (clientId) {
        data.tracking_client_id = clientId;
    }

    if (sessionId) {
        data.tracking_session_id = sessionId;
    }

    if (eventTimestamp) {
        data.tracking_event_timestamp = eventTimestamp;
    }

    if (fbp) {
        data.tracking_fbp = fbp;
    }

    if (fbc) {
        data.tracking_fbc = fbc;
    }

    if (clientUserAgent) {
        data.tracking_client_user_agent = clientUserAgent;
    }

    if (clientIpAddress) {
        data.tracking_client_ip_address = clientIpAddress;
    }

    if (eventId) {
        data.tracking_event_id = eventId;
    }

    if (userEmail) {
        data.hashedUserEmail = hashedEmailSHA256;
    }

    const response = await put(`/frontend/basket/${basketId}/item/${basketItemId}`, data);

    return await response.body();
};
