import { type CssResponsiveVariables } from './types';

export interface ZIndexCssResponsiveVariables {
    '--zindex-background'?: number;
    '--zindex-middleground'?: number;
    '--zindex-header'?: number;
    '--zindex-forground'?: number;
    '--zindex-popup'?: number;
    '--zindex-overlay'?: number;
    '--zindex-notifications'?: number;
}

export const zIndexCssResponsiveVariables: CssResponsiveVariables<ZIndexCssResponsiveVariables> = {
    'mobile.sm': {
        '--zindex-background': -1,
        '--zindex-middleground': 500,
        '--zindex-header': 550,
        '--zindex-forground': 1000,
        '--zindex-popup': 1050,
        '--zindex-overlay': 1100,
        '--zindex-header-above-overlay': 1150,
        '--zindex-notifications': 1150,
    },
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {},
    'tablet.lg': {},
    'desktop.sm': {},
    'desktop.md': {},
    'desktop.lg': {},
};
