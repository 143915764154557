import { connect } from 'react-redux';
import store from '@sportson/core-web/state/store';

const _inject = (modelName, state) => state[modelName];

export const getModel = (modelName) => _inject(modelName, store.getState());

export const injectModel = (modelName) => (Component) => {
    const mapStateToProps = (state) => {
        const props = {};

        props[modelName] = _inject(modelName, state);

        return props;
    };

    return connect(mapStateToProps)(Component);
};

export const injectModels = (modelNames) => (Component) => {
    const mapStateToProps = (state) => {
        const props = {};
        modelNames.forEach((modelName) => {
            props[modelName] = _inject(modelName, state);
        });

        return props;
    };

    return connect(mapStateToProps)(Component);
};

export const getState = (model = null) => {
    const state = store.getState();

    if (model && state[model]) {
        return state[model];
    }

    return state;
};

const locks = {};

export const acquireMutex = (mutex) => {
    if (typeof mutex === 'string') {
        // It's a single lock.
        let mutexResolver = null;
        const mutexPromise = new Promise((resolve) => {
            mutexResolver = resolve;
        });

        if (!locks[mutex]) {
            locks[mutex] = [];
        }

        locks[mutex].push(mutexPromise);

        if (locks[mutex].length > 1) {
            // Mutex exist.
            return locks[mutex][locks[mutex].length - 2].then(() => mutexResolver);
        }

        return mutexResolver;
    }
    if (typeof mutex === 'object' && locks.length && locks.length > 0) {
        // It's an array.
        // @todo: Implement multiple mutexes.
    }
};
