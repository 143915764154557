// Page
export const PageEvents = {
    VIEW: 'PAGE.VIEW',
    RESET: 'PAGE.RESET',
    RESOLVED: 'PAGE.RESOLVED',
};

// Error
export const ErrorEvents = {
    LOG: 'ERROR.LOG',
};

//
// Ecommerce.
//

// Basket Events.
export const BasketEvents = {
    // Basket.
    CREATED: 'BASKET.CREATED',
    SET: 'BASKET.SET',
    OPENED: 'BASKET.OPENED',
    CLOSED: 'BASKET.CLOSED',
    TOGGLED: 'BASKET.TOGGLED',
    EMPTIED: 'BASKET.EMPTIED',
    UPDATED: 'BASKET.UPDATED',

    // Products.
    PRODUCT_ADDED: 'BASKET.PRODUCT_ADDED',
    PRODUCT_REMOVED: 'BASKET.PRODUCT_REMOVED',
    PRODUCTS_ADDED: 'BASKET.PRODUCTS_ADDED',
};

// Search Events
export const SearchEvents = {
    SUBMITTED: 'SEARCH.SUBMITTED',
    SEARCH_SUGGESTIONS: 'SEARCH.SEARCH_SUGGESTIONS',
    NO_RESULTS: 'SEARCH.NO_RESULTS',
    BRAND_SEARCH: 'SEARCH.BRAND_SEARCH',
    MOBILE_FILTER: 'SEARCH.MOBILE_FILTER',
    TOGGLE_SEARCH: 'SEARCH.TOGGLE_SEARCH',
};

// Product Events
export const ProductEvents = {
    CLICK: 'PRODUCT.CLICK',
    VIEW: 'PRODUCT.VIEW',
    IMPRESSIONS: 'PRODUCT.IMPRESSIONS',
    PRODUCTS_AMOUNT: 'PRODUCTS.AMOUNT',
    TOGGLE_VAT: 'TOGGLE_VAT',
    SCROLL_TO_REVIEWS: 'PRODUCT.SCROLL_TO_REVIEWS',
};

export const CheckoutEvents = {
    // Checkout
    VIEW: 'CHECKOUT.INIT',
    INIT_SHIPPING: 'CHECKOUT.INIT_SHIPPING',
    SET_SHIPPING_METHOD: 'CHECKOUT.SET_SHIPPING_METHOD',
    UPDATE_SHIPPING: 'CHECKOUT.UPDATE_SHIPPING',
    SHIPPING_UPDATED: 'CHECKOUT.SHIPPING_UPDATED',
    SHIPPING: 'CHECKOUT.SHIPPING',
    INIT_PAYMENT: 'CHECKOUT.INIT_PAYMENT',
    PAYMENT: 'CHECKOUT.PAYMENT',
    UPDATE_PAYMENT: 'CHECKOUT.UPDATE_PAYMENT',
    EDIT_BASKET: 'CHECKOUT.EDIT_BASKET',
    TRANSACTION: 'CHECKOUT.TRANSACTION',
    REFUND: 'CHECKOUT.REFUND',
    STEP: 'CHECKOUT.STEP',
    UPDATED: 'CHECKOUT.UPDATED',

    FREIGHT_COST_UPDATED: 'FREIGHT.COST.UPDATED',

    // Gift Cards
    GIFT_CARD_ADDED: 'CHECKOUT.GIFT_CARD_ADDED',
    GIFT_CARD_ROLLBACK: 'CHECKOUT.GIFT_CARD_ROLLBACK',

    // Vouchers
    VOUCHER_ADDED: 'CHECKOUT.VOUCHER_ADDED',
    VOUCHER_REMOVED: 'CHECKOUT.VOUCHER_REMOVED',
};

export const PromotionEvents = {
    // Promotion
    CLICK: 'PROMOTION.CLICK',
    IMPRESSIONS: 'PROMOTION.IMPRESSIONS',
};

export const ScrollEvents = {
    TO_TOP: 'SCROLL.TO_TOP',
};

export const UserEvents = {
    UPDATED: 'USER.SET',
    COMPANY_UPDATED: 'COMPANY.UPDATED',
    LOGGED_OUT: 'USER.LOGGED_OUT',
    LOGGED_IN: 'USER.LOGGED_IN',
};

export const IngridTrackingEvents = {
    ORDERS: 'INGRIDTRACKING.ORDERS',
};
