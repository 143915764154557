import React from 'react';
import { Helmet } from 'react-helmet-async';
import { shallowEqual } from 'react-redux';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import { inServer } from '@sportson/core-web/constants';

const SiteMeta = () => {
    const { favicon, themeColor } = useAppSelector(
        (state) => state.application.config?.globalOptions?.metaData || {},
        shallowEqual,
    );

    // Detect light- or dark-mode browsers
    const darkMode = !inServer ? window.matchMedia('(prefers-color-scheme: dark)').matches : false;

    // Color theme for browser navigationbars etc depending on browser mode
    const { dark: darkColor, light: lightColor } = themeColor || {};

    // Use light or dark favicon depending on browser mode
    const { dark, light } = favicon || {};
    let icon: { url?: string; meta?: { mimeType: string } } = {};
    if (darkMode && dark) {
        icon = dark;
    } else if (light) {
        icon = light;
    }

    const pinterestVerifications = process.env.REACT_APP_PINTEREST_SITE_VERIFICATIONS?.split(',') || [];
    const facebookVerifications = process.env.REACT_APP_FACEBOOK_SITE_VERIFICATIONS?.split(',') || [];
    const googleVerifications = process.env.REACT_APP_GOOGLE_SITE_VERIFICATIONS?.split(',') || [];

    const rels: React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>[] = [];
    if (icon?.url) {
        rels.push(
            <link rel="apple-touch-icon" sizes="57x57" href={`${icon.url}?w=57&h=57`} />,
            <link rel="apple-touch-icon" sizes="60x60" href={`${icon.url}?w=60&h=60`} />,
            <link rel="apple-touch-icon" sizes="72x72" href={`${icon.url}?w=72&h=72`} />,
            <link rel="apple-touch-icon" sizes="76x76" href={`${icon.url}?w=76&h=76`} />,
            <link rel="apple-touch-icon" sizes="114x114" href={`${icon.url}?w=114&h=114`} />,
            <link rel="apple-touch-icon" sizes="120x120" href={`${icon.url}?w=120&h=120`} />,
            <link rel="apple-touch-icon" sizes="144x144" href={`${icon.url}?w=144&h=144`} />,
            <link rel="apple-touch-icon" sizes="152x152" href={`${icon.url}?w=152&h=152`} />,
            <link rel="apple-touch-icon" sizes="180x180" href={`${icon.url}?w=180&h=180`} />,
        );
        if (icon?.meta) {
            rels.push(
                <link rel="icon" type={icon.meta?.mimeType} sizes="16x16" href={`${icon.url}?w=16&h=16`} />,
                <link rel="icon" type={icon.meta?.mimeType} sizes="32x32" href={`${icon.url}?w=32&h=32`} />,
                <link rel="icon" type={icon.meta?.mimeType} sizes="96x96" href={`${icon.url}?w=96&h=96`} />,
                <link rel="icon" type={icon.meta?.mimeType} sizes="192x192" href={`${icon.url}?w=192&h=192`} />,
            );
        }
    }

    return (
        <Helmet>
            <>
                {icon?.url && (
                    <>
                        /!* Favicons *!/
                        {rels}
                        /!* MS Tile *!/
                        <meta name="msapplication-TileImage" content={`${icon.url}?w=150&h=150`} />
                        <meta name="msapplication-TileColor" content={darkMode ? darkColor : lightColor} />
                    </>
                )}
                /!* The theme-color sets the color of the tool bar *!/
                {lightColor && <meta name="theme-color" content={lightColor} media="(prefers-color-scheme: light)" />}
                {darkColor && <meta name="theme-color" content={darkColor} media="(prefers-color-scheme: dark)" />}
                {/* todo: getCountrySpecificVariable ? */}
                {pinterestVerifications.map((key) => {
                    const metaContent = key.split('|')[1];
                    return metaContent ? <meta key={metaContent} name="p:domain_verify" content={metaContent} /> : null;
                })}
                {facebookVerifications.map((key) => {
                    const metaContent = key.split('|')[1];
                    return metaContent ? (
                        <meta key={metaContent} name="facebook-domain-verification" content={metaContent} />
                    ) : null;
                })}
                {googleVerifications.map((key) => {
                    const metaContent = key.split('|')[1];
                    return metaContent ? (
                        <meta key={metaContent} name="google-site-verification" content={metaContent} />
                    ) : null;
                })}
            </>
        </Helmet>
    );
};
export default SiteMeta;
