import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import React, { useRef, useEffect, useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { getCompareProduct } from '@sportson/core-web/state/ecommerce/compare';
import { useDispatch } from 'react-redux';
import { CompareProducts } from './CompareProducts';

interface CompareProductsPopupProps {}

export const CompareProductsPopup: React.FC<CompareProductsPopupProps> = () => {
    const dispatch = useDispatch();
    const products = useAppSelector(({ ecommerce }) => ecommerce.compare.products);
    const nodeRef = useRef(null);

    const monitorCompareStatus = () => {
        dispatch(getCompareProduct());
    };

    useEffect(() => {
        window.addEventListener('storage', monitorCompareStatus);
        return () => {
            window.removeEventListener('storage', monitorCompareStatus);
        };
    }, []);

    return (
        <TransitionGroup>
            {products.length && (
                <Transition nodeRef={nodeRef.current} in={!!products.length} timeout={{ enter: 0, exit: 300 }}>
                    {(state) => <CompareProducts products={products} showing={state === 'entered'} />}
                </Transition>
            )}
        </TransitionGroup>
    );
};
