import Gtm from '@grebban/gtm';

export default (data) => {
    try {
        Gtm.addDataLayerEvent('checkout_option_shipping', {
            ecommerce: {
                checkout: {
                    actionField: { step: 1, option: data.option },
                },
            },
        });
    } catch (e) {
        //
    }
};
