import React from 'react';
import styled from '@grebban/style-system-react';
import { above } from '@sportson/core-web/utils/mediaqueries';
import Link from '@grebban/react-core-components/Link';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { useSelector } from 'react-redux';
import { useHeaderNavigationContext } from '@sportson/core-web/contexts/HeaderNavigationContext';
import Text from 'components/Text';

type Props = {};

const Wrapper = styled('div')`
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 28px;

    background-color: var(--color-base-black);
    border-top: 1px solid var(--color-neutrals-600);

    ${above['mobile.sm']} {
        height: 32px;
    }
`;

const BannerText = styled(Text)``;

export const Banner = () => {
    const bannerText: string = useSelector(({ application }) => application.header.data.banner?.message?.text);
    const bannerLink: string | null = useSelector(({ application }) => application.header.data.banner?.message?.url);
    const { headerNavigationState } = useHeaderNavigationContext();

    const isMobile = useBreakpoint({ to: 'tablet.sm' });

    if (!bannerText || !!headerNavigationState.activeSubMenu.length || headerNavigationState.mobileMenuActive) {
        return null;
    }

    return (
        <Wrapper>
            <BannerText
                $as={bannerLink ? 'a' : 'p'}
                href={bannerLink || ''}
                padding="8px 24px"
                color="var(--color-brand-yellow)"
                typography={isMobile ? 'UI/12_100_0_450' : 'UI/16_100_0_450'}
            >
                {bannerText}
            </BannerText>
        </Wrapper>
    );
};
