export const SIZES = {
    xsmall: {
        padding: '8px 20px',
    },
    small: {
        padding: '24px',
    },
    large: {
        padding: '32px',
    },
};

export const STYLES = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    lineHeight: '1em',
    textDecoration: 'none',
    userSelect: 'none',
    outline: 'none',
    transition: 'var(--transition-primary)',
};

// Disabled theme set directly in Button since it's the same for all themes.
export const THEMES = {
    primary: {
        color: 'var(--button-primary-color)',
        backgroundColor: 'var(--button-primary-background)',
        border: 'var(--button-primary-border)',
        hover: {
            color: 'var(--button-primary-hover-color)',
            backgroundColor: 'var(--button-primary-hover-background)',
            border: 'var(--button-primary-hover-border)',
        },
        disabled: {
            color: 'var(--button-primary-disabled-color)',
            backgroundColor: 'var(--button-primary-disabled-background)',
            border: 'var(--button-primary-disabled-border)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--button-primary-active-color)',
            backgroundColor: 'var(--button-primary-active-background)',
            border: 'var(--button-primary-active-border)',
        },
        focusOutlineColor: 'var(--button-focus-outline-color-default)',
    },
    primaryOutlined: {
        color: 'var(--button-primary-outlined-color)',
        backgroundColor: 'var(--button-primary-outlined-background)',
        border: 'var(--button-primary-outlined-border)',
        hover: {
            color: 'var(--button-primary-outlined-hover-color)',
            backgroundColor: 'var(--button-primary-outlined-hover-background)',
            border: 'var(--button-primary-outlined-hover-border)',
        },
        disabled: {
            color: 'var(--button-primary-outlined-disabled-color)',
            backgroundColor: 'var(--button-primary-outlined-disabled-background)',
            border: 'var(--button-primary-outlined-disabled-border)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--button-primary-outlined-active-color)',
            backgroundColor: 'var(--button-primary-outlined-active-background)',
            border: 'var(--button-primary-outlined-active-border)',
        },
        focusOutlineColor: 'var(--button-focus-outline-color-default)',
    },
    primaryDark: {
        color: 'var(--button-primary-dark-color)',
        backgroundColor: 'var(--button-primary-dark-background)',
        border: 'var(--button-primary-dark-border)',
        hover: {
            color: 'var(--button-primary-dark-hover-color)',
            backgroundColor: 'var(--button-primary-dark-hover-background)',
            border: 'var(--button-primary-dark-hover-border)',
        },
        disabled: {
            color: 'var(--button-primary-dark-disabled-color)',
            backgroundColor: 'var(--button-primary-dark-disabled-background)',
            border: 'var(--button-primary-dark-disabled-border)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--button-primary-dark-active-color)',
            backgroundColor: 'var(--button-primary-dark-active-background)',
            border: 'var(--button-primary-dark-active-border)',
        },
        focusOutlineColor: 'var(--button-focus-outline-color-default-dark)',
    },
    secondary: {
        color: 'var(--button-secondary-color)',
        backgroundColor: 'var(--button-secondary-background)',
        border: 'var(--button-secondary-border)',
        hover: {
            color: 'var(--button-secondary-hover-color)',
            backgroundColor: 'var(--button-secondary-hover-background)',
            border: 'var(--button-secondary-hover-border)',
        },
        disabled: {
            color: 'var(--button-secondary-disabled-color)',
            backgroundColor: 'var(--button-secondary-disabled-background)',
            border: 'var(--button-secondary-disabled-border)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--button-secondary-active-color)',
            backgroundColor: 'var(--button-secondary-active-background)',
            border: 'var(--button-secondary-active-border)',
        },
        focusOutlineColor: 'var(--button-focus-outline-color-default)',
    },
    secondaryDark: {
        color: 'var(--button-secondary-dark-color)',
        backgroundColor: 'var(--button-secondary-dark-background)',
        border: 'var(--button-secondary-dark-border)',
        hover: {
            color: 'var(--button-secondary-dark-hover-color)',
            backgroundColor: 'var(--button-secondary-dark-hover-background)',
            border: 'var(--button-secondary-dark-hover-border)',
        },
        disabled: {
            color: 'var(--button-secondary-dark-disabled-color)',
            backgroundColor: 'var(--button-secondary-dark-disabled-background)',
            border: 'var(--button-secondary-dark-disabled-border)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--button-secondary-dark-active-color)',
            backgroundColor: 'var(--button-secondary-dark-active-background)',
            border: 'var(--button-secondary-dark-active-border)',
        },
        focusOutlineColor: 'var(--button-focus-outline-color-default-dark)',
    },
    secondaryBlurred: {
        color: 'var(--button-secondary-blurred-color)',
        backgroundColor: 'var(--button-secondary-blurred-background)',
        border: 'var(--button-secondary-blurred-border)',
        hover: {
            color: 'var(--button-secondary-blurred-hover-color)',
            backgroundColor: 'var(--button-secondary-blurred-hover-background)',
            border: 'var(--button-secondary-blurred-hover-border)',
        },
        disabled: {
            color: 'var(--button-secondary-blurred-disabled-color)',
            backgroundColor: 'var(--button-secondary-blurred-disabled-background)',
            border: 'var(--button-secondary-blurred-disabled-border)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--button-secondary-blurred-active-color)',
            backgroundColor: 'var(--button-secondary-blurred-active-background)',
            border: 'var(--button-secondary-blurred-active-border)',
        },
        focusOutlineColor: 'var(--button-focus-outline-color-default)',
    },
    tertiary: {
        color: 'transparent',
        backgroundColor: 'var(--color-neutrals-100)',
        border: '1px solid var(--color-neutrals-500)',
        hover: {
            color: 'transparent',
            borderColor: '1px solid var(--color-neutrals-400)',
            strokeColor: 'var(--color-neutrals-400)',
        },
        disabled: {
            color: 'var(--color-neutrals-300)',
            borderColor: '1px solid var(--color-neutrals-300)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--color-brand-yellow)',
            backgroundColor: 'var(--color-base-black)',
            borderColor: '1px solid var(--color-base-black)',
            strokeColor: 'var(--color-brand-yellow)',
        },
    },
};

export type ButtonStyles = typeof STYLES;
export type ButtonThemes = typeof THEMES;
export type ButtonTheme = keyof ButtonThemes;
