import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import GetBasket from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/GetBasket';
import buildCartProductTree from '@sportson/core-web/utils/buildCartProductTree';
import getErrorMesage from '@sportson/core-web/utils/getErrorMessage';
import Events, { EVENTS } from '@sportson/core-web/config/events';
import getBasketId from '../utils/getBasketId';
import { initialState } from './index';

export interface GetBasketData {
    basketId?: string;
}

export default createAsyncThunk(
    `basket/get`,
    async (data: GetBasketData = {}, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
        let { basketId } = data;

        if (!basketId) {
            basketId = await getBasketId();
        }

        if (!basketId) {
            return rejectWithValue({ error: 'BasketId is not set.' });
        }

        const mutexLock = await mutex('basket');

        try {
            const response = await GetBasket(basketId);

            if (response?.data) {
                if (response.data.is_editable === false) {
                    mutexLock();
                    // await removeBasket();
                    return rejectWithValue({ error: 'Basket is not editable' });
                }

                mutexLock();
                return fulfillWithValue({ ...response.data, items: buildCartProductTree(response.data.items) });
            }

            throw 'Could not get basket';
        } catch (error) {
            mutexLock();
            return rejectWithValue({ error: getErrorMesage(error) });
        }
    },
);

const pending = (state, action) => {
    state.status = 'pending';
};

const fulfilled = (state, action) => {
    const basket = objectKeysToCamelCase(action.payload, {
        recursive: true,
        modifyInputObject: false,
    });

    Events.trigger(EVENTS.ECOMMERCE.BASKET.RETRIEVED, { basket });

    return { ...state, ...basket, status: 'idle' };
};

const rejected = (state, action) => {
    console.error(action.payload);
    return {
        ...initialState,
        status: 'idle',
    };
};

export const getBasketStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
