export const SIZES = {
    xsmall: {
        padding: '5px 8px',
        gap: '12px',
    },
    small: {
        padding: '8px 12px',
        gap: '4px',
    },
    large: {
        padding: '12px 16px',
        gap: '6px',
    },
};

export const STYLES = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    lineHeight: '1em',
    textDecoration: 'none',
    userSelect: 'none',
    outline: 'none',
    transition: 'var(--transition-primary)',
};

export const THEMES = {
    filled: {
        color: 'var(--boxbutton-filled-color)',
        backgroundColor: 'var(--boxbutton-filled-background)',
        border: 'var(--boxbutton-filled-border)',
        hover: {
            color: 'var(--boxbutton-filled-hover-color)',
            backgroundColor: 'var(--boxbutton-filled-hover-background)',
            border: 'var(--boxbutton-filled-hover-border)',
        },
        disabled: {
            color: 'var(--boxbutton-filled-disabled-color)',
            backgroundColor: 'var(--boxbutton-filled-disabled-background)',
            border: 'var(--boxbutton-filled-disabled-border)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--boxbutton-filled-active-color)',
            backgroundColor: 'var(--boxbutton-filled-active-background)',
            border: 'var(--boxbutton-filled-active-border)',
        },
    },
    outlined: {
        color: 'var(--boxbutton-outlined-color)',
        backgroundColor: 'var(--boxbutton-outlined-background)',
        border: 'var(--boxbutton-outlined-border)',
        hover: {
            color: 'var(--boxbutton-outlined-hover-color)',
            backgroundColor: 'var(--boxbutton-outlined-hover-background)',
            border: 'var(--boxbutton-outlined-hover-border)',
        },
        disabled: {
            color: 'var(--boxbutton-outlined-disabled-color)',
            backgroundColor: 'var(--boxbutton-outlined-disabled-background)',
            border: 'var(--boxbutton-outlined-disabled-border)',
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
        active: {
            color: 'var(--boxbutton-outlined-active-color)',
            backgroundColor: 'var(--boxbutton-outlined-active-background)',
            border: 'var(--boxbutton-outlined-active-border)',
        },
    },
};

export type BoxButtonStyles = typeof STYLES;
export type BoxButtonThemes = typeof THEMES;
export type BoxButtonSizes = typeof SIZES;
export type BoxButtonTheme = keyof BoxButtonThemes;
export type BoxButtonSize = keyof BoxButtonSizes;
