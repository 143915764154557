import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
    type IComparisonProduct,
    getComparisonProductsFromLocalStorage,
    addComparisonProductToLocalStorage,
    removeComparisonProductFromLocalStorage,
    clearComparisonProductsFromLocalStorage,
} from '@sportson/core-web/utils/compareProducts';

const initialState = {
    products: [] as IComparisonProduct[],
};

const slice = createSlice({
    name: 'compare',
    initialState,
    reducers: {
        addCompareProduct(state, action: PayloadAction<IComparisonProduct>) {
            state.products = addComparisonProductToLocalStorage(action.payload);
        },

        removeCompareProduct(state, action: PayloadAction<IComparisonProduct>) {
            state.products = removeComparisonProductFromLocalStorage(action.payload);
        },

        getCompareProduct(state) {
            state.products = getComparisonProductsFromLocalStorage();
        },

        clearCompareProducts(state) {
            clearComparisonProductsFromLocalStorage();
            window?.dispatchEvent(new Event('comparisonCleared'));
            state.products = [];
        },

        hydrateCompareProducts(state) {
            if (typeof window !== 'undefined') {
                state.products = getComparisonProductsFromLocalStorage();
            }
        },
    },
});

export const {
    addCompareProduct,
    removeCompareProduct,
    getCompareProduct,
    clearCompareProducts,
    hydrateCompareProducts,
} = slice.actions;
export const { reducer } = slice;
