import CoreLink from '@grebban/react-core-components/Link';
import styled, { stylesToResponsiveCssPropsByKeys } from '@grebban/style-system-react';
import { type ButtonThemes, STYLES, THEMES } from 'config/branding/buttons';
import applicationBrandingTypography from 'config/branding/typography';
import React from 'react';

const StyledCoreLink = styled(CoreLink)`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
    border: ${({ theme }) => theme.border};
    white-space: pre;

    & > span {
        line-height: 100%;
    }

    svg path {
        fill: ${({ theme }) => theme.color};
        transition: stroke var(--transition-primary);
    }

    &:hover {
        background-color: ${({ theme }) => theme.hover?.backgroundColor || theme.backgroundColor};
        color: ${({ theme }) => theme.hover?.color || theme.color};
        border: ${({ theme }) => theme.hover?.border || theme.border};

        svg path {
            fill: ${({ theme }) => theme.hover?.color || theme.color};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.active?.backgroundColor || theme.backgroundColor};
        color: ${({ theme }) => theme.active?.color || theme.color};
        border: ${({ theme }) => theme.active?.border || theme.border};

        svg path {
            fill: ${({ theme }) => theme.active?.color || theme.color};
        }
    }

    &:disabled {
        background-color: ${({ theme }) => theme.disabled?.backgroundColor || theme.backgroundColor};
        color: ${({ theme }) => theme.disabled?.color || theme.color};
        border: ${({ theme }) => theme.disabled?.border || theme.border};
        pointer-events: none;
        cursor: not-allowed;

        svg path {
            fill: ${({ theme }) => theme.disabled?.color || theme.color};
        }

        &:hover {
            background-color: ${({ theme }) => theme.disabled?.backgroundColor || theme.backgroundColor};
            color: ${({ theme }) => theme.disabled?.color || theme.color};
            border: ${({ theme }) => theme.disabled?.border || theme.border};
        }
    }
`;

// @TODO Remove extend when styled package is correct.
export interface LinkButtonProps extends Record<string, unknown> {
    children: React.ReactNode | typeof React.Children;
    disabled?: boolean;
    fullWidth?: boolean;
    theme?: keyof ButtonThemes;
    to?: string;
    typography?: string | string[];
}
const LinkButton: React.FunctionComponent<LinkButtonProps> = ({
    children,
    disabled = false,
    fullWidth = false,
    theme = 'primary',
    to = '',
    typography = ['Regular-14_130_-1', null, null, 'Regular-16_130_-1', null],
    ...rest
}) => (
    <StyledCoreLink
        style={STYLES}
        // @TODO The style package is actually incorrect typed, it should be "string | Array<string | null>" and not only strings.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Reason: the stylesToResponsiveCssPropsByKeys uses the style package, but it is not correctly ts yet.
        // @ts-ignore
        {...stylesToResponsiveCssPropsByKeys(typography, applicationBrandingTypography)}
        to={disabled ? null : to}
        disabled={disabled}
        theme={THEMES[theme]}
        height={['42px', null, null, null, '48px']}
        width={fullWidth ? '100%' : 'fit-content'}
        p={['8px 24px', null, null, null, '12px 32px']}
        {...rest}
    >
        {children}
    </StyledCoreLink>
);

export default LinkButton;
