const parsedVariables = {};

const getCountrySpecificVariable = (variable, locale = 'sv_SE') => {
    if (!parsedVariables[variable]) {
        try {
            parsedVariables[variable] = JSON.parse(process.env[variable] || '{}');
        } catch (e) {
            parsedVariables[variable] = process.env[variable];
        }
    }

    return parsedVariables[variable][locale] || parsedVariables[variable];
};

export default getCountrySpecificVariable;
