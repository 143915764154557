import React from 'react';
import Svg from '@grebban/react-core-components/svg/Svg';
import Path from '@grebban/react-core-components/svg/Path';
import { type Icon } from '@sportson/core-web/utils/types/iconTypes';

export interface SearchIconProps extends Icon, Record<string, unknown> {}

export const SearchIcon: React.FunctionComponent<SearchIconProps> = ({
    width = '16px',
    height = '16px',
    color = 'currentColor',
    fill = 'none',
    ...props
}) => (
    <Svg color={color} width={width} height={height} fill={fill} viewBox="0 0 17 16" {...props}>
        <Path
            fill={fill}
            fillRule="evenodd"
            d="M10.724 11.429a6.496 6.496 0 1 1 .707-.707l3.924 3.924-.707.708-3.924-3.925Zm1.268-4.933A5.496 5.496 0 1 1 1 6.496a5.496 5.496 0 0 1 10.992 0Z"
            clipRule="evenodd"
        />
    </Svg>
);
