import React from 'react';
import styled from '@grebban/style-system-react';
import { type TransitionsCssResponsiveVariables } from 'config/branding/transitions';

const Wrapper = styled('div')`
    display: grid;
    grid-template-rows: 0fr;
    transition-property: grid-template-rows;

    &.open {
        grid-template-rows: 1fr;
    }
`;

const Content = styled('div')`
    overflow: hidden;
`;

type Props = {
    children: React.ReactNode | typeof React.Children;
    transition?: string;
    isOpen: boolean;
};

/**
 *  Component for animating the height property
 *
 * @component
 * @prop {React.ReactNodes} children ReactNodes to animate
 * @prop {boolean} isOpen Show or hide content
 * @prop {boolean} transition  Any transition-properties except 'transition-property' (always: grid-template-rows)
 *
 * @example
 * <AnimateHeight isOpen={isOpen} transition="2s ease-in-out">
 *  <p>Content</p>
 *  <p>More content</p>
 * </AnimateHeight>
 */

export const AnimateHeight = ({ children, isOpen, transition, ...rest }: Props) => (
    <Wrapper className={isOpen ? 'open' : ''} transition={transition} {...rest}>
        <Content>{children}</Content>
    </Wrapper>
);
