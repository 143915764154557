import Gtm from '@grebban/gtm';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import Events from '@sportson/core-web/libs/Events';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import tracking from '@sportson/core-web/components/Tracking';

const GTM = () => {
    const GTM_TRACKING_KEY = process.env.REACT_APP_GTM_TRACKING_KEY;

    if (!GTM_TRACKING_KEY) {
        return null;
    }

    // Gather redux values used in gtm package
    const applicationPath = useAppSelector(({ application }) => (application.site.path || '').replace(/\/$/g, ''));
    const applicationLocale = useAppSelector(({ application }) => application.site.locale);
    const applicationId = useAppSelector(({ application }) => application.site.applicationId);
    const applicationUrl = useAppSelector(({ application }) => (application?.site?.url || '').replace(/\/$/g, ''));
    const applicationDomain = useAppSelector(({ application }) => application.site.domain);

    const ecommerceCurrency = useAppSelector(({ application }) => application.site?.currency?.code);
    const pageSlug = useAppSelector(({ application }) => application.site?.currentPathname || '');

    useEffect(() => {
        const eventsWithHandles = tracking.trackingEvents();
        // Initiate the tracking events
        eventsWithHandles.forEach((event) => {
            event.handle = Events.subscribe(event.name, event.callback);
        });

        // Unsubscribe on demount
        return () =>
            eventsWithHandles.forEach((event) => {
                Events.unsubscribe(event.name, event.handle);
            });
    }, []);

    return (
        <Gtm
            helmetComponent={Helmet}
            dependencies={[
                {
                    name: 'Cookiebot',
                    cbid: 'ad784b85-6c69-4938-98ad-bfa61098c720',
                    consentModeEnabled: true,
                },
            ]}
            consentModeEnabled={false}
            trackingKey={GTM_TRACKING_KEY}
            initialVariables={{
                currency: ecommerceCurrency,
                locale: applicationLocale,
                site_id: applicationId,
                url: `${applicationUrl}${pageSlug}`,
                domain: applicationDomain,
                path: applicationPath,
            }}
        />
    );
};

export default GTM;
