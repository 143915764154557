import { createSlice } from '@reduxjs/toolkit';
import resolve from '@sportson/core-web/state/application/resolve';

interface Currency {
    id: number;
    name: string;
    code: string;
    prefix: string | null;
    suffix: string | null;
}

interface Language {
    id: number;
    name: string;
    locale: string;
    iso: string | null;
    uri: string | null;
    currency: Currency;
}

interface PriceList {
    id: number;
    code: string;
}

interface PriceGroup {
    id: number;
    applicationId: number;
    code: string;
    priceIncVat: string;
    order: number;
    createdAt: string;
    updatedAt: string;
    overrideStormPrices: number;
}
export interface ApplicationState {
    applicationId: number | null;
    applicationName: string | null;
    currency: Currency | null;
    currentHref: string;
    currentPathname: string;
    defaultPriceList: PriceList;
    ipAddress: string | null;
    isFetching: boolean;
    isSupportingWebp: boolean;
    languages: Language[];
    locale: string;
    origin: string;
    path: string | null;
    priceGroups: PriceGroup[] | null;
    protocol: string | null;
    status: 'untouched' | 'idle' | 'pending';
}

const initialState: ApplicationState = {
    applicationId: null,
    applicationName: null,
    currency: null,
    currentHref: '',
    currentPathname: '/',
    defaultPriceList: {
        id: 1,
        code: '',
    },
    ipAddress: '',
    isFetching: false,
    isSupportingWebp: false,
    languages: [],
    locale: '',
    origin: '',
    path: null,
    priceGroups: [],
    protocol: 'https://',
    status: 'untouched',
};

const slice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        setHostState: (state, action) => {
            state.currentHref = action.payload.currentHref;
            state.origin = action.payload.origin;
            state.currentPathname = action.payload.currentPathname;
            state.protocol = action.payload.protocol;
            state.isSupportingWebp = action.payload.isSupportingWebp;
            state.ipAddress = action.payload.ipAddress;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(resolve.pending, (state) => {
                state.isFetching = true;
                state.status = 'pending';
            })
            .addCase(resolve.fulfilled, (state, action) => ({
                ...state,
                applicationId: action.payload?.data?.application.id,
                applicationName: action.payload?.data?.application.name,
                currency: action.payload?.data?.currency,
                defaultPriceList: action.payload?.data?.default_price_list,
                languages: action.payload?.data?.languages,
                locale: action.payload?.data?.application.locale,
                path: action.payload?.data?.application.path,
                priceGroups: action.payload?.data?.price_groups,
                status: 'idle',
            }))
            .addCase(resolve.rejected, (state) => {
                state.isFetching = true;
                state.status = 'idle';
            });
    },
});

export const { setHostState } = slice.actions;
export const { reducer } = slice;
