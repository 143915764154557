import Path from '@grebban/react-core-components/svg/Path';
import Svg from '@grebban/react-core-components/svg/Svg';

const sizes = {
    xsmall: {
        maskId: 'mask0_11322_143214',
        viewBox: '0 0 12 12',
        width: '12px',
        height: '12px',
        path: 'M3.5 11C3.225 11 2.98958 10.9021 2.79375 10.7063C2.59792 10.5104 2.5 10.275 2.5 10C2.5 9.725 2.59792 9.48958 2.79375 9.29375C2.98958 9.09792 3.225 9 3.5 9C3.775 9 4.01042 9.09792 4.20625 9.29375C4.40208 9.48958 4.5 9.725 4.5 10C4.5 10.275 4.40208 10.5104 4.20625 10.7063C4.01042 10.9021 3.775 11 3.5 11ZM8.5 11C8.225 11 7.98958 10.9021 7.79375 10.7063C7.59792 10.5104 7.5 10.275 7.5 10C7.5 9.725 7.59792 9.48958 7.79375 9.29375C7.98958 9.09792 8.225 9 8.5 9C8.775 9 9.01042 9.09792 9.20625 9.29375C9.40208 9.48958 9.5 9.725 9.5 10C9.5 10.275 9.40208 10.5104 9.20625 10.7063C9.01042 10.9021 8.775 11 8.5 11ZM3.075 3L4.275 5.5H7.775L9.15 3H3.075ZM2.6 2H9.975C10.1667 2 10.3125 2.08542 10.4125 2.25625C10.5125 2.42708 10.5167 2.6 10.425 2.775L8.65 5.975C8.55833 6.14167 8.43542 6.27083 8.28125 6.3625C8.12708 6.45417 7.95833 6.5 7.775 6.5H4.05L3.5 7.5H9.5V8.5H3.5C3.125 8.5 2.84167 8.33542 2.65 8.00625C2.45833 7.67708 2.45 7.35 2.625 7.025L3.3 5.8L1.5 2H0.5V1H2.125L2.6 2Z',
    },
    small: {
        maskId: 'mask0_14227_23091',
        viewBox: '0 0 16 16',
        width: '16px',
        height: '16px',
        path: 'M4.66699 14.6668C4.30033 14.6668 3.98644 14.5363 3.72533 14.2752C3.46421 14.0141 3.33366 13.7002 3.33366 13.3335C3.33366 12.9668 3.46421 12.6529 3.72533 12.3918C3.98644 12.1307 4.30033 12.0002 4.66699 12.0002C5.03366 12.0002 5.34755 12.1307 5.60866 12.3918C5.86977 12.6529 6.00033 12.9668 6.00033 13.3335C6.00033 13.7002 5.86977 14.0141 5.60866 14.2752C5.34755 14.5363 5.03366 14.6668 4.66699 14.6668ZM11.3337 14.6668C10.967 14.6668 10.6531 14.5363 10.392 14.2752C10.1309 14.0141 10.0003 13.7002 10.0003 13.3335C10.0003 12.9668 10.1309 12.6529 10.392 12.3918C10.6531 12.1307 10.967 12.0002 11.3337 12.0002C11.7003 12.0002 12.0142 12.1307 12.2753 12.3918C12.5364 12.6529 12.667 12.9668 12.667 13.3335C12.667 13.7002 12.5364 14.0141 12.2753 14.2752C12.0142 14.5363 11.7003 14.6668 11.3337 14.6668ZM4.10033 4.00016L5.70033 7.3335H10.367L12.2003 4.00016H4.10033ZM3.46699 2.66683H13.3003C13.5559 2.66683 13.7503 2.78072 13.8837 3.0085C14.017 3.23627 14.0225 3.46683 13.9003 3.70016L11.5337 7.96683C11.4114 8.18905 11.2475 8.36127 11.042 8.4835C10.8364 8.60572 10.6114 8.66683 10.367 8.66683H5.40033L4.66699 10.0002H12.667V11.3335H4.66699C4.16699 11.3335 3.78921 11.1141 3.53366 10.6752C3.2781 10.2363 3.26699 9.80016 3.50033 9.36683L4.40033 7.7335L2.00033 2.66683H0.666992V1.3335H2.83366L3.46699 2.66683Z',
    },
};

export const Cart = ({
    color = 'var(--color-base-black)',
    size = 'small',
    height = '16px',
    width = '16px',
    viewBox = '0 0 16 16',
    ...rest
}) => {
    const selectedSize = sizes[size] || sizes.small;
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || selectedSize.width}
            height={height || selectedSize.height}
            viewBox={viewBox || selectedSize.viewBox}
            fill="none"
            {...rest}
        >
            <mask
                id={selectedSize.maskId}
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width}
                height={height}
            >
                <rect width={width} height={height} fill="#D9D9D9" />
            </mask>
            <g mask={`url(#${selectedSize.maskId})`}>
                <Path d={selectedSize.path} fill={color} />
            </g>
        </Svg>
    );
};
