import Svg from '@grebban/react-core-components/svg/Svg';
import Path from '@grebban/react-core-components/svg/Path';
import styled from '@grebban/style-system-react';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';

const DirectionDiv = styled('div')`
    display: flex;
    transform: rotate(${({ rotation }) => rotation});
`;

const directions = {
    right: '0deg',
    left: '180deg',
    up: '270deg',
    down: '90deg',
};

const sizes = {
    xsmall: {
        viewBox: '0 0 12 12',
        width: '12px',
        height: '12px',
        pathOne: 'M11 6H1M11 6L5.09091 11',
        pathTwo: 'M11 6L5.09091 1',
    },

    small: {
        viewBox: '0 0 16 16',
        width: '16px',
        height: '16px',
        pathOne: 'M14 8H2M14 8L8 14',
        pathTwo: 'M14 8L8 2',
    },

    large: {
        viewBox: '0 0 24 24',
        width: '24px',
        height: '24px',
        pathOne: 'M22 12H2M22 12L12 22',
        pathTwo: 'M22 12L12 2',
    },
};
export interface ArrowIconProps {
    color?: CssVariable<CssColorKey>;
    size?: 'xsmall' | 'small' | 'large';
    height?: string | string[];
    width?: string | string[];
    viewBox?: string | string[];
    direction?: 'left' | 'right' | 'up' | 'down';
}

export const ArrowIcon = ({
    color = 'var(--color-base-black)',
    size = 'small',
    direction = 'right',
    height,
    width,
    viewBox,
    ...rest
}: ArrowIconProps) => {
    const selectedSize = sizes[size] || sizes.small;
    return (
        <DirectionDiv rotation={directions[direction]} width={selectedSize.width} height={selectedSize.height}>
            <Svg
                width={width || selectedSize.width}
                height={height || selectedSize.height}
                viewBox={viewBox || selectedSize.viewBox}
                fill="none"
                xmlns="http://www.w3.org/2000/Styled"
                {...rest}
            >
                <Path d={`${selectedSize.pathOne}${selectedSize.pathTwo}`} stroke={color} />
            </Svg>
        </DirectionDiv>
    );
};
