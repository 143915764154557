import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createSlice } from '@reduxjs/toolkit';
import resolveApplication from '@sportson/core-web/state/application/resolve';

export interface IHeaderState {
    data: Record<string, any>;
}

const initialState: IHeaderState = {
    data: {},
};
const slice = createSlice({
    name: 'header',
    initialState,
    reducers: { setHeaderState: (state, { payload }) => ({ ...state, ...payload }) },
    extraReducers(builder) {
        builder.addCase(resolveApplication.fulfilled, (state, { payload }) => {
            const data = objectKeysToCamelCase(payload?.data?.config?.header || {}, {
                recursive: true,
                modifyInputObject: false,
            });
            const bannerShowing = !!data?.announcementUsps?.length;
            return !Object.keys(data).length ? state : { ...state, bannerShowing, data };
        });
    },
});

export const { setHeaderState } = slice.actions;
export const { reducer } = slice;
