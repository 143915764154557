import React from 'react';
import PropTypes from 'prop-types';
import Path from '@grebban/react-core-components/svg/Path';
import Svg from '@grebban/react-core-components/svg/Svg';

const ChevronLeft = ({
    color = 'var(--color-base-black)',
    height = '11px',
    width = '6px',
    viewBox = '0 0 6 11',
    ...rest
}) => (
    <Svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <Path
            d="M5.79723 1.22466C6.06759 0.944505 6.06759 0.490278 5.79723 0.210119C5.52686 -0.0700397 5.08852 -0.0700398 4.81816 0.210119L0.202772 4.99273C-0.0675912 5.27289 -0.0675912 5.72711 0.202772 6.00727L4.81816 10.7899C5.08852 11.07 5.52686 11.07 5.79723 10.7899C6.06759 10.5097 6.06759 10.0555 5.79723 9.77534L1.67138 5.5L5.79723 1.22466Z"
            fill={color}
        />
    </Svg>
);

ChevronLeft.propTypes = {
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ChevronLeft;
