const imgIxParameterMapping: Record<keyof GenerateImgixSrcOptionsOptions, unknown> = {
    width: 'w',
    height: 'h',
    format: 'fm',
    backgroundColor: 'bg',
    quality: 'q',
    auto: 'auto',
    fit: 'fit',
    crop: 'crop',
    mask: 'mask',
};

const defaultOptions: GenerateImgixSrcOptionsOptions = {
    backgroundColor: 'transparent',
    quality: 80,
    auto: 'format',
    fit: 'clip',
};

// @TODO match the parameters with correct values from ImgIX instead of "string" etc.
export interface GenerateImgixSrcOptionsOptions {
    width?: number;
    height?: number;
    format?: string; // The output format to convert the image to.
    backgroundColor?: string; // Color to fill in any transparent areas
    quality?: string | number; // Controls the output quality of lossy file formats (jpg, pjpg, webp, or jxr).
    auto?: string; // The auto parameter helps you automate a baseline level of optimization across your entire image catalog.
    fit?: string; // The fit parameter controls how the output image is fit to its target dimensions after resizing, and how any background areas will be filled.
    crop?: string; // Crop mode controls how the image is aligned when fit=crop is set.
    mask?: string; // Sets the type of mask from a pre-defined list of shapes, or from a transparent image URL.
}

export type GenerateImgixSrc = (src: string, options?: GenerateImgixSrcOptionsOptions) => string;
const generateImgixSrc: GenerateImgixSrc = (src, options = defaultOptions) => {
    options = { ...defaultOptions, ...options };
    const parameters = Object.keys(options).map((key) => `${imgIxParameterMapping[key]}=${options[key]}`);
    if (parameters.length) {
        return `${src}?${parameters.join('&')}`;
    }
    return src;
};

export default generateImgixSrc;
