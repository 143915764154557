import VideoBackground from '@grebban/react-core-components/VideoBackground';
import React from 'react';

export interface BackgroundVideoProps {
    children?: React.ReactNode | typeof React.Children;
    src: string | string[];
    preload?: boolean;
    poster?: string | string[];
}

const BackgroundVideo = (props: BackgroundVideoProps) => <VideoBackground width="100%" height="100%" {...props} />;

export default BackgroundVideo;
