import { createSlice } from '@reduxjs/toolkit';
import getProductRating, { getProductRatingCallbacks } from './getProductRating';
import resolve, { resolvePageStateCallbacks } from './resolve';

export interface PageState {
    isFetching: boolean;
    data: Record<string, unknown> | null;
    type: string | null;
    template: string | null;
    relationships: object[] | null;
    localeAlternates: [];
    search: string;
    status: 'untouched' | 'idle' | 'pending';
}

// The initial state of this store.
const initialState: PageState = {
    isFetching: false,
    data: null,
    type: null,
    template: null,
    relationships: null,
    localeAlternates: [],
    search: '',
    status: 'untouched',
};

const slice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        updateSearch: (state, action) => {
            let search = '';
            // @ TODO just a temporary fix before Voyado Elevate to get a query for some testing purposes.

            // if (typeof window !== 'undefined') {
            //     search = window.location.search;
            // } else {
            search = action.payload.search;
            // }
            state.search = search;
        },
        update: (state, action) => ({
            ...state,
            ...action.payload,
        }),
    },
    extraReducers(builder) {
        builder
            .addCase(resolve.pending, resolvePageStateCallbacks.pending)
            .addCase(resolve.fulfilled, resolvePageStateCallbacks.fulfilled)
            .addCase(resolve.rejected, resolvePageStateCallbacks.rejected)

            .addCase(getProductRating.pending, getProductRatingCallbacks.pending)
            .addCase(getProductRating.fulfilled, getProductRatingCallbacks.fulfilled);
    },
});

export { resolve };
export const { update, updateSearch } = slice.actions;
export const { reducer } = slice;
