import { createSlice } from '@reduxjs/toolkit';
import store from '@sportson/core-web/state/store';
import Cookies, { COOKIES_CONSTANTS } from '@sportson/core-web/config/cookies';
import getCheckout, { getCheckoutStateCallbacks } from './getCheckout';
import getPaymentForm, { getPaymentFormStateCallbacks } from './getPaymentForm';

export interface ICheckoutState extends Record<string, any> {
    basket: object;
    buyer: object;
    deliveryMethods: object[];
    payer: object;
    payment: object;
    paymentMethods: object[];
    paymentReady: boolean;
    payments: {
        paymentCode: number;
        amount: number;
        cardNo: string;
        paymentMethodId: number;
        paymentServiceId: number;
    }[];
    shipTo: object;
    status: 'untouched' | 'pending' | 'idle';
    virtualBasket: boolean;
}
export const initialState: ICheckoutState = {
    basket: {},
    buyer: {},
    deliveryMethods: [],
    payer: {},
    payment: {},
    paymentMethods: [],
    paymentReady: false,
    payments: [],
    shipTo: {},
    status: 'untouched',
    virtualBasket: false,
};

const slice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        initCheckout: (state: ICheckoutState, action) => {
            Cookies.remove(COOKIES_CONSTANTS.ECOMMERCE.BASKET_COOKIE_NAME, {
                path: store.getState().application.site.path?.replace?.(/\/+$/, '') || '/',
            });

            return { ...initialState };
        },
        setPaymentReady: (state: ICheckoutState, action) => {
            state.paymentReady = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCheckout.pending, getCheckoutStateCallbacks.pending)
            .addCase(getCheckout.fulfilled, getCheckoutStateCallbacks.fulfilled)
            .addCase(getCheckout.rejected, getCheckoutStateCallbacks.rejected)
            .addCase(getPaymentForm.pending, getPaymentFormStateCallbacks.pending)
            .addCase(getPaymentForm.fulfilled, getPaymentFormStateCallbacks.fulfilled)
            .addCase(getPaymentForm.rejected, getPaymentFormStateCallbacks.rejected);
    },
});

export const { initCheckout, setPaymentReady } = slice.actions;
export const { reducer } = slice;
