import React from 'react';
import styled from '@grebban/style-system-react';
import PropTypes from 'prop-types';

const StyledWrapper = styled('div')``;

const Row = ({ display = 'flex', flexDirection = 'row', width = '100%', ...rest }) => (
    <StyledWrapper display={display} flexDirection={flexDirection} width={width} {...rest} />
);

Row.propTypes = {
    display: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    flexDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Row;
