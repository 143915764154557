// import { HEX, RGB, RGBA, CssVariables } from "./types";

// export const colorsCssVariables: CssVariables<RGBA | RGB | HEX | "transparent"> = {
export const colorsCssVariables = {
    // Base colors
    '--color-base-black': '#1B1B1BFF',
    '--color-base-white': '#FFFFFFFF',

    // Brand colors
    '--color-brand-yellow': '#FECB00FF',

    // Neutrals
    '--color-neutrals-100': '#F5F5F5FF',
    '--color-neutrals-200': '#D0D0D0FF',
    '--color-neutrals-300': '#C0C0C0FF',
    '--color-neutrals-400': '#969696FF',
    '--color-neutrals-500': '#676767FF',
    '--color-neutrals-600': '#3C3C3CFF',

    // Transparency colors
    '--color-transparency-black-90': '#000000E6',
    '--color-transparency-black-50': '#00000080',
    '--color-transparency-black-30': '#0000004D',
    '--color-transparency-black-10': '#0000001A',
    '--color-transparency-white-90': '#FFFFFFE6',
    '--color-transparency-white-85': '#FFFFFFD9',
    '--color-transparency-white-50': '#FFFFFF80',
    '--color-transparency-white-30': '#FFFFFF4D',
    '--color-transparency-grey-50': '#3C3C3C80',

    // Semantic colors
    '--color-semantic-green': '#4ED27AFF',
    '--color-semantic-yellow': '#EED23FFF',
    '--color-semantic-red': '#E52626FF',
    '--color-semantic-red-02': '#FF7E7E',
    '--color-semantic-light-red': '#FAE5E5',
    '--color-semantic-blue': '#044778FF',
    '--color-semantic-match': '#FFF0BE',
} as const;

export type CssColors = typeof colorsCssVariables;
export type CssColorKey = keyof CssColors;
