import { inServer } from '@sportson/core-web/constants';

export interface IComparisonProduct {
    id: string;
    image: string;
    name: string;
}

const LOCAL_STORAGE_KEY = 'comparisons';

const getComparisonProductsFromLocalStorage = (): IComparisonProduct[] => {
    if (inServer) return [];

    try {
        const storedProducts = localStorage.getItem(LOCAL_STORAGE_KEY);
        return storedProducts ? JSON.parse(storedProducts) : [];
    } catch (error) {
        console.error('Failed to parse comparison products from localStorage:', error);
        return [];
    }
};

const saveComparisonProductsToLocalStorage = (products: IComparisonProduct[]): IComparisonProduct[] => {
    if (inServer) return [];

    try {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(products));
        return products;
    } catch (error) {
        console.error('Failed to set comparison products to localStorage:', error);
        return [];
    }
};

const isProductInComparisonLocalStorage = (product: IComparisonProduct): boolean => {
    if (inServer) return false;

    const products = getComparisonProductsFromLocalStorage();
    return products.some(({ id }) => id === product.id);
};

const addComparisonProductToLocalStorage = (product: IComparisonProduct): IComparisonProduct[] => {
    if (inServer || isProductInComparisonLocalStorage(product)) return [];

    const products = getComparisonProductsFromLocalStorage();
    products.push(product);
    return saveComparisonProductsToLocalStorage(products);
};

const removeComparisonProductFromLocalStorage = (product: IComparisonProduct): IComparisonProduct[] => {
    if (inServer) return [];

    const products = getComparisonProductsFromLocalStorage().filter(({ id }) => id !== product.id);
    return saveComparisonProductsToLocalStorage(products);
};

const toggleComparisonProductInLocalStorage = (product: IComparisonProduct): IComparisonProduct[] => {
    if (inServer) return [];

    if (isProductInComparisonLocalStorage(product)) {
        return removeComparisonProductFromLocalStorage(product);
    }
    return addComparisonProductToLocalStorage(product);
};

const clearComparisonProductsFromLocalStorage = (): IComparisonProduct[] => {
    !inServer && localStorage.removeItem(LOCAL_STORAGE_KEY);
    return [];
};

export {
    getComparisonProductsFromLocalStorage,
    addComparisonProductToLocalStorage,
    removeComparisonProductFromLocalStorage,
    isProductInComparisonLocalStorage,
    toggleComparisonProductInLocalStorage,
    clearComparisonProductsFromLocalStorage,
};
