import isObject from '@grebban/utils/object/isObject';
import React, { Fragment, PureComponent } from 'react';

import { type WordpressACFWysiwyg, WordpressACFWysiwygContentArray } from 'libs/wordpress/types/acf/fields';
import Bold from 'libs/wordpress/components/Wysiwyg/tags/Bold';
import Heading from 'libs/wordpress/components/Wysiwyg/tags/Heading';
import Italic from 'libs/wordpress/components/Wysiwyg/tags/Italic';
import ListItem from 'libs/wordpress/components/Wysiwyg/tags/ListItem';
import OrderedList from 'libs/wordpress/components/Wysiwyg/tags/OrderedList';
import Span from 'libs/wordpress/components/Wysiwyg/tags/Span';
import SubHeading from 'libs/wordpress/components/Wysiwyg/tags/SubHeading';
import Underline from 'libs/wordpress/components/Wysiwyg/tags/Underline';
import UnorderedList from 'libs/wordpress/components/Wysiwyg/tags/UnorderedList';
import Url from 'libs/wordpress/components/Wysiwyg/tags/Url';

const noTextComponentTags = ['ol', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export type WysiwygComponentContentParts = { tag: string; content: React.ReactNode | React.FC }[];

export interface WysiwygTagComponentGenericProps {
    TextComponent: React.ReactNode | React.FC;
    attrs?: Record<string, unknown>;
}

let components = {
    url: Url,
    i: Italic,
    b: Bold,
    u: Underline,
    ul: UnorderedList,
    ol: OrderedList,
    span: Span,
    li: ListItem,
    h1: ({ children }) => <Heading type="h1">{children}</Heading>,
    h2: ({ children }) => <Heading type="h2">{children}</Heading>,
    h3: ({ children }) => <SubHeading type="h3">{children}</SubHeading>,
    h4: ({ children }) => <SubHeading type="h4">{children}</SubHeading>,
    h5: ({ children }) => <SubHeading type="h5">{children}</SubHeading>,
    h6: ({ children }) => <SubHeading type="h6">{children}</SubHeading>,
};
const defaultValidTags = Object.keys(components);

const DefaultTextComponent: React.FC<React.PropsWithChildren> = ({ children }) => (
    <p style={{ margin: '0' }}>{children}</p>
);

interface NeedsChildren extends Record<string, unknown> {
    children: React.ReactNode;
}

export interface WysiwygPropsOptions {
    renderNewlineOnRowEmptyString?: boolean;
}

export type WysiwygProps = {
    data: WordpressACFWysiwyg;
    tagComponents?: Record<string, React.ReactNode | React.FC>;
    TextComponent?: React.FC<React.PropsWithChildren<NeedsChildren>>;
    validTags?: string[];
    options?: WysiwygPropsOptions;
};

const defaultOptions: WysiwygPropsOptions = {
    renderNewlineOnRowEmptyString: true,
};

const Wysiwyg = ({
    data,
    tagComponents,
    TextComponent = DefaultTextComponent,
    validTags = defaultValidTags,
    options = {},
}: WysiwygProps) => {
    // Don't render Wysiwyg if it's only contains empty values
    if (data === undefined) {
        return null;
    }

    options = { ...defaultOptions, ...options };

    if (
        (Array.isArray(data) && data.filter(Boolean).length < 1) || // Check if array and empty
        (isObject(data) && Object.keys(data).length === 0) || // Check if object and empty
        (typeof data === 'string' && !data.length)
    ) {
        return null;
    }

    components = { ...components, ...tagComponents };

    const validComponents = {};
    validTags.forEach((tag) => {
        const tagComponent = components[tag];
        if (tagComponent) {
            validComponents[tag] = components[tag];
        }
    });

    if (typeof data === 'string') {
        // If the string has new lines, split it into several rows.
        data = data.split('\n') as string[];
    }

    if (typeof data === 'object' && !Array.isArray(data)) {
        data = [data];
    }

    const renderContentRecursive = (
        content: WordpressACFWysiwyg,
        rowIndex,
        contentIndex = 0,
        rowParts: WysiwygComponentContentParts = [],
    ) => {
        if (options.renderNewlineOnRowEmptyString && content === '') {
            return <br />;
        }
        if (!content) return null;

        let renderContent: React.ReactNode;
        if (Array.isArray(content)) {
            renderContent = content.map((subContent, subContentIndex) => (
                <Fragment key={`${rowIndex}.${contentIndex}`}>
                    {renderContentRecursive(subContent, rowIndex, contentIndex + subContentIndex + 1, rowParts)}
                </Fragment>
            ));
        } else if (typeof content === 'object' && isObject(content)) {
            const RenderComponent = validComponents[content.tag] || null;
            if (RenderComponent) {
                renderContent = (
                    <RenderComponent attrs={content.attributes} TextComponent={TextComponent}>
                        {renderContentRecursive(content.content, rowIndex, contentIndex + 1, rowParts)}
                    </RenderComponent>
                );
                rowParts.push({ tag: content.tag, content: renderContent });
            } else {
                // If it is not a valid tag, we just skip the tag and keep printing the content.
                renderContent = renderContentRecursive(content.content, rowIndex, contentIndex + 1, rowParts);
            }
        } else if (typeof content === 'string') {
            renderContent = content;
            rowParts.push({ tag: '', content: renderContent });
        }

        if (contentIndex === 0) {
            // If there are no invalid text parts, we can just wrap everything in the TextComponent
            // Otherwise we need to iterate through the parts and select where to put the TextComponents
            if (!rowParts.filter(({ tag }) => noTextComponentTags.indexOf(tag) !== -1).length) {
                renderContent = <TextComponent>{renderContent}</TextComponent>;
            } else {
                // @TODO NEed to go through the parts and add TextComponent around the valid text tags.
            }
        }
        return renderContent;
    };

    return (
        <>
            {data.map((content, rowIndex) => (
                <Fragment key={`${rowIndex}.${0}`}>{renderContentRecursive(content, rowIndex)}</Fragment>
            ))}
        </>
    );
};

export default Wysiwyg;
