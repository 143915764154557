import styled from '@grebban/style-system-react';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { shallowEqual } from 'react-redux';
import { transformBackgroundGroup } from 'libs/wordpress/utils/transformBackground';
import { transformHeadingGroup, type TransformHeadingOutput } from 'libs/wordpress/utils/transformHeading';
import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';

import {
    type WordpressACFGrebCommerceLink,
    type WordpressACFImage,
    type WordpressACFWysiwyg,
} from 'libs/wordpress/types/acf/fields';
import {
    type WordpressBackgroundSelection,
    type WordpressHeadingGroup,
    type WordpressNewsletterForm,
    type WordpressParagraphGroup,
    type WordpressFooterLinks,
} from 'libs/wordpress/types/contentSystem/groups';

import Text from 'components/Text';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import Background from '@sportson/core-web/components/Background';
import ContentWrapper from 'components/ContentWrapper';
import { SportsonLogo } from 'assets/media/icons/SportsonLogo';
import { NewsletterComponent, type FooterSocialMedia } from './Newsletter';
import { Partners } from './Partners';
import { FeaturedLinks } from './FeaturedLinks';
import { NavigationLinks } from './NavigationLinks';

const BackgroundWrapper = styled('div')`
    position: absolute;
    z-index: -1;
    display: flex;
    inset: 0;
    mix-blend-mode: multiply;

    @supports not (inset: 0) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

const FooterElement = styled('footer')`
    position: relative;
`;

const FooterBottom = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

type FooterHeading = WordpressHeadingGroup;
type FooterParagraph = WordpressParagraphGroup;
type FooterNewsletter = WordpressNewsletterForm;
type FooterLinks = WordpressFooterLinks;

type FooterPartners =
    | false
    | {
          url?: WordpressACFGrebCommerceLink<false>;
          icon: WordpressACFImage;
          name: string;
      }[];
type FooterCopyright = null | WordpressACFWysiwyg;
interface FooterBackground {
    background: WordpressBackgroundSelection;
}

interface NewsletterData {
    heading?: TransformHeadingOutput;
    paragraph?: FooterParagraph;
    newsletter: WordpressNewsletterForm;
    isMobile: boolean;
    socialMedia: FooterSocialMedia;
}

export interface FooterProps {}

const Footer = (props: FooterProps) => {
    const isMobile = useBreakpoint({ to: 'desktop.sm' });
    const device = isMobile ? 'mobile' : 'desktop';

    const heading: FooterHeading = useAppSelector(
        ({ application }) => application?.config?.footer?.newsletter?.data?.heading,
        shallowEqual,
    );

    const paragraph: FooterParagraph = useAppSelector(
        ({ application }) => application?.config?.footer?.newsletter?.data?.paragraph,
        shallowEqual,
    );

    const newsletter: FooterNewsletter = useAppSelector(
        ({ application }) => application?.config?.footer?.newsletter?.data?.newsletterForm,
        shallowEqual,
    );

    const socialMedia: FooterSocialMedia = useAppSelector(
        ({ application }) => application?.config?.footer?.socialMedia,
        shallowEqual,
    );

    const featuredLinks: FooterLinks = useAppSelector(
        ({ application }) => application?.config?.footer?.featuredLinks,
        shallowEqual,
    );

    const navigationLink: FooterLinks = useAppSelector(
        ({ application }) => application?.config?.footer?.navigationLinks,
        shallowEqual,
    );

    const background: FooterBackground = useAppSelector(
        ({ application }) => application?.config?.footer?.background,
        shallowEqual,
    );

    const partners: FooterPartners = useAppSelector(
        ({ application }) => application?.config?.footer?.partners,
        shallowEqual,
    );
    const copyright: FooterCopyright = useAppSelector(
        ({ application }) => application?.config?.footer?.copyright,
        shallowEqual,
    );

    let backgroundColor;
    let backgroundImage;
    let backgroundVideo;
    if (background) {
        const { type: backgroundType, data: backgroundData } = transformBackgroundGroup(background.background, device);
        if (backgroundType === 'color') {
            backgroundColor = backgroundData as BackgroundColorProps;
            backgroundColor.backgroundColor = backgroundColor.backgroundColor || 'var(--color-base-black)';
        } else if (backgroundType === 'image') {
            backgroundImage = backgroundData as BackgroundImageProps;
        } else if (backgroundType === 'video') {
            backgroundVideo = backgroundData as BackgroundVideoProps;
        }
    }

    const newsletterData: NewsletterData = {
        isMobile,
        newsletter,
        socialMedia: socialMedia?.length > 0 ? socialMedia : [],
    };

    if (heading) {
        const headingData: TransformHeadingOutput = transformHeadingGroup(heading, device);
        newsletterData.heading = headingData;
    }

    if (paragraph) {
        newsletterData.paragraph = paragraph;
    }

    if (isMobile) {
        newsletterData.isMobile = isMobile;
    }

    return (
        <FooterElement>
            <ContentWrapper
                display="flex"
                flexDirection="column"
                maxWidth="var(--site-corridor-max-width)"
                alignItems="flex-start"
                p={['48px 12px 24px 12px', null, '48px 16px 24px 16px', null, '80px 40px 24px 40px']}
                gap={['80px', null, null, null, '40px']}
            >
                <SportsonLogo height={isMobile ? '24px' : '40px'} width="auto" color="var(--color-brand-yellow)" />
                <NewsletterComponent {...newsletterData} />
                <FeaturedLinks {...featuredLinks} />
                <NavigationLinks {...navigationLink} />

                <FooterBottom
                    flexDirection={['column', null, null, null, 'row']}
                    gap={['80px', null, null, null, 'unset']}
                >
                    <Partners partners={partners} />

                    {copyright && (
                        <Wysiwyg
                            data={copyright}
                            TextComponent={(props) => (
                                <Text
                                    minWidth="fit-content"
                                    textAlign="left"
                                    marginLeft="auto"
                                    color="var(--color-neutrals-300)"
                                    typography="UI/12_100_0_450"
                                    {...props}
                                />
                            )}
                        />
                    )}
                </FooterBottom>
                <BackgroundWrapper>
                    <Background
                        backgroundVideo={backgroundVideo}
                        backgroundImage={backgroundImage}
                        backgroundColor={backgroundColor}
                    />
                </BackgroundWrapper>
            </ContentWrapper>
        </FooterElement>
    );
};

export default Footer;
