import matchBbcode from '@grebban/utils/regex/expressions/matchBBCode';
import matchHtmlElements from '@grebban/utils/regex/expressions/matchHtmlElements';

// This function will function will cut the description if too long,
// it will also clear html elements and bbcode elements.

const formatMetaDescription = (description) => {
    const str = description.replace(matchHtmlElements, '').replace(matchBbcode, '');
    return str.length > 151 ? str.slice(0, 152).concat('...') : str;
};

export default formatMetaDescription;
