import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createSlice } from '@reduxjs/toolkit';
import { resolve as resolvePage } from '@sportson/core-web/state/page';
import { DEFAULT_POPUP_TIMEOUT_SECONDS } from '@sportson/core-web/state/models/Popups/constants';

export interface IPopup {
    view: string;
    transition: string;
    data: any;
    seconds?: number;
}

export type IPopupState = IPopup;

const initialState: IPopupState = {
    view: '',
    transition: '',
    data: {},
    seconds: DEFAULT_POPUP_TIMEOUT_SECONDS,
};

const slice = createSlice({
    name: 'popups',
    initialState,
    reducers: {
        show: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        update: (state, action) => ({ ...state, ...action.payload }),
        close: () => initialState,
    },
    extraReducers(builder) {
        builder.addCase(resolvePage.fulfilled, (state, action) => {
            const camelizedData = objectKeysToCamelCase(action?.payload, {
                recursive: true,
                modifyInputObject: false,
            });
            return camelizedData.status === 'redirect' ? state : initialState;
        });
    },
});

export {};
export const { show, update, close } = slice.actions;
export const { reducer } = slice;
