import { del } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';

export default async (basketId, discountCode) => {
    const response = await del(`/frontend/basket/${basketId}/discount/${discountCode}`, {
        application_id: getModel('application').site.applicationId,
    });

    return await response;
};
