import { useEffect, useState, type ChangeEvent } from 'react';
import styled from '@grebban/style-system-react';
import validateEmail from '@sportson/core-web/utils/validateEmail';

import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import { type WordpressNewsletterForm } from 'libs/wordpress/types/contentSystem/groups';
import { type TransformHeadingOutput } from 'libs/wordpress/utils/transformHeading';
import { type WordpressIcon } from 'libs/wordpress/types/contentSystem/elements';

import Column from '@sportson/core-web/App/layouts/Column';
import Row from '@sportson/core-web/App/layouts/Row';
import Image from '@sportson/core-web/components/Image';
import Subscribe from '@sportson/core-web/libs/GrebbCommerceAPI/Newsletter/Subscribe';
import Text from 'components/Text';
import Button from 'components/Button';
import Checkbox from 'components/inputs/Checkbox';
import EmailInput from 'components/inputs/EmailInput';
import { SubmitButton } from 'components/SubmitButton';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from '@sportson/core-web/utils/mediaqueries';

const ContentWrapper = styled('div')`
    display: flex;
    width: 100%;
`;

const Form = styled('form')`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    gap: 24px;
`;

const SignupNewsletter = styled('div')`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        'input'
        'checkbox'
        'button';
    gap: 16px;
    width: 100%;

    ${above['tablet.lg']} {
        grid-template-columns: 1fr auto;
        grid-template-rows: ${({ hasError }) => (hasError ? 'auto' : '38px')};
        grid-template-areas:
            'input button'
            'checkbox .';
    }
`;

const EmailInputWrapper = styled('div')`
    grid-area: input;
`;

const StyledCheckbox = styled(Checkbox)`
    grid-area: checkbox;
`;

const StyledSubmitButton = styled(SubmitButton)`
    grid-area: button;
    margin-top: ${({ hasError }) => (hasError ? '38px' : '8px')};

    ${above['tablet.lg']} {
        margin-top: ${({ hasError }) => (hasError ? '34px' : '0px')};
    }
`;

export type FooterSocialMedia = {
    url: string;
    icon: WordpressIcon;
    name: string;
}[];

export interface IsMobile {
    isMobile: boolean;
}

export interface NewsletterComponentProps extends IsMobile {
    heading?: TransformHeadingOutput;
    paragraph?: any; // TODO: FIX type
    newsletter: WordpressNewsletterForm;
    textColor?: CssVariable<CssColorKey>;
    socialMedia?: any; // TODO: FIX type
}

export const NewsletterComponent = ({
    newsletter,
    heading,
    paragraph,
    textColor = 'var(--color-base-white)',
    socialMedia,
}: NewsletterComponentProps) => {
    const [formState, setFormState] = useState({
        emailValid: false,
        emailError: false,
        consent: false,
        consentError: false,
        success: false,
        successMessage: newsletter?.form?.successMessage,
        errorMessage: newsletter?.form?.errorMessage,
        loading: false,
        isDisabled: true,
        responseError: false,
    });

    const [emailInputError, setEmailInputError] = useState(false);

    const handleConsentChange = (value: { checked: boolean }) => {
        value.checked
            ? setFormState({ ...formState, consent: value.checked, consentError: false })
            : setFormState({
                  ...formState,
                  consent: value.checked,
                  consentError: true,
              });
    };

    const validateInput = (e: ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        if (email) {
            const emailValid = validateEmail(email);
            setFormState({ ...formState, emailValid, emailError: !emailValid });
        }
    };

    const submitForm = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setFormState({ ...formState, loading: true });

        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        const data = {
            email: '',
            privacyPolicyConsent: false,
        };

        Object.keys(formProps).forEach((key) => {
            const inputType = key;
            const value = formProps[key];
            if (inputType === 'email') {
                data.email = value.toString();
            } else if (inputType === 'checkbox' && value === 'on') {
                data.privacyPolicyConsent = true;
            }
        });

        const submitData = {
            email: data.email,
        };

        if (data.email && data.privacyPolicyConsent) {
            try {
                const res = await Subscribe(submitData);

                if (res.data) {
                    setFormState({ ...formState, success: true, loading: false });

                    setTimeout(() => {
                        ev.target.reset();
                        setFormState({ ...formState, consent: false, consentError: false });
                    }, 5000);
                } else {
                    console.log('Response Error!');
                    setFormState({ ...formState, success: false, loading: false, responseError: true });

                    setTimeout(() => {
                        ev.target.reset();
                        setFormState({ ...formState, responseError: false });
                    }, 5000);
                }
            } catch (error) {
                console.log('Request Error!');
                console.log(formState.errorMessage, error);
                setFormState({ ...formState, success: false, loading: false });
            }
        }
    };

    useEffect(() => {
        if (formState.success) {
            setFormState({ ...formState, isDisabled: true });

            setTimeout(() => {
                setFormState({ ...formState, success: false });
            }, 5000);
        } else if (formState.emailValid && formState.consent) {
            setFormState({ ...formState, isDisabled: false });
        } else {
            setFormState({ ...formState, isDisabled: true });
        }
    }, [formState.emailValid, formState.consent, formState.success]);

    return (
        <ContentWrapper
            color={textColor}
            flexDirection={['column', null, null, null, 'row']}
            gap={['80px', null, null, null, '24px']}
        >
            <Column width={['100%', null, null, null, '33%']} gap="16px">
                {/* Heading */}
                {heading?.text && (
                    <Wysiwyg
                        data={heading.text}
                        TextComponent={(props) => (
                            <Text
                                as={heading?.type || 'h2'}
                                textAlign="left"
                                color={heading.color || 'var(--color-brand-yellow)'}
                                typography="UI/12_100_0_450_uppercase"
                                {...props}
                            />
                        )}
                    />
                )}

                {/* Paragraph */}
                {paragraph?.text && (
                    <Wysiwyg
                        data={paragraph.text}
                        TextComponent={(props) => (
                            <Text
                                as="p"
                                textAlign="left"
                                color={paragraph.color || 'var(--text-color-white)'}
                                typography="Content/16_150_0_350"
                                {...props}
                            />
                        )}
                    />
                )}

                {/* Social Media listing */}
                {socialMedia && socialMedia.length > 0 && (
                    <Row gap="16px" maxHeight="40px">
                        {socialMedia.map(({ icon, name, url }) => (
                            <Button key={name} theme="secondaryDark" size="xsmall" to={url} aspectRatio="1">
                                <Image src={icon.url} alt={icon.alt ?? name} />
                            </Button>
                        ))}
                    </Row>
                )}
            </Column>

            {newsletter && (
                <Column width={['100%', null, null, null, '66%']} gap="16px">
                    <Wysiwyg
                        data={newsletter?.emailInput?.label}
                        TextComponent={(props) => (
                            <Text
                                as="h2"
                                textAlign="left"
                                color="var(--color-brand-yellow)"
                                typography="UI/12_100_0_450_uppercase"
                                {...props}
                            />
                        )}
                    />
                    <Form
                        onSubmit={submitForm}
                        method="post"
                        width="100%"
                        flexDirection={['column', null, null, null, 'row']}
                    >
                        {/* TODO: Fix regex, allowing incomplete emails (eg: test@test) not requiring the last dot */}
                        <SignupNewsletter hasError={emailInputError}>
                            <EmailInputWrapper>
                                <EmailInput
                                    required
                                    isFooter
                                    hideLabel
                                    name="email"
                                    label="email input"
                                    placeholder={newsletter?.emailInput?.placeholder}
                                    onBlur={validateInput}
                                    customErrors={{
                                        valueMissing: newsletter?.emailInput?.errorMessage,
                                        patternMismatch: newsletter?.emailInput?.errorMessage,
                                        typeMismatch: newsletter?.emailInput?.errorMessage,
                                    }}
                                    onErrorChange={setEmailInputError}
                                />
                            </EmailInputWrapper>
                            <StyledCheckbox
                                required
                                name="checkbox"
                                label={newsletter?.privacyCheckboxInput?.text}
                                checked={formState.consent}
                                onChange={(e) => handleConsentChange(e.target)}
                                customErrors={{
                                    valueMissing: newsletter?.privacyCheckboxInput?.errorMessage,
                                }}
                                errorMsgColor="var(--color-semantic-red-02)"
                            />
                            <StyledSubmitButton
                                height="fit-content"
                                theme="primary"
                                disabled={formState.isDisabled}
                                typography="UI/16_100_0_450"
                                gap="6px"
                                hasError={emailInputError}
                            >
                                {formState.success
                                    ? formState.successMessage
                                    : formState.responseError
                                      ? formState.errorMessage
                                      : newsletter.button.label}
                            </StyledSubmitButton>
                        </SignupNewsletter>
                    </Form>
                </Column>
            )}
        </ContentWrapper>
    );
};
