import React, { type FunctionComponent } from 'react';
import useBreakpoint, { type UseBreakpointOptions } from '@sportson/core-web/hooks/useBreakpoint';
import { type ValidBreakpointLabels } from '@sportson/core-web/config/breakpoints';
import styled from 'libs/styled';

const HideWithCss = styled('div')`
    display: none;
`;

interface BreakpointProps {
    from?: ValidBreakpointLabels;
    to?: ValidBreakpointLabels;
    only?: ValidBreakpointLabels;
    render: any;
    hideWithCss?: boolean;
    options?: UseBreakpointOptions;
}

const Breakpoint: FunctionComponent<BreakpointProps> = ({
    from,
    to,
    only,
    render,
    hideWithCss = false,
    options = {},
}) => {
    const shouldRender = useBreakpoint(only || { from, to }, options);

    if (!shouldRender) {
        return hideWithCss ? <HideWithCss>{render}</HideWithCss> : null;
    }

    return render;
};

export default Breakpoint;
