import React from 'react';
import PropTypes from 'prop-types';
import Path from '@grebban/react-core-components/svg/Path';
import Svg from '@grebban/react-core-components/svg/Svg';

const sizes = {
    xsmall: {
        viewBox: '0 0 12 12',
        width: '12px',
        height: '12px',
        pathOne: 'M11 1L1 11',
        pathTwo: 'M11 11L1 1',
    },

    small: {
        viewBox: '0 0 17 16',
        width: '17px',
        height: '16px',
        pathOne: 'M14.333 2L2.33301 14',
        pathTwo: 'M14.333 14L2.33301 2',
    },

    large: {
        viewBox: '0 0 25 24',
        width: '25px',
        height: '24px',
        pathOne: 'M22.667 2L2.66699 22',
        pathTwo: 'M22.667 22L2.66699 2',
    },
};

const Cross = ({
    color = 'var(--color-base-black)',
    size = 'small',
    height = '',
    width = '',
    viewBox = '',
    ...rest
}) => {
    const selectedSize = sizes[size] || sizes.small;
    return (
        <Svg
            width={width || selectedSize.width}
            height={height || selectedSize.height}
            viewBox={viewBox || selectedSize.viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <Path id="lineOne" d={selectedSize.pathOne} stroke={color} />
            <Path id="lineTwo" d={selectedSize.pathTwo} stroke={color} />
        </Svg>
    );
};

Cross.propTypes = {
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    size: PropTypes.oneOf(['xsmall', 'small', 'large']),
    viewBox: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Cross;
