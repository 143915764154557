import { post } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';

export default async ({ email }) => {
    const data = {
        application_id: getModel('application').site.applicationId,
        email,
        accepts_email: true,
    };

    const response = await post(`/frontend/newsletter/subscribe`, data);

    return await response.body();
};
