import Gtm from '@grebban/gtm';
import tracking from '@sportson/core-web/components/Tracking';
import UpdateProductViewCount from '@sportson/core-web/libs/GrebbCommerceAPI/Products/UpdateProductViewCount';
import { getModel } from '@sportson/core-web/state';
import DataLayer from './DataLayer';

export default (data) => {
    try {
        const application = getModel('application');
        const product = data?.data;

        if (!product) {
            return;
        }

        Gtm.addDataLayerEvent('product_view', {
            algoliaDisplayedObjectIDs: product?.part_no || product.partNo || '',
            ecommerce: {
                // @TODO: Check if basketId is needed for product view
                // basket: { id: basket?.id },
                detail: {
                    products: [DataLayer.getProduct(product)],
                },
            },
            currency: application.site.currency.code,
            eventId: tracking.getUniqueId(),
        });

        // Track product view in API
        if (data?.data?.id && data?.data?.category_id) {
            UpdateProductViewCount(data.data.id, data.data.category_id);
        }
    } catch (e) {
        //
    }
};
