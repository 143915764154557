import { toSnakeCase } from './string';

// import { camelizeKeys } from 'fast-case';

export type LooseObject = { [key: string]: any };

export const isEmpty = (o: LooseObject) => Object.keys(o).length === 0;

export const isObject = (o: any) => o && typeof o === 'object' && !Array.isArray(o);

// export const keysToCamel = (o: LooseObject): any => camelizeKeys(o);

export const keysToSnake = (o: LooseObject): any => {
    if (isObject(o)) {
        const n: LooseObject = {};

        Object.keys(o).forEach((k) => {
            n[toSnakeCase(k)] = o[k];
        });

        return n;
    }
    return o;
};

export const cloneDeep = (inObject: any) => {
    if (typeof inObject !== 'object' || inObject === null) {
        return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    const outObject: any = Array.isArray(inObject) ? [] : {};

    for (const key in inObject) {
        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = cloneDeep(inObject[key]);
    }

    return outObject;
};

/**
 * @param {LooseObject} o - The object that should be manipulated
 * @param {string} p - Path to the key to be removed. Can be 'key' or 'nested.key'
 */
export const deleteKey = (o: LooseObject, p: string) => {
    const keys = p.split('.').filter((v) => v);
    keys.reduce((acc, key, i) => {
        if (i === keys.length - 1) {
            delete acc[key];
            return true;
        }
        return acc[key];
    }, o);
};

/**
 * @param {LooseObject} o - The object that should be searched
 * @param {any} v - Value to search for
 * @param {string} p - Nested path for recursive search
 * @return {string[]} - Array of paths to the keys with a matched search value
 */
export const recursiveSearch = (o: LooseObject, v: any, p = '') => {
    let props: string[] = [];

    for (const key in o) {
        if (o[key] === v) {
            props.push(`${p}.${key}`);
        }

        if (o[key] instanceof Object) {
            props = [...props, ...recursiveSearch(o[key], v, `${p}.${key}`)];
        }
    }

    return props;
};

/**
 * @param {LooseObject} o - The object that should be manipulated
 * @param {any} v - Delete all keys and nested keys with this value
 */
export const recursiveDeleteKeyByValue = (o: LooseObject, v: any) => {
    const keys = recursiveSearch(o, v);
    return keys?.forEach((k) => {
        deleteKey(o, k);
    });
};
