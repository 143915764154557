import Svg from '@grebban/react-core-components/svg/Svg';
import Path from '@grebban/react-core-components/svg/Path';
import React from 'react';

interface MenuProps {
    color?: string | string[];
    height?: string | string[];
    width?: string | string[];
    viewBox?: string | string[];
}

const Menu: React.FunctionComponent<MenuProps> = ({
    color = 'currentColor',
    height = '16px',
    width = '16px',
    viewBox = '0 0 16 16',
    ...rest
}) => (
    <Svg width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
        <Path d="M0 1.45h16M0 7.45h16M0 13.45h16" width={width} height={height} stroke={color} strokeWidth="1.1" />
    </Svg>
);

export default Menu;
