import { post } from '@sportson/core-web/libs/GrebbCommerceAPI/util/authClient';
import { getModel } from '@sportson/core-web/state';
import tracking from '@sportson/core-web/components/Tracking';
import { STORM_PAYMENT_METHOD_RESURS } from 'config/constants';

export default async (ipAddress, eventId) => {
    const clientId = tracking.getClientId();
    const sessionId = tracking.getSessionId();
    const eventTimestamp = tracking.getEventTimestamp();
    const consentMarketing = tracking.getConsentMarketing();
    const consentStatistic = tracking.getConsentStatistic();
    const fbp = tracking.getFacebookFbp();
    const fbc = tracking.getFacebookFbc();
    const clientUserAgent = tracking.getFacebookClientUserAgent() || '';
    const clientIpAddress = getModel('application').ipAddress;

    const data = {
        application_id: getModel('application').site.applicationId,
        ip_address: ipAddress,
        payment_method_id: STORM_PAYMENT_METHOD_RESURS || null,
        consent_marketing: consentMarketing,
        consent_statistic: consentStatistic,
    };

    if (clientId) {
        data.tracking_client_id = clientId;
    }

    if (sessionId) {
        data.tracking_session_id = sessionId;
    }

    if (eventTimestamp) {
        data.tracking_event_timestamp = eventTimestamp;
    }

    if (fbp) {
        data.tracking_fbp = fbp;
    }

    if (fbc) {
        data.tracking_fbc = fbc;
    }

    if (clientUserAgent) {
        data.tracking_client_user_agent = clientUserAgent;
    }

    if (clientIpAddress) {
        data.tracking_client_ip_address = clientIpAddress;
    }

    if (eventId) {
        data.tracking_event_id = eventId;
    }

    const response = await post('/frontend/basket', data);
    return await response.body();
};
