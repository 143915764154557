import produce from 'immer';
import {
    // Search
    SEARCH,
    SEARCH_CLEAR,
    SEARCH_CLEAR_ERROR,
    SEARCH_CLEAR_SUCCESS,
    SEARCH_DEFAULT_QUERY,
    SEARCH_DEFAULT_QUERY_ERROR,
    SEARCH_DEFAULT_QUERY_SUCCESS,
    SEARCH_ERROR,
    SEARCH_SUCCESS,
} from './constants';

// The initial state of this store.
const initialState = {
    isFetching: false,
    query: '',
};

export default produce((state, action) => {
    switch (action.type) {
        // Search
        case SEARCH:
            state.isFetching = true;
            break;
        case SEARCH_SUCCESS:
            state.isFetching = false;
            state.query = action.query;
            break;
        case SEARCH_ERROR:
            state.isFetching = false;
            break;
        case SEARCH_CLEAR:
            state.isFetching = true;
            break;
        case SEARCH_CLEAR_SUCCESS:
            state.isFetching = false;
            state.query = '';
            break;
        case SEARCH_CLEAR_ERROR:
            state.isFetching = false;
            break;
        case SEARCH_DEFAULT_QUERY:
            state.isFetching = true;
            break;
        case SEARCH_DEFAULT_QUERY_SUCCESS:
            state.query = action.query;
            break;
        case SEARCH_DEFAULT_QUERY_ERROR:
            state.isFetching = false;
            break;
        default:
            break;
    }
}, initialState);
