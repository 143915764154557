import { createSlice } from '@reduxjs/toolkit';
import Cookies, { COOKIES_CONSTANTS } from '@sportson/core-web/config/cookies';
import store from '@sportson/core-web/state/store';
import getCheckout from '../checkout/getCheckout';
import addProductToBasket, { addProductToBasketStateCallbacks } from './addProductToBasket';
import addVoucher, { addVoucherStateCallbacks } from './addVoucher';
import createBasket, { createBasketStateCallbacks } from './createBasket';
import deleteBasketItem, { deleteBasketItemStateCallbacks } from './deleteBasketItem';
import getBasket, { getBasketStateCallbacks } from './getBasket';
import removeVoucher, { removeVoucherStateCallbacks } from './removeVoucher';
import updateBasketItem, { updateBasketItemStateCallbacks } from './updateBasketItem';

export interface IBasketState extends Record<string, any> {
    status: 'untouched' | 'pending' | 'idle';
    items: any[];
}
export const initialState: IBasketState = {
    status: 'untouched',
    items: [],
};

const slice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        resetBasketState: (state: IBasketState, action) => {
            Cookies.remove(COOKIES_CONSTANTS.ECOMMERCE.BASKET_COOKIE_NAME, {
                path: store.getState().application.site.path?.replace?.(/\/+$/, '') || '/',
            });

            return { ...initialState };
        },
        setBasket: (state, action) => {
            Cookies.set(COOKIES_CONSTANTS.ECOMMERCE.BASKET_COOKIE_NAME, action.payload.basket.id, {
                path: store.getState().application.site.path?.replace?.(/\/+$/, '') || '/',
                expires: COOKIES_CONSTANTS.DEFAULT_LIFESPAN,
            });

            return { ...state, ...action.payload.basket };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addProductToBasket.pending, addProductToBasketStateCallbacks.pending)
            .addCase(addProductToBasket.fulfilled, addProductToBasketStateCallbacks.fulfilled)
            .addCase(addProductToBasket.rejected, addProductToBasketStateCallbacks.rejected)

            .addCase(createBasket.pending, createBasketStateCallbacks.pending)
            .addCase(createBasket.fulfilled, createBasketStateCallbacks.fulfilled)
            .addCase(createBasket.rejected, createBasketStateCallbacks.rejected)

            .addCase(getBasket.pending, getBasketStateCallbacks.pending)
            .addCase(getBasket.fulfilled, getBasketStateCallbacks.fulfilled)
            .addCase(getBasket.rejected, getBasketStateCallbacks.rejected)

            .addCase(updateBasketItem.pending, updateBasketItemStateCallbacks.pending)
            .addCase(updateBasketItem.fulfilled, updateBasketItemStateCallbacks.fulfilled)
            .addCase(updateBasketItem.rejected, updateBasketItemStateCallbacks.rejected)

            .addCase(deleteBasketItem.pending, deleteBasketItemStateCallbacks.pending)
            .addCase(deleteBasketItem.fulfilled, deleteBasketItemStateCallbacks.fulfilled)
            .addCase(deleteBasketItem.rejected, deleteBasketItemStateCallbacks.rejected)

            .addCase(addVoucher.pending, addVoucherStateCallbacks.pending)
            .addCase(addVoucher.fulfilled, addVoucherStateCallbacks.fulfilled)
            .addCase(addVoucher.rejected, addVoucherStateCallbacks.rejected)

            .addCase(removeVoucher.pending, removeVoucherStateCallbacks.pending)
            .addCase(removeVoucher.fulfilled, removeVoucherStateCallbacks.fulfilled)
            .addCase(removeVoucher.rejected, removeVoucherStateCallbacks.rejected)

            .addCase(getCheckout.fulfilled, (state, action) => {
                const { basket } = action.payload;
                if (basket) {
                    return getBasketStateCallbacks.fulfilled(state, { payload: basket });
                }
            });
    },
});

export const { setBasket, resetBasketState } = slice.actions;
export const { reducer } = slice;
