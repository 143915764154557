import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createSlice } from '@reduxjs/toolkit';
import { resolve as resolvePage } from '@sportson/core-web/state/page';

export interface IOverlay {
    view: string;
    transition: string;
    data: any;
}

export type IOverlayState = IOverlay;

const initialState: IOverlayState = { view: '', transition: '', data: {} };

const slice = createSlice({
    name: 'overlay',
    initialState,
    reducers: {
        show: (state, action) => ({ ...state, ...action.payload }),
        update: (state, action) => ({ ...state, ...action.payload }),
        close: () => initialState,
    },
    extraReducers(builder) {
        builder.addCase(resolvePage.fulfilled, (state, action) => {
            const camelizedData = objectKeysToCamelCase(action?.payload, {
                recursive: true,
                modifyInputObject: false,
            });
            return camelizedData.status === 'redirect' ? state : initialState;
        });
    },
});

export {};
export const { show, update, close } = slice.actions;
export const { reducer } = slice;
