import { BasketEvents, CheckoutEvents, PageEvents, ProductEvents } from '@sportson/core-web/libs/Events/constants';
import Cookies from 'js-cookie';
import BasketProductAdded from './BasketProductAdded';
import BasketProductRemoved from './BasketProductRemoved';
import CheckoutPayment from './CheckoutPayment';
import CheckoutShipping from './CheckoutShipping';
import CheckoutView from './CheckoutView';
import PageView from './PageView';
import ProductClick from './ProductClick';
import ProductImpressions from './ProductImpressions';
import ProductView from './ProductView';
import Transaction from './Transaction';

export default {
    getClientId() {
        try {
            const tracker = window.ga.getAll()[0];
            return tracker.get('clientId');
        } catch (e) {
            return '';
        }
    },

    getSessionId() {
        try {
            const sessionIdCookie = Cookies.get(`_ga_${process.env.REACT_APP_GA4_MEASUREMENT_ID.split('-')[1]}`);
            return sessionIdCookie.split('.')[2];
        } catch (e) {
            return '';
        }
    },

    getEventTimestamp() {
        try {
            const currentTime = Date.now();
            // Timestamp needs to be passed in microseconds
            const currentTimeInMicroseconds = currentTime * 1000;
            // We add up 5 milliseconds to be sure the Page view event has been triggered
            return currentTimeInMicroseconds + 5000;
        } catch (e) {
            return '';
        }
    },

    getFacebookFbp() {
        try {
            return Cookies.get('_fbp') || '';
        } catch (e) {
            return '';
        }
    },

    getFacebookFbc() {
        try {
            return Cookies.get('_fbc') || '';
        } catch (e) {
            return '';
        }
    },

    getFacebookClientUserAgent() {
        try {
            return navigator.userAgent;
        } catch (e) {
            return '';
        }
    },

    getConsentMarketing() {
        try {
            return window.Cookiebot.consent.marketing || false;
        } catch (error) {
            return false;
        }
    },

    getConsentStatistic() {
        try {
            return window.Cookiebot.consent.statistics || false;
        } catch (error) {
            return false;
        }
    },

    getUniqueId() {
        try {
            const uint32 = crypto.getRandomValues(new Uint32Array(1))[0];
            return uint32.toString(16);
        } catch (e) {
            return '';
        }
    },

    trackingEvents() {
        return [
            // Page
            { name: PageEvents.VIEW, callback: PageView, handle: null }, // OK
            // Basket
            { name: BasketEvents.PRODUCT_ADDED, callback: BasketProductAdded, handle: null }, // OK
            { name: BasketEvents.PRODUCT_REMOVED, callback: BasketProductRemoved, handle: null }, // OK
            // Upsell
            // Promotion
            // { name: PromotionEvents.CLICK, callback: PromotionClick, handle: null },
            // { name: PromotionEvents.IMPRESSIONS, callback: PromotionImpressions, handle: null },
            // Search
            // { name: SearchEvents.SUBMITTED, callback: Search, handle: null },
            // Product
            { name: ProductEvents.VIEW, callback: ProductView, handle: null }, // OK
            { name: ProductEvents.CLICK, callback: ProductClick, handle: null }, // OK
            { name: ProductEvents.IMPRESSIONS, callback: ProductImpressions, handle: null }, // OK
            // Checkout
            { name: CheckoutEvents.VIEW, callback: CheckoutView, handle: null }, // OK
            { name: CheckoutEvents.UPDATED, callback: CheckoutView, handle: null },
            { name: CheckoutEvents.SHIPPING, callback: CheckoutShipping, handle: null },
            { name: CheckoutEvents.PAYMENT, callback: CheckoutPayment, handle: null },
            { name: CheckoutEvents.TRANSACTION, callback: Transaction, handle: null }, // OK
        ];
    },
};
