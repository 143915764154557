import PropTypes from 'prop-types';
import React from 'react';
import Text from 'components/Text';

const SubHeading = ({ type, children }) => (
    <Text
        $as={type}
        typography={[
            'Primary/Headlines/18-120-mobile',
            null,
            'Primary/Headlines/18-120-tablet',
            null,
            'Primary/Headlines/24-120-desktop',
        ]}
        mb="24px"
    >
        {children}
    </Text>
);

SubHeading.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
};

export default SubHeading;
