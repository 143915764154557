import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@grebban/style-system-react';
import { useHeaderNavigationContext } from '@sportson/core-web/contexts/HeaderNavigationContext';
import { type WordpressACFGrebCommerceLink } from 'libs/wordpress/types/acf/fields';
import Text from 'components/Text';
import { Link } from 'react-router-dom';
import { above } from '@sportson/core-web/utils/mediaqueries';
import { type ICMSIcon } from './TopNav';

const Div = styled('div')();
const CategorySection = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px 24px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-neutrals-200);
    }
`;
const MenuWrapper = styled('div')`
    z-index: 99;
    display: grid;
    position: absolute;
    top: var(--header-height);
    left: 0;
    width: 100%;

    background-color: var(--color-base-white);
    grid-template-rows: 0fr;
    transition: grid-template-rows var(--transition-primary-fast);

    &.open {
        border-top: 1px solid var(--color-neutrals-200);
        grid-template-rows: 1fr;
        margin-top: 2px;
    }
`;

const Grid = styled('div')`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow: hidden;

    &.bottom-border {
        border-bottom: 1px solid var(--color-neutrals-200);
    }

    ${above['desktop.sm']} {
        width: 100%;
        margin: 0 auto;
        max-width: var(--site-corridor-max-width);
    }
`;

const StaticColumn = styled('div')`
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--color-neutrals-200);
    grid-column: 1;
    border-left: 1px solid var(--color-neutrals-200);
`;

const Column = styled('div')`
    border-right: 1px solid var(--color-neutrals-200);
`;

const TopLink = styled(Link)`
    display: flex;
    gap: 8px;
    padding: 8px;

    &.top-menu-item {
        padding: 0;
        pointer-events: none;
    }

    & :hover {
        color: var(--color-neutrals-400);
    }
`;
const SubLink = styled(Link)`
    padding: 8px;
    color: var(--color-neutrals-500);

    &.top-menu-item {
        padding: 0;
    }

    & :hover {
        cursor: pointer;
        color: var(--color-neutrals-400);
    }
`;

const TopLeftCol = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 24px;
`;
const BottomLeftCol = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    border-top: 1px solid var(--color-neutrals-200);
`;

const Icon = styled('img')`
    width: 16px;
    height: 16px;
`;
// splitta upp interfaces men jag orkar bara inte kl 23
interface IFormattedMenu {
    name: string;
    topUrl: string;
    subLinks:
        | {
              link: WordpressACFGrebCommerceLink<true, true>;
              links?: { link: WordpressACFGrebCommerceLink<true, true> }[];
              column?: string;
          }[]
        | undefined;
}

interface INavigationLinks {
    link: WordpressACFGrebCommerceLink<true, true>;
    links?: {
        link: WordpressACFGrebCommerceLink<true, true>;
        links?: { link: WordpressACFGrebCommerceLink<true, true> }[];
        column: string;
    }[];
}

interface Props {
    menuOpen: boolean;
    menuLinks: INavigationLinks[];
    guideCustomPages: {
        //
        guideLinks: { icon: ICMSIcon; link: WordpressACFGrebCommerceLink<true, true> }[];
        customPageLinks: {
            link: WordpressACFGrebCommerceLink<true, true>;
        }[];
    };
}

const DesktopMenu = ({ menuOpen, menuLinks, guideCustomPages }: Props) => {
    const { t } = useTranslation();
    const menu = useMemo(
        () =>
            menuLinks.reduce((acc: IFormattedMenu[], curr) => {
                const menuItem: IFormattedMenu = { name: curr.link.text, topUrl: curr.link.url, subLinks: curr.links };
                acc.push(menuItem);
                return acc;
            }, []),
        [menuLinks],
    );

    const menus: { name: string; url: string; subMenu: React.ReactNode[][] }[] = [];

    menu.forEach((menuItem) => {
        const gridColumnNodes: React.ReactNode[][] = [[], [], [], []];

        if (!menuItem.subLinks) return;

        menuItem.subLinks.forEach((link, index) => {
            const col = Number(link.column) - 1;

            const item = (
                <CategorySection key={`${link.link.linkId}-${link.link.url}`}>
                    <TopLink typography to={link.link.url} tabIndex={menuOpen ? '0' : '-1'}>
                        <Text typography="UI/16_100_0_450">{link.link.text}</Text>
                    </TopLink>
                    {(link.links || []).map((link, index) => (
                        <SubLink key={link.link.text} to={link.link.url} tabIndex={menuOpen ? '0' : '-1'}>
                            <Text typography="UI/14_100_0_450">{link.link.text}</Text>
                        </SubLink>
                    ))}
                </CategorySection>
            );

            gridColumnNodes[col].push(item);
        });
        menus.push({ name: menuItem.name, url: menuItem.topUrl, subMenu: gridColumnNodes });
    });

    const { headerNavigationState } = useHeaderNavigationContext();

    return (
        <MenuWrapper className={menuOpen ? 'open' : ''}>
            <Grid className={menuOpen ? 'bottom-border' : ''}>
                <StaticColumn>
                    <TopLeftCol>
                        {(guideCustomPages.customPageLinks || []).map((link) => (
                            <TopLink key={link.link.linkId} to={link.link.url} tabIndex={menuOpen ? '0' : '-1'}>
                                <Text typography={['UI/20_100_0_450', null, null, null, null, null, 'UI/24_100_0_450']}>
                                    {link.link.text}
                                </Text>
                            </TopLink>
                        ))}
                    </TopLeftCol>
                    <BottomLeftCol>
                        {(guideCustomPages.guideLinks || []).map((link) => (
                            <TopLink key={link.link.linkId} to={link.link.url} tabIndex={menuOpen ? '0' : '-1'}>
                                <Icon src={link.icon.url} alt={link.icon.alt} />
                                <Text typography="UI/14_100_0_450">{link.link.text}</Text>
                            </TopLink>
                        ))}
                    </BottomLeftCol>
                </StaticColumn>

                {menus.map((menu, index) => (
                    <Div
                        key={`${menu.name}-$${menu.url}`}
                        display={headerNavigationState.activeSubMenu === menu.name ? 'grid' : 'none'}
                        gridTemplateColumns="1fr 1fr 1fr 1fr"
                        gridColumn="2 / span 4;"
                    >
                        <Column>
                            <CategorySection>
                                <Div padding="8px" gap="10px" display="flex" flexDirection="column">
                                    <TopLink as="span" className="top-menu-item">
                                        <Text typography="UI/24_100_0_450">{menu.name}</Text>
                                    </TopLink>

                                    <SubLink className="top-menu-item" to={menu.url} tabIndex={menuOpen ? '0' : '-1'}>
                                        <Text typography="UI/14_100_0_450" aria-label={t('show_all') + menu.name}>
                                            {t('show_all')}
                                        </Text>
                                    </SubLink>
                                </Div>
                            </CategorySection>

                            {menu.subMenu[0].map((node) => node)}
                        </Column>
                        <Column>{menu.subMenu[1].map((node) => node)}</Column>
                        <Column>{menu.subMenu[2].map((node) => node)}</Column>
                        <Column>{menu.subMenu[3].map((node) => node)}</Column>
                    </Div>
                ))}
            </Grid>
        </MenuWrapper>
    );
};

export default DesktopMenu;
