import { type FC } from 'react';
import styled from '@grebban/style-system-react';

export interface ColumnProps {
    display?: string | string[];
    flexDirection?: string | string[];
    width?: string | (string | null)[];
    [x: string]: any;
}

const StyledWrapper = styled('div')``;

const Column: FC<ColumnProps> = ({ display = 'flex', flexDirection = 'column', width = '100%', ...rest }) => (
    <StyledWrapper display={display} flexDirection={flexDirection} width={width} {...rest} />
);

export default Column;
