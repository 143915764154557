// eslint-disable-next-line eslint-comments/disable-enable-pair -- Not sure why the rule is whining about this
/* eslint-disable no-undef -- ssrCache is fetched from another scope and does exist globally (I think) */
import { inServer } from '@sportson/core-web/constants';

const second = 1000;
const minute = second * 60;

const stringToHash = (s) => s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
export const cacheKey = (...value) => stringToHash(JSON.stringify(value));

export const cache = async (closure, key, ttl = minute) => {
    if (!inServer || typeof ssrCache === 'undefined') {
        return closure();
    }

    if (ssrCache.has(key)) {
        return ssrCache.get(key);
    }

    const response = await closure();
    // todo check response size to prevent massive requests being stored
    ssrCache.set(key, response, ttl);

    return response;
};

/*
// cacheWithCacheControl
let ttl = parseInt(response.header('cache-control').replace(/[^0-9]/g, ''), 10) * 1000;

const expires = response.header('expires');
if (expires) {
  const date = new Date(expires);
  const expires_in_seconds = (date.getTime() - new Date().getTime());
  ttl = expires_in_seconds > 0 ? expires_in_seconds : ttl;
}
console.log({
  date: response.header('date'),
  expires: response.header('expires'),
  'last-modified': response.header('last-modified'),
  ttl
});
 */
