import styled, { stylesToResponsiveCssPropsByKeys } from '@grebban/style-system-react';
import applicationBrandingTypography from 'config/branding/typography';
import React from 'react';

const StyledText = styled('span')``;

export interface TextProps extends Record<string, unknown> {
    $as?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'blockquote';
    typography?: string | Array<string | null>;
    children: React.ReactNode;
}

const Text = ({ $as = 'p', typography = '', ...rest }: TextProps) => (
    // @TODO The style package is actually incorrect typed, it should be "string | Array<string | null>" and not only strings.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Reason: the stylesToResponsiveCssPropsByKeys uses the style package, but it is not correctly ts yet.
    // @ts-ignore
    <StyledText as={$as} {...stylesToResponsiveCssPropsByKeys(typography, applicationBrandingTypography)} {...rest} />
);

export default Text;
