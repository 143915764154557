import { type CssResponsiveVariables, type Percent, type Pixels } from './types';

export interface SpacingCssResponsiveVariables {
    '--module-spacing'?: Pixels | Percent;
}

export const spacingCssResponsiveVariables: CssResponsiveVariables<SpacingCssResponsiveVariables> = {
    'mobile.sm': {
        '--module-spacing': '0px',
    },
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {},
    'tablet.lg': {},
    'desktop.sm': {},
    'desktop.md': {},
    'desktop.lg': {},
};
