const getQueryParams = (location) => {
    const params = {};

    if (location?.search) {
        const search = location.search.substr(1);

        const paramsArray = search.split('&');

        let lastAddedItemKey = null;
        paramsArray.forEach((p) => {
            const [parameterKey, parameterValue] = p.split('=');

            if (parameterValue) {
                lastAddedItemKey = parameterKey;
                params[parameterKey] = parameterValue;
            } else if (lastAddedItemKey) {
                params[lastAddedItemKey] += `&${parameterKey}`;
            }
        });
    }

    return params;
};

export default getQueryParams;
