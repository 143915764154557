import { initializeApp } from 'firebase/app';
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    signOut as firebaseSignOut,
    getAuth,
    getIdToken,
    getIdTokenResult,
    signInWithCustomToken,
    signInWithPopup,
    signInWithRedirect,
} from 'firebase/auth';
import Cookies from 'js-cookie';
import { getModel } from '@sportson/core-web/state';
import { inServer } from '@sportson/core-web/constants';
import RequestMagicLinkLogin from '@sportson/core-web/libs/GrebbCommerceAPI/User/RequestMagicLinkLogin';
import { post, get } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import ConsumeMagicLinkLogin from '@sportson/core-web/libs/GrebbCommerceAPI/User/ConsumeMagicLinkLogin';
import getCountrySpecificVariable from './getCountrySpecificVariable';

// eslint-disable-next-line import/no-mutable-exports -- Reason: Temporary solution - still works
let auth = {};

let isMobile = false;

const initFirebase = (locale = 'sv_SE') => {
    if (!inServer) {
        const firebaseConfig = getCountrySpecificVariable('REACT_APP_FIREBASE_CREDENTIALS', locale);

        const firebaseApp = initializeApp(firebaseConfig);
        auth = getAuth(firebaseApp);

        isMobile = typeof window !== 'undefined' && (window.isMobile || /Mobi/i.test(window.navigator.userAgent));
    }
};

const verifyCustomerId = async (email = '', token = '') => {
    let response;

    try {
        response = await getIdTokenResult(auth.currentUser);

        if (response.claims && response.claims.customer_id) {
            Cookies.set('isLoggedIn', true, { expires: 7 });
            // User has customer_id
        }
    } catch (e) {
        console.log('Something went wrong: ', e);
    }
};

const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
        prompt: 'select_account',
    });

    try {
        await signInWithPopup(auth, provider);
        await verifyCustomerId();
    } catch (error) {
        return error.message;
    }
};

const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();

    provider.setCustomParameters({
        prompt: 'select_account',
    });

    try {
        !isMobile ? await signInWithPopup(auth, provider) : await signInWithRedirect(auth, provider);

        await verifyCustomerId();
    } catch (error) {
        return error;
    }
};

const requestEmailToken = async (email) => {
    try {
        return await RequestMagicLinkLogin(email);
    } catch (error) {
        console.error(error);
    }
};

const signInWithCustom = async (email, token) => {
    try {
        const {
            status,
            response: { data },
        } = await ConsumeMagicLinkLogin(email, token);

        if (status === 404 || !data) {
            return { status, data };
        }

        return { status, data };
    } catch (error) {
        console.error(error);
    }
};

const user = async () => {
    try {
        await getIdTokenResult(auth.currentUser);
    } catch (error) {
        console.error(error);
    }
};

const getCustomer = async () => {
    try {
        const token = await getIdTokenResult(auth.currentUser);

        return await get(
            `/auth/me`,
            { application_id: getModel('application').applicationId },
            { Authorization: `Bearer ${token.token}` },
        );
    } catch (e) {
        console.error(e);
    }
};

const signOut = async () => {
    try {
        await firebaseSignOut(auth);
        Cookies.remove('isLoggedIn');
    } catch (error) {
        console.error(error);
    }
};

export {
    signInWithGoogle,
    signInWithFacebook,
    signInWithCustom,
    requestEmailToken,
    signOut,
    user,
    getCustomer,
    auth,
    initFirebase,
};
