export const STORM_PACKAGE_TYPES = [37, 22];
export const STRUCTURE_TYPE_ID = 8;
export const MANAGED_STRUCTURE_TYPE_ID = 37;
export const SIM_CODE = '';
export const SIM_TYPES = [];
export const LICENSE_CODE = '';
export const STORM_FILE_EXPOSED_CODE = 'ft_exposed_image';
export const STORM_FILE_ENVIRONMENT_IMAGE = 'ft_environment_image';
export const STORM_FILE_IMAGE_TYPES = [1, 8, 10];
export const STORM_FILE_VIDEO_TYPES = [4, 6];
export const STORM_MEDIA_TYPES = [...STORM_FILE_IMAGE_TYPES, ...STORM_FILE_VIDEO_TYPES];
export const STORM_IMAGE_PORTRAIT = 'pdp_portrait';
export const STORM_IMAGE_ENVIROMENT = 'pdp_environment';
// List of file type code-prefixes for excluding files depending on locale.
export const FREIGHT_PRODUCT_TYPE = 3;
export const HANDLING_FEE_PART_NO = '10000009';
export const SALE_PRODUCT_NO = process.env.REACT_APP_STORM_SALE_NO;
export const SALE_PRODUCT_CODE = 'custom_price';
export const STORM_ROLE_SALE = process.env.REACT_APP_STORM_ROLE_SALE;
export const STORM_ROLE_ADMIN = process.env.REACT_APP_STORM_ROLE_ADMIN;
export const STORM_MEDIA_URL = process.env.REACT_APP_STORM_MEDIA_URL;

export const INGRID_WIDGET_URL = process.env.REACT_APP_INGRID_WIDGET_URL;
export const KLARNA_CHECKOUT_URL = process.env.REACT_APP_KLARNA_CHECKOUT_URL;
export const DEPICT_IS_ACTIVE = process.env.REACT_APP_DEPICT_ACTIVE === 'true';
export const STORM_PAYMENT_METHOD_RESURS = parseInt(process.env.REACT_APP_STORM_PAYMENT_METHOD_RESURS || '', 10);
export const STORM_PAYMENT_METHOD_RETAIN24 = parseInt(process.env.REACT_APP_STORM_PAYMENT_METHOD_RETAIN24 || '', 10);

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// dynamic placeholder filenames?
export const PLACEHOLDER = `${process.env.REACT_APP_MEDIA_URL}/placeholder.png`;

// Basket/Order info fields
export const BASKET_ITEM_APPLIED_PROMOTION = 'applied_promotion';
export const APPLIED_MEMBER_PROMOTIONS = 'applied_member_promotions';

export const UPSELL_BASKET_STORM_CODES = ['Accessory-1'];

// Product relations code
export const MANUAL_ORDER_HANDLING = 'manual_order_handling';
export const STORM_SALES_REP = 'sales_rep';
export const PRODUCT_PAGE_RELATIONS = 'upsell_productpage';
export const PRODUCT_PREORDER = 'prebooking';
export const PRODUCT_BACKORDER = 'backorder';
export const PRODUCT_DROPSHIP = 'dropship';
export const PRICE_INC_VAT = 'pricing.pricelists.web.incVat';
export const PRICE_EX_VAT = 'pricing.pricelists.web.exVat';
export const INTERNATIONAL_APP_ORGANIZATION = '';
export const VIRTUAL_PRODUCT = 'virtual';
export const FREE_FREIGHT = 'freefreight';
export const STORE_PRODUCT = 'sale_type_store';
export const ONLINE_PRODUCT = 'sale_type_online';
export const NO_STOCK_NO_PRICE_PRODUCT = 'no_stock_no_price';
export const NOT_ACTIVE_ONLINE = 'not_active_online';
export const EXCLUDED_FLAGS_VARIANT_PARENT = [
    'sale_type_online_store',
    'sale_type_online',
    'sale_type_store',
    'not_active_online',
    'no_stock_no_price',
];
export const SHIPPING_COST = 'shipping_cost';
export const APP_ORGANIZATION = 'Sportson';
export const AFFILIATION = 'Sportson AB';

export const WEB_WAREHOUSE = '2050';
export const STORM_IMAGE_URL = `https://stormcdn1065.azureedge.net/`;
