import { get } from '@sportson/core-web/libs/GrebbCommerceAPI/util/authClient';
import { getModel } from '@sportson/core-web/state';

export default async (basketId) => {
    const response = await get(`/frontend/basket/id/${basketId}`, {
        application_id: getModel('application').site.applicationId,
    });

    return await response.body();
};
