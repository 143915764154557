import { createSlice } from '@reduxjs/toolkit';
import { allowScroll, preventScroll } from '@sportson/core-web/utils/scroll';

export type IScrollState = {
    locked: boolean;
    keys: string[];
};

const initialState: IScrollState = {
    locked: false,
    keys: [],
};

const slice = createSlice({
    name: 'scroll',
    initialState,
    reducers: {
        allow: (state, action) => {
            const key = action.payload || '';
            const keys = state.keys.filter((k) => k !== key);

            // If no keys is supplied, then we override and unlock everything
            if (!key) {
                allowScroll();
                return { keys: [], locked: false };
            }

            // If key is supplied and we have no locking keys remaining, then we unlock
            if (!keys.length) {
                allowScroll();
                return { keys: [], locked: false };
            }

            // If key is supplied and we have locking keys remaininging, then we remove that key and keep scroll locked
            return { keys, locked: true };
        },
        disallow: (state, action) => {
            const key = action.payload || '';
            const keys = !state.keys.includes(key) ? [...state.keys, key] : state.keys;

            // If no keys is supplied, then we override and lock everything (still keeping keys)
            if (!key) {
                preventScroll();
                return { keys: state.keys, locked: true };
            }

            // If key is supplied and we have locking keys, then we make sure the scroll is locked
            if (keys.length) {
                preventScroll();
                return { keys, locked: true };
            }

            // If key is supplied and we have no locking keys, then we return state as it is (we should never get to this point)
            return state;
        },
        toggle: (state, action) => {
            const key = action.payload || '';
            const lock = !state.locked;

            // If no keys is supplied, then we override and toggle (remove all keys on unlock, keeping them on lock)
            if (!key) {
                lock ? preventScroll() : allowScroll();
                return { keys: state.locked ? state.keys : [], locked: lock };
            }

            // If key is supplied and we have locking keys, then we make sure the scroll is locked
            if (lock) {
                const keys = !state.keys.includes(key) ? [...state.keys, key] : state.keys;
                preventScroll();
                return { keys, locked: true };
            }

            // If key is supplied and we want to unlock, then we inlock if we don't have any locking keys left
            if (!lock) {
                const keys = state.keys.filter((k) => k !== key);
                keys.length ? preventScroll() : allowScroll();
                return { keys, locked: !!keys.length };
            }

            // We should never get to this point
            return state;
        },
    },
});

export {};
export const { allow, disallow, toggle } = slice.actions;
export const { reducer } = slice;
