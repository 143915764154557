import loadable from '@loadable/component';
import Views from '@sportson/core-web/Views';
import Footer from 'Footer';
import Overlay from 'Overlay';
import Popups from 'Popups';
import NotificationWrapper from 'components/wrappers/NotificationWrapper';
import { HeaderContextProvider } from '@sportson/core-web/contexts/HeaderContext';
import { HeaderNavigationContextProvider } from '@sportson/core-web/contexts/HeaderNavigationContext';
import { initFirebase, auth } from '@sportson/core-web/utils/firebase';
import { useEffect } from 'react';
import { inServer } from '@sportson/core-web/constants';
import Cookies from 'js-cookie';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import getUser from '@sportson/core-web/state/customer/user/getUser';
import { Header } from './Header/header';

const PageTransitionOverlay = loadable(() => import('@sportson/core-web/components/PageTransitionOverlay'));

const Site = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        initFirebase();

        const fetchData = async () => {
            if (!inServer && Cookies.get('user_logged_in') === 'true') {
                await dispatch(getUser());
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Overlay />
            <Popups />
            <PageTransitionOverlay />
            <HeaderContextProvider>
                <HeaderNavigationContextProvider>
                    <Header />
                </HeaderNavigationContextProvider>
                <Views />
            </HeaderContextProvider>
            <Footer />
            <NotificationWrapper />
        </>
    );
};

export default Site;

export { Site as DefaultSite };
