import React from 'react';
import Svg from '@grebban/react-core-components/svg/Svg';
import Path from '@grebban/react-core-components/svg/Path';

export interface CompareProps {
    color?: string;
    height?: string;
    width?: string;
    viewBox?: string;
}

const Compare: React.FunctionComponent<CompareProps> = ({
    color = 'var(--color-base-black)',
    height = '16px',
    width = '16px',
    viewBox = '0 0 16 16',
    ...rest
}) => (
    <Svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.91421 5.50002L5.70711 7.29289L5 8L2 5.00002L5 2L5.70711 2.70711L3.91421 4.50002H13.0001V5.50002H3.91421Z"
            fill={color}
        />
        <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0858 11.5L10.2929 13.2929L11 14L14 11L11 8L10.2929 8.70711L12.0858 10.5H2.99992V11.5H12.0858Z"
            fill={color}
        />
    </Svg>
);

export default Compare;
