import React from 'react';
import Svg from '@grebban/react-core-components/svg/Svg';
import PropTypes from 'prop-types';
import Path from '@grebban/react-core-components/svg/Path';

const Loading = ({ color, strokeWidth, ...rest }) => (
    <Svg xmlns="http://www.w3.org/2000/svg" {...rest}>
        <Path
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            fill="none"
            fillRule="evenodd"
            d="M8 1C4.1340068 1 1 4.1340068 1 8c0 3.86599325 3.1340068 7 7 7 3.86599325 0 7-3.13400675 7-7"
        />
    </Svg>
);

Loading.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    strokeWidth: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

Loading.defaultProps = {
    color: 'black',
    height: '24px',
    width: 'initial',
    viewBox: '0 0 16 16',
    strokeWidth: '1',
};

export default Loading;
