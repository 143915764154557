import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import GetPaymentForm from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/GetPaymentForm';
import getBasketId from '@sportson/core-web/state/ecommerce/norce/utils/getBasketId';
import getErrorMesage from '@sportson/core-web/utils/getErrorMessage';
import { initialState } from './index';

export interface GetPaymentFormData {
    basketId?: string;
}

export default createAsyncThunk(
    `checkout/payment`,
    async (data: GetPaymentFormData = {}, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
        let { basketId } = data;

        if (!basketId) {
            basketId = getBasketId();
        }

        if (!basketId) {
            return rejectWithValue({ error: 'BasketId is not set.' });
        }

        const mutexLock = await mutex('basket');

        try {
            const response = await GetPaymentForm(basketId);
            const paymentData = response.data;

            mutexLock();

            return fulfillWithValue(paymentData);
        } catch (error) {
            mutexLock();
            return rejectWithValue({ error: getErrorMesage(error) });
        }
    },
);

const pending = (state, action) => {
    state.status = 'pending';
};

const fulfilled = (state, action) => {
    const payment = objectKeysToCamelCase(action.payload, {
        recursive: true,
        modifyInputObject: false,
    });
    // const checkoutId = paymentData?.redirect_parameters?.find(
    //     parameter => parameter.name === 'checkoutid'
    // )?.value;
    // Cookies.set('checkoutId', checkoutId);
    state.payment = payment;
};

const rejected = (state, action) => {
    console.error(action.payload);
    return {
        ...initialState,
        status: 'idle',
    };
};

export const getPaymentFormStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
