import { type CssResponsiveVariables, type Pixels } from './types';

export interface GridCssResponsiveVariables {
    '--grid-item-default-column-size'?: number;
    '--grid-item-default-row-size'?: number;
    '--grid-columns'?: number;
    '--grid-padding'?: Pixels;
    '--grid-gap'?: Pixels;
}

export const gridCssResponsiveVariables: CssResponsiveVariables<GridCssResponsiveVariables> = {
    'mobile.sm': {
        '--grid-item-default-column-size': 2,
        '--grid-item-default-row-size': 1,
        '--grid-columns': 12,
        '--grid-padding': `12px`,
        '--grid-gap': `12px`,
    },
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {
        '--grid-item-default-column-size': 4,
        '--grid-item--default-row-size': 1,
        '--grid-columns': 12,
        '--grid-padding': `16px`,
        '--grid-gap': `16px`,
    },
    'tablet.lg': {},
    'desktop.sm': {
        '--grid-item-default-column-size': 3,
        '--grid-item--default-row-size': 1,
        '--grid-columns': 12,
        '--grid-padding': `48px`,
        '--grid-gap': `16px`,
    },
    'desktop.md': {},
    'desktop.lg': {},
};
