import Path from '@grebban/react-core-components/svg/Path';
import Svg from '@grebban/react-core-components/svg/Svg';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';

const sizes = {
    xsmall: {
        viewBox: '0 0 12 12',
        width: '12px',
        height: '12px',
        path: 'M2 4L6 8L10 4',
    },
    small: {
        viewBox: '0 0 17 16',
        width: '17px',
        height: '16px',
        path: 'M2.33301 5L8.33301 11L14.333 5',
    },
    large: {
        viewBox: '0 0 25 24',
        width: '25px',
        height: '24px',
        path: 'M3.66699 7.5L12.667 16.5L21.667 7.5',
    },
};
export type ChevronSizes = keyof typeof sizes;

// @TODO Remove Record<string, unknown> when styled is fixed and we can correctly pass css via rest.
export interface ChevronProps extends Record<string, unknown> {
    color: CssVariable<CssColorKey>;
    height?: string | string[];
    size?: ChevronSizes;
    width?: string | string[];
    viewBox?: string | string[];
}

export const ChevronDown = ({
    color = 'var(--color-base-black)',
    size = 'small',
    height,
    width,
    viewBox,
    ...rest
}: ChevronProps) => {
    const selectedSize = sizes[size];
    return (
        <Svg
            width={width || selectedSize.width}
            height={height || selectedSize.height}
            viewBox={viewBox || selectedSize.viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <Path d={selectedSize.path} stroke={color} />
        </Svg>
    );
};
