import CoreLink from '@sportson/core-web/components/Link';
import styled from 'libs/styled';
import Text from 'components/Text';
// import { CssColorKey } from 'config/branding/colors';
// import { CssVariable } from 'config/branding/types';
// import applicationBrandingTypography from 'config/branding/typography';
import PropTypes from 'prop-types';
import React from 'react';

const StyledLink = styled(CoreLink)`
    display: inline-block;
    text-decoration: none;
    white-space: pre;
`;

const LinkText = styled(Text)`
    display: inline-block;
    position: relative;

    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 0.8px;
        background-color: currentColor;

        ${({ noUnderline, duration, easing }) =>
            noUnderline &&
            `
            transform: scale3d(0, 1, 1);
            transform-origin: 100% 50%;
            transition: all ${duration / 2}ms ${easing};
            `}
    }

    &:hover {
        &::after {
            ${({ noUnderline, duration, easing, to }) =>
                !to
                    ? ''
                    : noUnderline
                      ? `animation: noUnderlinehoverTextLink ${duration / 2}ms ${easing}; transform: scale3d(1, 1, 1);`
                      : `animation: hoverTextLink ${duration}ms ${easing};`}
        }
    }

    @keyframes hoverTextLink {
        0% {
            transform: scale3d(1, 1, 1);
            transform-origin: 100% 50%;
        }
        50% {
            transform: scale3d(0, 1, 1);
            transform-origin: 100% 50%;
        }
        50.1% {
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }
    }

    @keyframes noUnderlinehoverTextLink {
        0% {
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }
    }
`;

// @TODO Remove Record<string, unknown> when styled is fixed and we can correctly pass css via rest.
export interface LinkProps extends Record<string, unknown> {
    children: React.ReactNode | typeof React.Children;
    // color?: CssVariable<CssColorKey> | CssVariable<CssColorKey>[];
    color?: string | string[];
    duration?: number;
    easing?: string | string[];
    noUnderline?: boolean;
    to?: string;
    typography?: string | (string | null)[];
}
const Link: React.FunctionComponent<LinkProps> = ({
    children,
    color = 'var(--color-base-black)',
    duration = 800,
    easing = 'cubic-bezier(.4,0,.6,1)',
    noUnderline = false,
    typography = 'UI/16_100_0_450',
    to = '',
    ...rest
}) => (
    <StyledLink volatile to={to} {...rest}>
        <LinkText
            color={color}
            duration={duration}
            easing={easing}
            noUnderline={noUnderline}
            typography={typography}
            // @TODO The style package is actually incorrect typed, it should be "string | Array<string | null>" and not only strings.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Reason: the stylesToResponsiveCssPropsByKeys uses the style package, but it is not correctly ts yet.
            // @ts-ignore
            // {...stylesToResponsiveCssPropsByKeys(typography, applicationBrandingTypography)}
        >
            {children}
        </LinkText>
    </StyledLink>
);

export default Link;
