export default {
    'categories.code': {
        label: 'category.filter.category',
        type: 'tree',
        param: 'category',
        open: false,
    },
    'manufacturer.name': {
        label: 'category.filter.brand',
        type: 'checkbox',
        param: 'manufacturer',
        open: false,
    },
    'pricing.pricelists.web.incVat': {
        label: 'category.filter.price',
        type: 'range',
        param: 'priceInc',
        open: false,
    },
    onHandFilter: {
        label: 'category.filter.stock_status',
        type: 'checkbox',
        param: 'onHand',
        open: false,
    },
} as const;
