// Search.
export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_CLEAR_SUCCESS = 'SEARCH_CLEAR_SUCCESS';
export const SEARCH_CLEAR_ERROR = 'SEARCH_CLEAR_ERROR';
export const SEARCH_DEFAULT_QUERY = 'SEARCH_DEFAULT_QUERY';
export const SEARCH_DEFAULT_QUERY_SUCCESS = 'SEARCH_DEFAULT_QUERY_SUCCESS';
export const SEARCH_DEFAULT_QUERY_ERROR = 'SEARCH_DEFAULT_QUERY_ERROR';
