import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import CreateBasket from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/CreateBasket';
import buildCartProductTree from '@sportson/core-web/utils/buildCartProductTree';
import tracking from '@sportson/core-web/components/Tracking';
import Cookies, { COOKIES_CONSTANTS } from '@sportson/core-web/config/cookies';
import Events, { EVENTS } from '@sportson/core-web/config/events';
import UpdateBasket from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/UpdateBasket';

export default createAsyncThunk(
    `basket/create`,
    async (data, { getState, dispatch, rejectWithValue, fulfillWithValue }) => {
        const mutexLock = await mutex('basket');
        const eventId = tracking.getUniqueId();

        try {
            const createBasketResponse = await CreateBasket('127.0.0.1', eventId);

            if (createBasketResponse?.data) {
                // basket created, all good
                Cookies.remove('checkoutId');
                Cookies.remove('paymentReference');
                mutexLock();

                try {
                    // Populate new basket with storeId if it exists
                    const store = getState().ecommerce.stores?.selected;
                    if (typeof store?.storeId === 'number') {
                        const basketId = createBasketResponse.data.id;

                        // Update basket with storeId
                        const basketWithUpdatedStore = await UpdateBasket(basketId, {
                            selected_store_id: store.storeId,
                        });
                    }
                } catch (error) {
                    console.error(error);
                }

                const cookieLifeSpan = getState().application.header.data.basket.cookie.lifespan;
                return fulfillWithValue({
                    cookieLifeSpan,
                    basketData: {
                        ...createBasketResponse.data,
                        items: buildCartProductTree(createBasketResponse.data.items),
                    },
                });
            }

            throw 'Could not create basket';
        } catch (error) {
            mutexLock();
            return rejectWithValue({ error });
        }
    },
);

const pending = (state, action) => {
    state.status = 'pending';
};

const fulfilled = (state, action) => {
    const basketData = objectKeysToCamelCase(action.payload.basketData, {
        recursive: true,
        modifyInputObject: false,
    });

    const cookieLifespan = Number(action.payload.cookieLifeSpan);
    Cookies.set(COOKIES_CONSTANTS.ECOMMERCE.BASKET_COOKIE_NAME, basketData.id, {
        expires: cookieLifespan || COOKIES_CONSTANTS.DEFAULT_LIFESPAN,
    });

    Events.trigger(EVENTS.ECOMMERCE.BASKET.CREATED, {
        basket: basketData,
    });

    return {
        ...state,
        ...basketData,
        status: 'idle',
    };
};

const rejected = (state, action) => {
    console.error(action.payload);
    return {
        ...state,
        status: 'idle',
    };
};

export const createBasketStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
