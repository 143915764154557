// import ImageBackground from '@grebban/react-core-components/ImageBackground';
import ImageBackground from '@grebban/react-core-components/ImageBackground';
import useInViewObserver from '@grebban/react-hooks/useInViewObserver';
import asArray from '@grebban/utils/array/asArray';
import generateImgixSrc, { type GenerateImgixSrcOptionsOptions } from '@sportson/core-web/libs/Imgix/generateImgixSrc';
import React, { useMemo } from 'react';

export type BackgroundImagePropsBackgroundRepeat =
    | 'repeat'
    | 'no-repeat'
    | 'repeat-x'
    | 'space'
    | 'round'
    | 'unset'
    | 'revert'
    | 'initial';

export type BackgroundImagePropsBackgroundPosition =
    | '0% 0%'
    | '0% 50%'
    | '0% 100%'
    | '50% 0%'
    | '50% 50%'
    | '50% 100%'
    | '100% 0%'
    | '100% 50%'
    | '100% 100%';

export type BackgroundImagePropsBackgroundSize = 'cover' | 'contain' | '100%';

export interface BackgroundImageProps extends Omit<GenerateImgixSrcOptionsOptions, 'width' | 'height'> {
    backgroundRepeat?: BackgroundImagePropsBackgroundRepeat | BackgroundImagePropsBackgroundRepeat[];
    height?: string | string[];
    imageBackground?: string;
    lazy?: boolean;
    backgroundPosition?: BackgroundImagePropsBackgroundPosition | BackgroundImagePropsBackgroundPosition[];
    backgroundSize?: BackgroundImagePropsBackgroundSize | BackgroundImagePropsBackgroundSize[];
    src: string | string[];
    width?: string | string[];
    widths?: number | number[];
    heights?: number | number[];
}

const BackgroundImage = ({
    src,
    auto,
    backgroundColor,
    backgroundRepeat,
    fit,
    crop,
    format,
    height = '100%',
    lazy = true,
    mask,
    backgroundPosition = '50% 50%',
    quality,
    backgroundSize = 'cover',
    width = '100%',
    widths = [0, 768, 1280, 1366, 1536, 1920], // we have zero at the beginning since our breakpoints start att zero
    heights = [],
    ...rest
}: BackgroundImageProps) => {
    // @TODO Function works when it is in project but no in package, check why.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Reason: the hook is not correctly ts yet.
    // @ts-ignore
    const [reference, isIntersecting] = useInViewObserver({ onEnter: lazy, rootMargin: '100%' });

    const generatedSources = useMemo(() => {
        const imgixSources: string[] = [];
        const sources = asArray(src);

        widths = asArray(widths);
        heights = asArray(heights);

        if (!sources.length) {
            return [];
        }

        for (let i = 0; i < Math.max(widths.length, heights.length); i++) {
            const options: GenerateImgixSrcOptionsOptions = {
                width: widths[i] || widths[widths.length - 1],
                height: heights[i] || heights[heights.length - 1],
            };

            if (format) {
                options.format = format;
            }
            if (backgroundColor) {
                options.backgroundColor = backgroundColor;
            }
            if (quality) {
                options.quality = quality;
            }
            if (auto) {
                options.auto = auto;
            }
            if (fit) {
                options.fit = fit;
            }
            if (crop) {
                options.crop = crop;
            }
            if (mask) {
                options.mask = mask;
            }

            imgixSources.push(generateImgixSrc(sources[i] || sources[sources.length - 1], options));
        }

        return imgixSources;
    }, [src, widths, heights]);

    return (
        <ImageBackground
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Reason: the ImageBackground uses the style package, but it is not correctly ts yet.
            // @ts-ignore
            backgroundRepeat={backgroundRepeat}
            height={height}
            backgroundPosition={backgroundPosition}
            backgroundSize={backgroundSize}
            width={width}
            ref={reference}
            src={isIntersecting || !lazy ? generatedSources : []}
            {...rest}
        />
    );
};

export default BackgroundImage;
