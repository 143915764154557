import {
    type IBreakpoint,
    type ValidBreakpointLabels,
    breakpoints,
    getBreakpoint,
    breakpointLabels as labels,
} from '@sportson/core-web/config/breakpoints';

/**
 * Create a array of objects with labels and breakpoints
 */

export const breakpointMap = breakpoints.map((entry) => ({
    label: entry.label,
    size: entry.from,
}));

/**
 * Get the value of a breakpoint with prefix
 * @param {string} label - Label of the breakpoint. Ex: 'md' or 'lg'
 * @returns {string} - '62em'
 */
export const getMinBreakpointValue = (label: ValidBreakpointLabels): string | null =>
    getBreakpoint(label)?.from || null;

/**
 * Get the value of a breakpoint with prefix and subtract 0.01 from the value.
 * @param {string} label - Label of the breakpoint. Ex: 'md' or 'lg'
 * @returns {string} - '61.99em'
 */
export const getMaxBreakpointValue = (label: ValidBreakpointLabels): string | null => getBreakpoint(label)?.to || null;

/**
 * Get the label of the next breakpoint, smallest to largest
 * @param {string} label - Label of the breakpoint. Ex: 'md' or 'lg'
 * @returns {string} - 'md' will return 'lg'
 */
export const getNextBreakpoint = (label: ValidBreakpointLabels): IBreakpoint | null => {
    for (let i = 0; i < breakpoints.length; i++) {
        if (breakpoints[i].label === label) {
            return breakpoints[i + 1];
        }
    }
    console.warn(`There are no breakpoints after ${label}.`);
    return null;
};

export const getPrevBreakpoint = (label: ValidBreakpointLabels): IBreakpoint | null => {
    for (let i = 0; i < breakpoints.length; i++) {
        if (breakpoints[i].label === label) {
            return breakpoints[i - 1];
        }
    }
    console.warn(`There are no breakpoints before ${label}.`);
    return null;
};

/**
 * Create a object with label as key and the finished mediaQuery as value
 * Ex: {xs: '@media (min-width: 25em)', ... }
 */
export const above: any = breakpointMap.reduce(
    (obj: object, bp) => ({
        ...obj,
        [bp.label]: `@media (min-width: ${bp.size})`,
    }),
    {},
);

/**
 * Create an object with label as key and the finished mediaQuery as value
 * Ex: {xs: '@media (min-width: 24.99em)', ... }
 */
export const below: any = breakpointMap.reduce(
    (obj: object, bp) => ({
        ...obj,
        [bp.label]: `@media (max-width: ${bp.size})`,
    }),
    {},
);

export const media: any = ['hover', 'isIE'].reduce(
    (obj) => ({
        ...obj,
        hover: '@media (hover: hover), (-ms-high-contrast: none)',
        isIE: '@media all and (-ms-high-contrast: none)',
    }),
    {},
);
