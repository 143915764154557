import Gtm from '@grebban/gtm';

export default ({ title, template, type, currencyCode, applicationId }) => {
    try {
        Gtm.addDataLayerEvent('page_view', {
            site_id: applicationId,
            page_title: title || '404',
            page_type: type || '',
            page_template: template || '',
            currency: currencyCode || '',
        });
    } catch (e) {
        //
    }
};
