import { buildQueryString, get, request } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { API_URL, isSupportingLazy } from '@sportson/core-web/constants';

export default async (url, parameters = {}, origin) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);

    if (process.env.REACT_APP_URL) {
        url = process.env.REACT_APP_URL;
    }

    const requestUrl = `/frontend/content/resolve-app?url=${encodeURIComponent(url)}${
        builtParameters && `&${builtParameters}`
    }`;

    let response;
    if (!API_URL && origin) {
        response = await request({
            method: 'GET',
            path: requestUrl,
            baseUrl: origin,
        });
        if (response) {
            return await response.body();
        }
    }

    response = await get(requestUrl);

    return await response.body();
};
