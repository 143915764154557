import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';

import { type WordpressACFWysiwyg } from '../types/acf/fields';
import { type WordpressHeading, type WordpressTextSectionHeading } from '../types/contentSystem/elements';
import { type WordpressHeadingGroup, type WordpressTextSectionHeadingGroup } from '../types/contentSystem/groups';
import transformTextColor from './transformTextColor';

export interface TransformHeadingOutput {
    text: WordpressACFWysiwyg;
    type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color?: CssVariable<CssColorKey>;
}
export interface TransformTextSectionHeadingOutput {
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export const transformHeading = (input: WordpressHeading): TransformHeadingOutput => {
    const output: TransformHeadingOutput = {
        text: input.text,
    };

    if (input.type && input.type !== 'default') {
        output.type = input.type;
    }

    if (input.textColor && input.textColor !== 'default') {
        output.color = transformTextColor(input.textColor);
    }

    // Can be added when things are added to the heading element in WP.
    // if (input.textSize && input.textSize !== 'default') {
    //     output.size = input.textSize;
    // }
    //
    // if (input.textWeight && input.textWeight !== 'default') {
    //     output.weight = input.textWeight;
    // }
    //
    // if (input.font && input.font !== 'default') {
    //     output.font = input.font;
    // }

    return output;
};

export const transformTextSectionHeading = (input: WordpressTextSectionHeading): TransformTextSectionHeadingOutput => {
    const output: TransformHeadingOutput = {
        text: input.text,
    };

    if (input.textColor && input.textColor !== 'default') {
        output.color = transformTextColor(input.textColor);
    }

    return output;
};

export const transformHeadingGroup = (group: WordpressHeadingGroup, device: 'mobile' | 'desktop') => {
    if (device === 'mobile' && group.customizeMobile && group.mobileHeading) {
        return transformHeading(group.mobileHeading);
    }
    return transformHeading(group.desktopHeading);
};

export const transformTextSectionHeadingGroup = (
    group: WordpressTextSectionHeadingGroup,
    device: 'mobile' | 'desktop',
) => {
    if (device === 'mobile' && group.customizeMobile && group.mobileTextSectionHeading) {
        return transformTextSectionHeading(group.mobileTextSectionHeading);
    }
    return transformTextSectionHeading(group.desktopTextSectionHeading);
};
