import { get } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';

export default async (partNumbers) => {
    const response = await get('/frontend/products/no', {
        part_no_seed: partNumbers,
        application_id: getModel('application').site.applicationId,
    });

    return await response.body();
};
