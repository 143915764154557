import Gtm from '@grebban/gtm';
import chunkArrayInGroups from '@sportson/core-web/utils/chunkArrayInGroups';
import DataLayer from './DataLayer';

export default ({ products, list, offset }) => {
    try {
        const impressions = (products || [])
            .filter((i) => i)
            .map((item, index) => {
                if (list) {
                    item.list = list;
                }
                const itemPosition = index + (offset || 0);
                return DataLayer.getImpression(item, itemPosition);
            });

        // chunk products array into groups of 24 to not exceed maximum payload size
        const newProducts = chunkArrayInGroups(impressions, 24);

        newProducts.forEach((impressions) => {
            Gtm.addDataLayerEvent('impressions', {
                ecommerce: {
                    impressions,
                },
            });
        });
    } catch (e) {
        //
    }
};
