import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Events, { EVENTS } from '@sportson/core-web/config/events';
import GetUser from '@sportson/core-web/libs/GrebbCommerceAPI/User/GetUser';
import getErrorMesage from '@sportson/core-web/utils/getErrorMessage';
import { getModel } from '@sportson/core-web/state';

import { initialState } from './index';

export default createAsyncThunk(`user/get`, async (_, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
    const mutexLock = await mutex('user');
    const { applicationId } = getModel('application').site;
    try {
        const res = await GetUser(applicationId);
        const { data } = await res.body();

        if (data) {
            mutexLock();

            return fulfillWithValue({ user: data });
        }
    } catch (error) {
        mutexLock();
        return rejectWithValue({ error: getErrorMesage(error), isFetching: false });
    }
});

const pending = (state) => {};

const fulfilled = (state, action) => {
    console.error(action.payload);
    return { ...state, user: action.payload };
};

const rejected = (state, action) => {
    console.error(action.payload);
    return {
        ...initialState,
    };
};

export const getUserStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
