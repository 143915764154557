import styled from '@grebban/style-system-react';
import { above } from '@sportson/core-web/utils/mediaqueries';
import SearchBar from 'components/SearchBar';
import Text from 'components/Text';
import { type WordpressACFGrebCommerceLink } from 'libs/wordpress/types/acf/fields';
import { useSelector } from 'react-redux';
import LinkButton from 'components/LinkButton';
import { type HeaderNavigationContextState } from '@sportson/core-web/contexts/HeaderNavigationContext';
import { type ICMSIcon } from '../navigation/TopNav';

const Wrapper = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0px 12px;

    ${above['desktop.sm']} {
        display: none;
    }
`;

const TopLinksWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
`;

const StyledLinkButton = styled(LinkButton)`
    border-radius: 100px;

    &:hover {
        border-color: var(--button-secondary-dark-hover-border);
    }

    &:focus {
        outline: auto !important;
    }

    &:active {
        background-color: var(--button-secondary-dark-background);
    }
`;

const Icon = styled('img')`
    width: 16px;
    height: 16px;
    margin-right: 4px;
`;

type Props = {
    isMenuOpen: boolean;
    headerNavigationState: {
        activeSubMenu: string;
        mobileMenuActive: boolean;
        desktopMenuActive: boolean;
        parentMenuName: string | null;
    };
    setHeaderNavigationState: (state: HeaderNavigationContextState) => void;
};

const MobileBottomRow = ({ isMenuOpen = false, headerNavigationState, setHeaderNavigationState }: Props) => {
    const topLinks: { links?: { icon: ICMSIcon; link: WordpressACFGrebCommerceLink<true, true> }[] } = useSelector(
        ({ application }) => application.header.data.navigation?.topNavigation,
    );

    if (!topLinks?.links && isMenuOpen) {
        return null;
    }

    const handleMobileMenuClick = () => {
        setHeaderNavigationState({
            ...headerNavigationState,
            mobileMenuActive: !isMenuOpen,
        });
    };

    return (
        <Wrapper>
            {isMenuOpen ? (
                <TopLinksWrapper>
                    {topLinks?.links &&
                        topLinks.links.map((link) => (
                            <StyledLinkButton to={link.link.url} onClick={handleMobileMenuClick} theme="secondaryDark">
                                <Icon src={link.icon.url} alt={link.icon.alt} />
                                <Text
                                    color="var(--color-base-white)"
                                    typography={['UI/12_100_0_450', 'UI/16_100_0_450', null, null, null]}
                                >
                                    {link.link.text}
                                </Text>
                            </StyledLinkButton>
                        ))}
                </TopLinksWrapper>
            ) : (
                <SearchBar />
            )}
        </Wrapper>
    );
};
export default MobileBottomRow;
