import { put } from '@sportson/core-web/libs/GrebbCommerceAPI/util/authClient';
import { getModel } from '@sportson/core-web/state';

export default async (email: string) => {
    const { site } = getModel('application');

    const data = {
        application_id: site.applicationId,
        email,
    };

    const response = await put(`/frontend/auth/magic`, data);
    return {
        response: await response.body(),
        status: await response.status(),
    };
};
