import Link from '@grebban/react-core-components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@grebban/style-system-react';

const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: underline;
`;

const Url = ({ attrs, children }) => (
    <StyledLink to={attrs.dataUrl} tabIndex={attrs.tabIndex}>
        {children}
    </StyledLink>
);

Url.propTypes = {
    attrs: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

export default Url;
