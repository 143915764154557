import { get, request } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';
import { API_URL, inServer } from '@sportson/core-web/constants';

export default async (partNo, parameters = {}, url, applicationId) => {
    if (!parameters || typeof parameters !== 'object') {
        parameters = {};
    }

    let response;
    if (!API_URL && inServer && url) {
        response = await request({
            method: 'GET',
            path: `/frontend/rating/no/${partNo}?application_id=${applicationId}`,
            data: parameters,
            baseUrl: url,
        });
        if (response) {
            return await response.body();
        }
    }

    response = await get(`/frontend/rating/no/${partNo}`, {
        application_id: applicationId || getModel('application').site.applicationId,
        ...parameters,
    });

    return await response.body();
};
