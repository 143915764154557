import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';

import { type WordpressBackgroundImage, type WordpressBackgroundVideo } from '../types/contentSystem/elements';
import {
    type WordpressBackgroundImageGroup,
    type WordpressBackgroundOptions,
    type WordpressBackgroundSelection,
    WordpressBackgroundSelectionColor,
    WordpressBackgroundSelectionImage,
    WordpressBackgroundSelectionVideo,
    type WordpressBackgroundVideoGroup,
} from '../types/contentSystem/groups';
import { type WordpressBackgroundColor } from '../types/contentSystem/settings';

const colorMapper: Record<WordpressBackgroundColor, CssVariable<CssColorKey> | undefined> = {
    default: undefined,
    white: 'var(--color-base-white)',
    black: 'var(--color-base-black)',
    yellow: 'var(--color-brand-yellow)',
    gray: 'var(--color-neutrals-200)',
};
export type TransformBackgroundColorOutput = BackgroundColorProps;
export const transformBackgroundColor = (bgColor: WordpressBackgroundColor): TransformBackgroundColorOutput => ({
    backgroundColor: colorMapper[bgColor],
});

// @TODO Add more cms data to TransformBackgroundImageOutput
export interface TransformBackgroundImageOutput extends BackgroundImageProps {
    alt: string | string[];
}
export const transformBackgroundImage = (data: WordpressBackgroundImage): TransformBackgroundImageOutput => {
    const { backgroundImageSettings, image } = data;
    const output: TransformBackgroundImageOutput = {
        src: image.url,
        alt: image.alt,
    };

    if (backgroundImageSettings.backgroundRepeat !== 'default') {
        output.backgroundRepeat = backgroundImageSettings.backgroundRepeat === 'on' ? 'repeat' : 'no-repeat';
    }

    if (backgroundImageSettings.backgroundPosition !== 'default') {
        output.backgroundPosition = backgroundImageSettings.backgroundPosition;
    }

    if (backgroundImageSettings.backgroundSize !== 'default') {
        output.backgroundSize = backgroundImageSettings.backgroundSize;
    }

    return output;
};
export const transformBackgroundImageGroup = (
    backgroundData: WordpressBackgroundImageGroup,
    device: 'mobile' | 'desktop',
) => {
    const background = backgroundData[`${device}BackgroundImage`];
    return transformBackgroundImage(background);
};

// @TODO Add more cms data to TransformBackgroundVideoOutput
export type TransformBackgroundVideoOutput = BackgroundVideoProps;
export const transformBackgroundVideo = (data: WordpressBackgroundVideo): TransformBackgroundVideoOutput => ({
    src: data.video.url,
});
export const transformBackgroundVideoGroup = (
    backgroundData: WordpressBackgroundVideoGroup,
    device: 'mobile' | 'desktop',
) => {
    const background = backgroundData[`${device}BackgroundVideo`];
    return transformBackgroundVideo(background);
};

// export type TransformBackgroundOption = <WordpressBackgroundType extends WordpressBackgroundOptions>(
//     backgroundOption: WordpressBackgroundType,
// ) => WordpressBackgroundType extends WordpressBackgroundSelectionVideo
//     ? TransformBackgroundVideoOutput
//     : WordpressBackgroundType extends WordpressBackgroundSelectionImage
//     ? TransformBackgroundImageOutput
//     : WordpressBackgroundType extends WordpressBackgroundSelectionColor
//     ? TransformBackgroundColorOutput
//     : null;

// export const transformBackgroundOption: TransformBackgroundOption = (backgroundOption) => {
export const transformBackgroundOption = (backgroundOption: WordpressBackgroundOptions) => {
    // Transform the data depending on the type
    switch (backgroundOption.layout) {
        case 'color':
            return {
                type: 'color',
                data: transformBackgroundColor(backgroundOption.backgroundColor),
            };

        case 'image':
            return {
                type: 'image',
                data: transformBackgroundImage(backgroundOption.backgroundImage),
            };

        case 'video':
            return {
                type: 'video',
                data: transformBackgroundVideo(backgroundOption.backgroundVideo),
            };

        default:
            throw new Error(`Layout not found in transformBackgroundOption`);
    }
};

export const transformBackgroundGroup = (group: WordpressBackgroundSelection, device: 'mobile' | 'desktop') => {
    if (device === 'mobile' && group.customizeMobile && group.mobile) {
        return transformBackgroundOption(group.mobile[0]);
    }
    return transformBackgroundOption(group.desktop[0]);
};
