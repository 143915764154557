import { type CssResponsiveVariables } from './types';

type Ratio = number;

export interface RatiosCssResponsiveVariables {
    '--ratio-landscape'?: Ratio;
    '--ratio-square'?: Ratio;
    '--ratio-portrait'?: Ratio;
    '--ratio-product-image'?: Ratio;
}

export const ratiosCssResponsiveVariables: CssResponsiveVariables<RatiosCssResponsiveVariables> = {
    'mobile.sm': {
        '--ratio-landscape': 4 / 3,
        '--ratio-square': 1,
        '--ratio-portrait': 3 / 4,
        '--ratio-product-media': 2 / 3,
    },
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {},
    'tablet.lg': {},
    'desktop.sm': {},
    'desktop.md': {},
    'desktop.lg': {},
};
