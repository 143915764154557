import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { reducers as application } from '@sportson/core-web/state/application';
import { reducers as customer } from '@sportson/core-web/state/customer';
import { reducers as ecommerce } from '@sportson/core-web/state/ecommerce';
import { INITIAL_STATE_KEY } from '@sportson/core-web/constants';
import searchReducer from '@sportson/core-web/state/models/Search/reducers';
import { reducer as page } from '@sportson/core-web/state/page';
import { reducer as server } from '@sportson/core-web/state/server';

const isClient = typeof window !== 'undefined';

export const initiateStore = () => {
    const preloadedState = isClient && window[INITIAL_STATE_KEY] ? JSON.parse(window[INITIAL_STATE_KEY]) : {};
    const store = configureStore({
        reducer: {
            application: combineReducers(application), // @TODO: follow this structure
            ecommerce: combineReducers({ ...ecommerce }),
            // basket: basketReducer,
            customer,
            page,
            server,
            search: searchReducer,
        },
        preloadedState,
        devTools: true,
    });

    isClient && delete window[INITIAL_STATE_KEY];

    return store;
};

const store = initiateStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
