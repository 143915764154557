import './styles/fonts.css';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOMClient from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { loadableReady } from '@loadable/component';
import { unregisterServiceWorker } from 'serviceWorker';
import getCurrentHref from '@sportson/core-web/utils/getCurrentHref';
// Setup Sentry for client in production
import { initSentry } from '@sportson/core-web/utils/sentry';
import { initTranslation } from '@sportson/core-web/utils/translation';
import App from '@sportson/core-web/App';
import { INITIAL_PROMISES_CONTEXT, inProduction } from '@sportson/core-web/constants';
import { PromisesProvider } from '@sportson/core-web/contexts/promises-context';
// @todo: the line below could be used when creating the store, google tag manager and stuff, check royal web for inspiration
// import providers from 'middlewares/providers';
// prettier-ignore
import store from '@sportson/core-web/state/store';
import resolveAppState from '@sportson/core-web/state/application/resolve';
import { setInitialStore } from '@sportson/core-web/state/ecommerce/stores';
import { resolve as resolvePage } from '@sportson/core-web/state/page';

initSentry();
unregisterServiceWorker();

const initClient = async (resolve, _reject) => {
    const cache = createCache({ key: 'sportson' });

    let promisesContext = { promises: {}, responses: {} };

    if (typeof window !== 'undefined' && window[INITIAL_PROMISES_CONTEXT]) {
        // take default from data we saved in SSR
        promisesContext = window[INITIAL_PROMISES_CONTEXT];
        delete window[INITIAL_PROMISES_CONTEXT];
    }

    try {
        const { status: applicationStatus } = store.getState().application?.site;
        if (applicationStatus === 'untouched') {
            const currentHref = getCurrentHref();
            await store.dispatch(
                resolveAppState({
                    url: typeof window !== 'undefined' ? window.location.href : currentHref,
                }),
            );
        }

        const { status: pageStatus } = store.getState().page;
        if (pageStatus === 'untouched') {
            const { application } = store.getState();
            const sitePath = application.site.path || '/';
            // Todo: probably need this row when we have subfolder markets domain.com/se/
            // let pageUrl = window.location.pathname === sitePath ? '/' : window.location.pathname.slice(sitePath.length);
            const pageUrl = window?.location.pathname === sitePath ? '/' : window.location.pathname;

            const resolvePageResult = await store.dispatch(resolvePage({ slug: pageUrl, location: window.location }));
            const requestStatus = resolvePageResult?.meta?.requestStatus;
            const status = resolvePageResult?.payload?.status;

            if (requestStatus === 'rejected' || (requestStatus === 'fulfilled' && status === 'redirect')) {
                throw 'redirect';
            }
        }
    } catch (error) {
        console.error('Resolve app error in client', error);

        return resolve();
    }

    const selectedStoreCode = localStorage.getItem('sportson_selected_store');
    if (selectedStoreCode) {
        store.dispatch(setInitialStore(selectedStoreCode));
    }

    const reduxState = store.getState();
    const { locale } = reduxState.application.site;
    const translationLocale = locale?.replace('_', '-');
    const translation = await import(/* webpackChunkName: "[request]" */ `locales/${translationLocale}.json`);

    initTranslation({
        lng: translationLocale,
        resources: {
            [translationLocale]: {
                translation,
            },
        },
    });

    loadableReady(() => {
        const AppWithCallbackAfterRender = () => {
            useEffect(() => {
                resolve();
            });

            return (
                <PromisesProvider value={promisesContext}>
                    <CacheProvider value={cache}>
                        <CookiesProvider>
                            <ReduxProvider store={store}>
                                <HelmetProvider>
                                    <BrowserRouter>
                                        <App />
                                    </BrowserRouter>
                                </HelmetProvider>
                            </ReduxProvider>
                        </CookiesProvider>
                    </CacheProvider>
                </PromisesProvider>
            );
        };

        // TODO: Check react 18 implementation https://react.dev/blog/2022/03/08/react-18-upgrade-guide
        const container = document.getElementById('root');
        if (inProduction) {
            ReactDOMClient.hydrateRoot(container, <AppWithCallbackAfterRender />);
        } else {
            ReactDOMClient.createRoot(container).render(<AppWithCallbackAfterRender />);
        }
    });
};

export default new Promise((resolve, reject) => {
    initClient(resolve, reject);
});
