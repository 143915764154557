import styled from '@grebban/style-system-react';
import { useHeaderNavigationContext } from '@sportson/core-web/contexts/HeaderNavigationContext';
import { above } from '@sportson/core-web/utils/mediaqueries';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Breakpoint from '@sportson/core-web/components/Breakpoint';

import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { allowScroll, preventScroll } from '@sportson/core-web/utils/scroll';
import { AnimateHeight } from 'components/AnimateHeight';

import { SportsonLogo } from 'assets/media/icons/SportsonLogo';
import SearchBar from 'components/SearchBar';

import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';
import Cross from 'assets/media/icons/Cross';
import Button from 'components/Button';
import Text from 'components/Text';
import Toolbox from './toolbox/Toolbox';
import { Banner } from './Banner';
import Nav from './navigation/Nav';
import RightSideLinks from './navigation/RightSideLinks';
import TopNav from './navigation/TopNav';
import MobileBottomRow from './toolbox/MobileBottomRow';

const Div = styled('div')`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    background-color: var(--color-base-black);
`;

const StyledHeader = styled(Div.withComponent('header'))``;

const CheckoutHeader = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 8px 12px;
    background-color: var(--color-base-black);

    ${above['desktop.sm']} {
        height: 88px;
        padding: 24px;
    }
`;

const LogoLink = styled('a')``;

const BottomRow = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${above['desktop.sm']} {
        background: ${({ isMenuOpen }) => (isMenuOpen ? 'var(--color-base-white)' : 'var(--color-base-black)')};
        box-shadow: ${({ isMenuOpen }) => (isMenuOpen ? '0px -8px 0px 0px var(--color-base-white)' : 'none')};
    }
`;

const MaxWidthWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: var(--site-corridor-max-width);
`;

const HeaderWrapper = styled('div')`
    background-color: var(--color-base-black);
    min-height: var(--header-height);
    display: flex;
    flex-direction: column;
`;

const TopRow = styled(MaxWidthWrapper)`
    align-items: center;
    min-height: 56px;
    gap: 16px;
    padding: 8px 12px;

    ${above['desktop.sm']} {
        padding: 24px 40px;
    }
`;

const TheIcon = styled(Cross)`
    color: var(--color-brand-yellow);
`;

const Checkout = ({ t }) => {
    const isMobile = useBreakpoint({ to: 'desktop.sm' });

    return (
        <CheckoutHeader>
            <a href="/" aria-label={t('aria_labels.to_startpage')}>
                <SportsonLogo height={isMobile ? '24px' : '40px'} width="auto" color="var(--color-brand-yellow)" />
            </a>
            <Text as="h1" color="var(--color-base-white)" typography="UI/14_100_0_450_uppercase">
                {t('checkout.checkout')}
            </Text>
            <Button url="/" typography="UI/16_100_0_450" display="flex" gap="6px" theme="secondaryDark">
                {t('continue_shopping')} <ArrowIcon size="xsmall" color="var(--color-brand-yellow)" />
            </Button>
        </CheckoutHeader>
    );
};

const BikeSelector = ({ t }) => {
    const isMobile = useBreakpoint({ to: 'desktop.sm' });
    return (
        <CheckoutHeader>
            <SportsonLogo height={isMobile ? '24px' : '40px'} width="auto" color="var(--color-brand-yellow)" />
            <Button url="/" typography="UI/16_100_0_450" display="flex" gap="6px" theme="secondaryDark">
                {t('continue_shopping')} <TheIcon size="xsmall" color="var(--color-brand-yellow)" />
            </Button>
        </CheckoutHeader>
    );
};

const DefaultHeader = () => {
    const isMobile = useBreakpoint({ to: 'desktop.sm' });
    const { t } = useTranslation();

    const [showHeader, setShowHeader] = useState(true);
    const { headerNavigationState, setHeaderNavigationState } = useHeaderNavigationContext();
    const [y, setY] = useState(0);

    const handleNavigation = useCallback(
        (e) => {
            const window = e?.currentTarget;
            if (y > window?.scrollY) {
                setShowHeader(true);
            } else if (y < window?.scrollY && window?.scrollY > 50) {
                setShowHeader(false);
            }
            setY(window?.scrollY);
        },
        [y],
    );

    useEffect(() => {
        if (headerNavigationState.mobileMenuActive || headerNavigationState.desktopMenuActive) {
            preventScroll();
        } else {
            allowScroll();
        }
    }, [headerNavigationState.desktopMenuActive, headerNavigationState.mobileMenuActive]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setY(window?.scrollY);
            window?.addEventListener('scroll', handleNavigation);

            return () => {
                window?.removeEventListener('scroll', handleNavigation);
            };
        }
    }, [handleNavigation]);

    return (
        <StyledHeader>
            <AnimateHeight transition="var(--transition-primary-fast)" isOpen={showHeader}>
                <HeaderWrapper>
                    <TopRow>
                        <LogoLink
                            href="/"
                            aria-label={t('store_listing.to_store_page', {
                                pageName: t('header.main'),
                            })}
                        >
                            <SportsonLogo
                                height={isMobile ? '24px' : '40px'}
                                width="auto"
                                color="var(--color-brand-yellow)"
                            />
                        </LogoLink>
                        <Breakpoint
                            from="desktop.sm"
                            render={
                                <>
                                    {/* TODO: We will fix this when implementing */}
                                    <SearchBar />
                                    <TopNav />
                                </>
                            }
                        />
                        <Toolbox isMenuOpen={!headerNavigationState.mobileMenuActive} />
                    </TopRow>

                    <BottomRow isMenuOpen={headerNavigationState.desktopMenuActive}>
                        <MaxWidthWrapper>
                            <Nav />
                            <RightSideLinks />
                            <MobileBottomRow
                                isMenuOpen={headerNavigationState.mobileMenuActive}
                                headerNavigationState={headerNavigationState}
                                setHeaderNavigationState={setHeaderNavigationState}
                            />
                        </MaxWidthWrapper>
                    </BottomRow>
                </HeaderWrapper>
                <Banner />
            </AnimateHeight>
        </StyledHeader>
    );
};

const headerMapping = {
    bike_selector: BikeSelector,
    norce_checkout: Checkout,
    default: DefaultHeader,
};

export const Header = () => {
    const { t } = useTranslation();
    const pageTemplate = useAppSelector(({ page }) => page.template);

    if (pageTemplate === 'bike_selector') {
        return null;
    }

    // @ts-expect-error: Reason:  We know that we'll always have a template
    const Component = headerMapping[pageTemplate] || headerMapping.default;

    return <Component t={t} />;
};
