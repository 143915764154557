import { createSlice } from '@reduxjs/toolkit';

export interface INotification {
    template: string;
    data: any;
    timestamp: number;
    lifespan: number | null;
}

export type INotificationState = INotification[];

const initialState: INotificationState = [];

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        add: (state, action) => {
            // If template already exists, don't add it again
            if (state.some((notification) => notification.template === action.payload.template)) {
                return;
            }

            state.push(action.payload);
        },
        update: (state, action) => {
            // Find the correct notification and update the data
            state.forEach((notification) => {
                if (notification.template === action.payload.template) {
                    notification.data = action.payload.data;
                }
            });
        },
        delay: (state, action) => {
            // Find the correct notification and update the lifespan
            state.forEach((notification) => {
                if (notification.template === action.payload.template) {
                    notification.lifespan = action.payload.lifespan;
                }
            });
        },
        freeze: (state, action) => {
            // Find the correct notification and null the lifespan
            state.forEach((notification) => {
                if (notification.template === action.payload.template) {
                    notification.lifespan = null;
                }
            });
        },
        close: (state, action) => {
            state.splice(action.payload, 1);
        },
    },
});

export {};
export const { add, close, delay, update, freeze } = slice.actions;
export const { reducer } = slice;
