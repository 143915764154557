import { type CssVariables } from './types';

export interface OpacityCssVariables extends CssVariables<number> {
    '--opacity-light': number;
    '--opacity-default': number;
    '--opacity-transparent': number;
}
export const opacityCssVariables: OpacityCssVariables = {
    '--opacity-light': 0.8,
    '--opacity-default': 0.6,
    '--opacity-medium': 0.4,
    '--opacity-loading': 0.2,
    '--opacity-transparent': 0.0,
};
