import { STORM_MEDIA_URL } from 'config/constants';

// Check if media-url contains base-url or if we need to grab it from .env
export const getMediaUrl = (url) => {
    if (!url) {
        return null;
    }
    if (url.includes('https://') || url.includes('http://')) {
        return url;
    }
    return `${STORM_MEDIA_URL}/${url}`;
};
