import React, { useEffect, useRef, useState, type InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from '@grebban/style-system-react/index';
import { STYLES } from 'config/branding/buttons';
import Text from 'components/Text';
import { type CssVariable } from 'config/branding/types';
import { type CssColorKey } from 'config/branding/colors';
import classNames from 'classnames';

const InputWrapper = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width: 100%;
`;

const Label = styled(Text)`
    padding-left: 4px;
    text-transform: uppercase;
    color: var(--color-neutrals-500);

    /* Visually hide the label element but leave available for screen readers */
    &.visually-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
`;

const InputElement = styled('input')`
    font-family: 'emeric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-decoration: unset;

    border-radius: 32px;
    border: 1px solid red;
    padding: 12px 16px;
    width: 100%;
    color: var(--color-neutrals-400);
    border: 1px solid var(--color-neutrals-600);

    :hover {
        color: var(--color-neutrals-400);
        border-color: var(--color-neutrals-400);
    }

    :focus {
        color: var(--color-neutrals-300);
        border-color: var(--color-base-black);
    }

    &:disabled {
        background-color: ${({ theme }) => theme.disabled?.color || theme.color};
        color: ${({ theme }) => theme.disabled?.color || theme.color};
        border: 1px solid var(--color-neutrals-500);
        pointer-events: none;
        cursor: not-allowed;

        svg:not(.loading-icon) path {
            fill: ${({ theme }) => theme.disabled?.color || theme.color};
        }

        &:hover {
            background-color: ${({ theme }) => theme.disabled?.backgroundColor || theme.backgroundColor};
            color: ${({ theme }) => theme.disabled?.color || theme.color};
            border: ${({ theme }) => theme.disabled?.borderColor || theme.border};
        }
    }

    &.is-typing {
        color: var(--color-base-black);
        border: 1px solid var(--color-base-black);
    }

    &.has-error {
        color: var(--color-semantic-red);
        border: 1px solid var(--color-semantic-red);
    }

    &.inverted {
        background-color: transparent;
        border: 1px solid var(--color-neutrals-600);
        color: var(--color-neutrals-400);

        :hover {
            color: var(--color-neutrals-400);
            background-color: var(--color-transparency-grey-50);
            border: 1px solid var(--color-neutrals-600);
        }

        :focus {
            color: var(--color-neutrals-500);
        }

        &.has-error {
            color: var(--color-semantic-red-02);
            border: 1px solid var(--color-semantic-red-02);
        }

        &.is-typing {
            color: var(--color-base-white);
            border: 1px solid var(--color-neutrals-300);
        }
    }
`;

const ErrorWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const ErrorMessage = styled(Text)`
    color: var(--color-semantic-red);

    &.inverted {
        color: var(--color-semantic-red-02);
    }
`;

export type ValidityErrors = keyof ValidityState;

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id'> {
    name: string;
    label: string;
    translationsErrorPrefix?: string;
    customErrors?: Partial<Record<ValidityErrors, string>>;
    isFooter?: boolean;
    hideLabel?: boolean;
    labelColor?: CssVariable<CssColorKey>;
    inverted?: boolean;
    onErrorChange?: (hasError: boolean) => void;
}

const Input = ({
    defaultValue,
    required,
    label,
    name,
    translationsErrorPrefix = '',
    onBlur,
    customErrors = {},
    isFooter = false,
    hideLabel = false,
    labelColor = 'var(--color-neutrals-300)',
    inverted = false,
    onErrorChange,
    ...props
}: InputProps) => {
    const { t } = useTranslation();
    const [isTyping, setIsTyping] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [touched, setTouched] = useState<boolean>(false);
    const [inputErrors, setInputErrors] = useState<string[]>([]);
    const location = useLocation();

    useEffect(() => {
        setInputErrors([]);
    }, [location.pathname]);

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 0) {
            setIsTyping(true);
        } else {
            setIsTyping(false);
        }
        props.onChange?.(ev);
    };

    const validateInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!inputRef.current) return;

        const { validity } = ev.target;
        // https://www.w3schools.com/js/js_validation_api.asp
        // customError	    Set to true, if a custom validity message is set.
        // patternMismatch	Set to true, if an element's value does not match its pattern attribute.
        // rangeOverflow	Set to true, if an element's value is greater than its max attribute.
        // rangeUnderflow	Set to true, if an element's value is less than its min attribute.
        // stepMismatch	    Set to true, if an element's value is invalid per its step attribute.
        // tooLong	        Set to true, if an element's value exceeds its maxLength attribute.
        // typeMismatch	    Set to true, if an element's value is invalid per its type attribute.
        // valueMissing	    Set to true, if an element (with a required attribute) has no value.
        // valid	        Set to true, if an element's value is valid.

        const validityStateKeys: string[] = [];
        const errors: string[] = [];

        for (const error in validity) {
            if (error === 'customError' || error === 'valid') {
                continue;
            }
            validityStateKeys.push(error);
            if (validity[error]) {
                // @ts-expect-error: Reason:  Temp remove this since it throws an error but not in other projects with the same code.
                const customError = customErrors[error] ?? t(`${translationsErrorPrefix}${name}.${error}`);
                errors.push(customError);
            }
        }

        const hasErrors = errors.length > 0;

        if (onErrorChange) {
            onErrorChange(hasErrors);
        }

        // Since we don't have any errors, the input should be valid.
        if (hasErrors) {
            const firstErrorIsCustomMessage = validityStateKeys.indexOf(errors[0]) === -1;
            inputRef.current.setCustomValidity(
                firstErrorIsCustomMessage ? errors[0] : inputRef.current.validationMessage,
            );
            setInputErrors(errors);
            return;
        }

        inputRef.current.setCustomValidity('');
        setInputErrors([]);
    };

    const inputClasses = classNames({
        inverted: !!inverted,
        'has-error': !!inputErrors.length,
        'is-typing': isTyping && !inputErrors.length,
    });

    return (
        <InputWrapper touched={touched} hasErrors={!!inputErrors.length} gap={isFooter && '16px'}>
            <Label
                className={hideLabel && 'visually-hidden'}
                $as="label"
                touched={touched}
                hasErrors={!!inputErrors.length}
                htmlFor={name}
                typography="UI/12_100_0_450_uppercase"
                color={isFooter ? 'var(--color-brand-yellow)' : labelColor}
            >
                {label}
            </Label>
            {inputErrors.length > 0 && (
                <ErrorWrapper>
                    {inputErrors.map((error) => (
                        <ErrorMessage
                            key={error}
                            $as="p"
                            typography="Content/14_130_0_350"
                            className={inverted && 'inverted'}
                        >
                            {error}
                        </ErrorMessage>
                    ))}
                </ErrorWrapper>
            )}
            <InputElement
                maxHeight={isFooter && '42px'}
                marginTop={isFooter ? 'unset' : '8px'}
                touched={touched}
                hasErrors={!!inputErrors.length}
                ref={inputRef}
                required={required}
                defaultValue={defaultValue}
                name={name}
                id={name}
                onBlur={(ev) => {
                    setTouched(true);
                    validateInput(ev);
                    onBlur?.(ev);
                }}
                onInvalid={validateInput}
                onChange={handleInputChange}
                style={STYLES}
                className={inputClasses}
                {...props}
            />
        </InputWrapper>
    );
};

export default Input;
