import CoreButton from '@grebban/react-core-components/Button';
import styled from '@grebban/style-system-react';
import React from 'react';

const StyledButton = styled(CoreButton)`
    outline: none;
    border: none;
    display: flex;
    align-items: center;
`;

// @TODO Extend CoreButton props
export interface TextButtonProps {
    onClick: (ev: React.MouseEvent<HTMLElement>) => void;
    children: typeof React.Children | React.ReactNode | React.ReactNode[];
}

const TextButton: React.FunctionComponent<TextButtonProps> = ({ onClick, children, ...rest }) => (
    <StyledButton type="button" onClick={onClick} {...rest}>
        {children}
    </StyledButton>
);

export default TextButton;
