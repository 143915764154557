import React from 'react';
import Path from '@grebban/react-core-components/svg/Path';
import Svg from '@grebban/react-core-components/svg/Svg';
import PropTypes from 'prop-types';

const sizes = {
    xsmall: {
        viewBox: '0 0 12 12',
        width: '12px',
        height: '12px',
        pathOne: 'M6 0V12',
        pathTwo: 'M12 6L0 6',
    },

    small: {
        viewBox: '0 0 17 16',
        width: '17px',
        height: '16px',
        pathOne: 'M8.33301 0V16',
        pathTwo: 'M16.333 8L0.333008 8',
    },

    large: {
        viewBox: '0 0 25 24',
        width: '25px',
        height: '24px',
        pathOne: 'M12.667 0V24',
        pathTwo: 'M24.667 12L0.666992 12',
    },
};

const Plus = ({ color = 'var(--color-base-black)', size, height, width, viewBox, ...rest }) => {
    const selectedSize = sizes[size] || sizes.small;
    return (
        <Svg
            width={width || selectedSize.width}
            height={height || selectedSize.height}
            viewBox={viewBox || selectedSize.viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <Path id="lineOne" d={selectedSize.pathOne} stroke={color} />
            <Path id="lineTwo" d={selectedSize.pathTwo} stroke={color} />
        </Svg>
    );
};

Plus.propTypes = {
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    size: PropTypes.oneOf(['xsmall', 'small', 'large']),
    viewBox: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Plus;
