import React from 'react';
import Svg from '@grebban/react-core-components/svg/Svg';
import Path from '@grebban/react-core-components/svg/Path';
import PropTypes from 'prop-types';

const paths = {
    up: 'M7 13L7 0.999999M7 0.999999L13 6.33333M7 0.999999L1 6.33333',
    right: 'M1 7L13 7M13 7L7.66667 13M13 7L7.66667 1',
    down: 'M7 1V13M7 13L1 7.66667M7 13L13 7.66667',
    left: 'M13 7H1m0 0l5.333-6M1 7l5.333 6',
};

const Arrow = ({
    color = 'var(--color-base-black)',
    height = '10px',
    width = '10px',
    viewBox = '0 0 14 14',
    direction = 'left',
}) => (
    <Svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d={paths[direction]} stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
);

Arrow.propTypes = {
    color: PropTypes.string,
    direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
    height: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

export default Arrow;
