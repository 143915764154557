import fontFamilies from './fontFamilies';
// @TODO Style should depends on the font
// https://stackoverflow.com/questions/56949513/typescript-type-of-a-property-dependent-on-another-property-within-the-same-obj
export interface TextStyle {
    fontFamily: string;
    fontStyle?: string;
    fontSize: string;
    fontWeight: string;
    letterSpacing?: string;
    lineHeight: string;
    textDecoration?: string;
    textTransform?: string;
}

export type Typography = keyof typeof typography;
const typography: Record<string, TextStyle> = {
    'Content/80_120_0_500': {
        fontFamily: fontFamilies.emericMedium,
        fontSize: '80px',
        lineHeight: '120%',
        letterSpacing: '0%',
        fontWeight: '500',
        textTransform: 'none',
    },
    'Content/64_120_0_500': {
        fontFamily: fontFamilies.emericMedium,
        fontSize: '64px',
        lineHeight: '120%',
        letterSpacing: '0%',
        fontWeight: '500',
        textTransform: 'none',
    },
    'Content/56_120_0_500': {
        fontFamily: fontFamilies.emericMedium,
        fontSize: '56px',
        lineHeight: '120%',
        letterSpacing: '0%',
        fontWeight: '500',
        textTransform: 'none',
    },
    'Content/48_120_0_500': {
        fontFamily: fontFamilies.emericMedium,
        fontSize: '48px',
        lineHeight: '120%',
        letterSpacing: '0%',
        fontWeight: '500',
        textTransform: 'none',
    },
    'Content/40_120_0_500': {
        fontFamily: fontFamilies.emericMedium,
        fontSize: '40px',
        lineHeight: '120%',
        letterSpacing: '0%',
        fontWeight: '500',
        textTransform: 'none',
    },
    'Content/32_120_0_500': {
        fontFamily: fontFamilies.emericMedium,
        fontSize: '32px',
        lineHeight: '120%',
        letterSpacing: '0%',
        fontWeight: '500',
        textTransform: 'none',
    },
    'Content/32_130_0_500': {
        fontFamily: fontFamilies.emericMedium,
        fontSize: '32px',
        lineHeight: '130%',
        letterSpacing: '0px',
        fontWeight: '500',
        textTransform: 'none',
    },
    'Content/16_130_0_500': {
        // TODO: Temporary waiting for design system
        fontFamily: fontFamilies.emericMedium,
        fontSize: '16px',
        lineHeight: '100%',
        letterSpacing: '0px',
        fontWeight: '500',
        textTransform: 'none',
    },
    'Content/24_130_0_450': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '24px',
        lineHeight: '130%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'none',
    },
    'Content/24_140_0_350': {
        fontFamily: fontFamilies.emericBook,
        fontSize: '24px',
        lineHeight: '140%',
        letterSpacing: '0%',
        fontWeight: '350',
        textTransform: 'none',
    },
    'Content/20_130_0_450': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '20px',
        lineHeight: '130%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'none',
    },
    'Content/20_140_0_350': {
        fontFamily: fontFamilies.emericBook,
        fontSize: '20px',
        lineHeight: '140%',
        letterSpacing: '0%',
        fontWeight: '350',
        textTransform: 'none',
    },
    'Content/18_150_0_350': {
        fontFamily: fontFamilies.emericBook,
        fontSize: '18px',
        lineHeight: '150%',
        letterSpacing: '0%',
        fontWeight: '350',
        textTransform: 'none',
    },
    'Content/16_130_0_350': {
        fontFamily: fontFamilies.emericBook,
        fontSize: '16px',
        lineHeight: '130%',
        letterSpacing: '0%',
        fontWeight: '350',
        textTransform: 'none',
    },
    'Content/16_150_0_350': {
        fontFamily: fontFamilies.emericBook,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0%',
        fontWeight: '350',
        textTransform: 'none',
    },
    'Content/14_130_0_350': {
        fontFamily: fontFamilies.emericBook,
        fontSize: '14px',
        lineHeight: '130%',
        letterSpacing: '0%',
        fontWeight: '350',
        textTransform: 'none',
    },
    'Content/14_130_0_450': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '14px',
        lineHeight: '130%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'none',
    },
    'UI/24_100_0_450': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '24px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'none',
    },
    'UI/20_100_0_450': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '20px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'none',
    },
    'UI/16_100_0_450': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '16px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'none',
    },
    'UI/16_100_0_350': {
        fontFamily: fontFamilies.emericBook,
        fontSize: '16px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '350',
        textTransform: 'none',
    },
    'UI/14_100_0_450': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '14px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'none',
    },
    'UI/14_100_0_450_uppercase': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '14px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '400',
        textTransform: 'uppercase',
    },
    'UI/12_100_0_450': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '12px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'none',
    },
    'UI/12_100_0_450_uppercase': {
        fontFamily: fontFamilies.emericCore,
        fontSize: '12px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '450',
        textTransform: 'uppercase',
    },
    'UI/12_100_0_350': {
        fontFamily: fontFamilies.emericBook,
        fontSize: '12px',
        lineHeight: '100%',
        letterSpacing: '0%',
        fontWeight: '350',
        textTransform: 'none',
    },
};

export default typography;
