import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createSlice } from '@reduxjs/toolkit';
import resolveApplication from '@sportson/core-web/state/application/resolve';

export interface IFooterState {
    data: {};
}

const initialState: IFooterState = {
    data: {},
};
const slice = createSlice({
    name: 'footer',
    initialState,
    reducers: {
        setState: (state, data) => {
            const { payload } = data;
            return {
                ...state,
                ...payload,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(resolveApplication.fulfilled, (state, { payload }) => {
            const footerData = objectKeysToCamelCase(payload?.data?.config?.footer || {}, {
                recursive: true,
                modifyInputObject: false,
            });
            state.data = footerData;
        });
    },
});

export {};
export const { setState: setFooterState } = slice.actions;
export const { reducer } = slice;
