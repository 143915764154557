import usePrevious from '@grebban/react-hooks/usePrevious';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
// import extractQueryParams from '@grebban/string-utils/extractQueryParams';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import { resolve as resolvePage } from '@sportson/core-web/state/page';
import { setServerState } from '@sportson/core-web/state/server';
import store from '@sportson/core-web/state/store';
import View from 'Views/View';

const Views = () => {
    // React redux dispatch.
    const dispatch = useAppDispatch();

    // React router location values.
    const { pathname, search } = useLocation();
    const previousSearch = usePrevious(search);
    const previousPathname = usePrevious(pathname);

    // React redux page values.
    const pageType = useAppSelector(({ page }) => page.type);
    const pageId = useAppSelector(({ page }) => page.data.id);
    const pageStatus = useAppSelector(({ page }) => page.status);
    const pageTemplate = useAppSelector(({ page }) => page.template);
    const pageRedirect = useAppSelector(({ page }) => (page.type === 'redirect' && page.data.to ? page.data.to : ''));

    const page = useAppSelector(({ page }) => page) || {};
    const currencyCode = useAppSelector(({ application }) => application.site.currency.code);

    // React redux application values.
    const sitePath = useAppSelector(({ application }) => application.site?.path);
    const applicationId = useAppSelector(({ application }) => application.site?.applicationId);

    // Environment options.
    const withLoadingPageTransition = process.env.REACT_APP_USE_LOADING_PAGE_TRANSITION === 'true';

    useEffect(() => {
        const updatedSearch = previousSearch !== undefined && search !== previousSearch;
        const updatedPathname = previousPathname !== undefined && pathname !== previousPathname;

        (async function () {
            // Handle updated location search (when url param change on client).
            if (updatedSearch) {
                // Todo: maybe need this for something
                // const queryParams = extractQueryParams(search) || {};
            }

            // Handle updated location pathname (when navigating on client).
            if (updatedPathname) {
                // We resolve view upon navigation on client side.
                await store.dispatch(
                    resolvePage({
                        slug: pathname.replace(new RegExp(`^${sitePath}`, 'i'), '/'),
                        location: window.location,
                    }),
                );
                // We reset server requestUrl & requestBody upon client side navigation.
                dispatch(setServerState({ requestUrl: '', requestBody: {} }));
            }
        })();
    }, [pathname, search]);

    // If we have a redirect then we redirect.
    if (pageRedirect) {
        return <Redirect to={pageRedirect} />;
    }

    return (
        <View
            type={pageType}
            template={pageTemplate}
            id={pageId ? `${pageId}` : pageId}
            loading={withLoadingPageTransition && pageStatus === 'pending'}
        />
    );
};

export default Views;
