const debugCounter = {};

const getFullDebugContext = (context: string, message: string) => `${context}|${message}`;
const createDebugContext = (context: string, message: string) => {
    const fullDebugContext = getFullDebugContext(context, message);
    if (!debugCounter[fullDebugContext]) {
        debugCounter[fullDebugContext] = 0;
    }
    debugCounter[fullDebugContext] += 1;

    return fullDebugContext;
};

const createDebugMessage = (context: string, message: string): string => {
    const time = new Date().getTime();
    const fullDebugContext = `${context}|${message}`;
    return `Timestamp: ${time}, Count: ${debugCounter[fullDebugContext]} \n${context} > ${message}`;
};

interface CreateConsoleMessageGroupOptions {
    collapsed?: boolean;
    label?: string;
}
const groupConsoleMessagesDefaultOptions: Required<CreateConsoleMessageGroupOptions> = {
    collapsed: true,
    label: 'Group',
};

const groupConsoleMessages = (
    context: string,
    message: string,
    options: CreateConsoleMessageGroupOptions = groupConsoleMessagesDefaultOptions,
) => {
    const { collapsed, label } = { ...groupConsoleMessagesDefaultOptions, ...options };
    const fullDebugContext = createDebugContext(context, label);
    const countHandle = debugCounter[fullDebugContext];

    const TimerHandle = `${fullDebugContext}|${countHandle}`;
    console.time(TimerHandle);

    const groupDebugMessage = createDebugMessage(context, label);
    if (collapsed) {
        console.groupCollapsed(groupDebugMessage);
    } else {
        console.group(groupDebugMessage);
    }
    return () => {
        console.timeEnd(TimerHandle);
        console.groupEnd();
    };
};

interface DebugHelperOptions {
    messageType?: 'log' | 'warn' | 'error';
    condition?: boolean;
    triggerDebugger?: boolean;
    createConsoleMessageGroup?: boolean | CreateConsoleMessageGroupOptions;
}

interface DebugHelperParameters {
    context: string;
    message: string;
    options?: DebugHelperOptions;
    additionalParameters?: Record<string, unknown>;
}

const defaultOptions: Required<DebugHelperOptions> = {
    messageType: 'log',
    condition: true,
    triggerDebugger: false,
    createConsoleMessageGroup: false,
    // logLevel: // @TODO Implement logLevel.
};

let contextLimits: string[] = [];
if (process.env.REACT_APP_DEBUG_HELPER_CONTEXT_FILTER) {
    contextLimits = process.env.REACT_APP_DEBUG_HELPER_CONTEXT_FILTER.split(',');
}

export const debugHelper = ({
    context,
    message,
    options = defaultOptions,
    additionalParameters,
}: DebugHelperParameters) => {
    const returnObject: {
        endConsoleGroup?: () => void;
    } = {};

    if (!process.env.REACT_APP_DEBUG_HELPER) {
        return returnObject;
    }

    const { condition, messageType, triggerDebugger, createConsoleMessageGroup } = { ...defaultOptions, ...options };
    if (!condition || (contextLimits.length > 0 && contextLimits.indexOf(context) === -1)) {
        return returnObject;
    }

    createDebugContext(context, message);

    if (createConsoleMessageGroup) {
        returnObject.endConsoleGroup = groupConsoleMessages(
            context,
            message,
            typeof createConsoleMessageGroup === 'object' ? createConsoleMessageGroup : undefined,
        );
    }

    const debugMessage = createDebugMessage(context, message);
    if (additionalParameters) {
        console[messageType](debugMessage, JSON.parse(JSON.stringify(additionalParameters)));
    } else {
        console[messageType](debugMessage);
    }

    if (triggerDebugger) {
        // eslint-disable-next-line no-debugger -- Let the function use the debugger since it is nice for debugging...
        debugger;
    }

    return returnObject;
};
