import PropTypes from 'prop-types';
import React from 'react';

const ListItem = ({ children, TextComponent }) => (
    <li>
        <TextComponent display="inline-block" my="8px">
            {children}
        </TextComponent>
    </li>
);

ListItem.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ListItem;
