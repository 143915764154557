import { getModel } from '@sportson/core-web/state';
import { API_ENDPOINT, API_URL } from '@sportson/core-web/constants';
import buildQueryString from './buildQueryString';

class ApiResponse {
    constructor(response, error = null) {
        this.res = response;
        this.error = error;
    }

    response() {
        return this.res;
    }

    error() {
        return this.error;
    }

    body() {
        switch (this.header('content-type')) {
            case 'application/json':
                return this.response().json();
            case 'text/plain':
                return this.response().text();
            default:
                return this.response();
        }
        // Exception
    }

    status() {
        return this.response()?.status;
    }

    ok() {
        return this.response()?.ok;
    }

    headers() {
        return this.response()?.headers;
    }

    header(name) {
        return this.headers()?.get(name);
    }
}

const getBaseUrl = () => {
    const { origin, protocol } = getModel('application').site;
    return API_URL || protocol + origin + API_ENDPOINT;
};

export const request = async ({ path, method, body, headers = {}, baseUrl, controllerSignal, ...extraConfig }) => {
    if (!baseUrl) {
        baseUrl = getBaseUrl();
    }

    const url = baseUrl + path;

    if (process.env.NODE_ENV === 'development' && !headers.hasOwnProperty('Cache-Control')) {
        headers['Cache-Control'] = 'no-cache';
    }
    const config = {
        method,
        ...extraConfig,
        credentials: 'include', // include, *same-origin, omit
        headers: {
            Accept: 'application/json',
            ...headers,
            ...extraConfig.headers,
        },
    };
    if (body) {
        config.body = JSON.stringify(body);
    }

    if (controllerSignal) {
        config.signal = controllerSignal;
    }

    try {
        const response = await fetch(url, config);
        return new ApiResponse(response);
    } catch (e) {
        return new ApiResponse(null, e);
    }
};

const getParameters = (url, query) => {
    if (!query) {
        return url;
    }
    const prefix = url.indexOf('?') > -1 ? '&' : '?';
    const queryString = buildQueryString(query);
    return url + prefix + queryString;
};

export const get = (endpoint, body, headers, controllerSignal) =>
    request({
        path: getParameters(endpoint, body),
        method: 'GET',
        body: null,
        headers,
        controllerSignal,
    });

export const post = (endpoint, body, headers) =>
    request({
        path: endpoint,
        method: 'POST',
        body,
        headers: { ...headers, ...{ 'Content-Type': 'application/json' } },
    });

export const put = (endpoint, body, headers) =>
    request({
        path: endpoint,
        method: 'PUT',
        body,
        headers: { ...headers, ...{ 'Content-Type': 'application/json' } },
    });

export const del = (endpoint, body, headers) =>
    request({
        path: endpoint,
        method: 'DELETE',
        body,
        headers: { ...headers, ...{ 'Content-Type': 'application/json' } },
    });
