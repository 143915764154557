import { inServer } from '@sportson/core-web/constants';

const FREIGHT_PART_NO = process.env.REACT_APP_STORM_FREIGHT_NO;

class DataLayer {
    constructor() {
        this.intervalHandler = null;
        this.queue = [];
        this.gtmTrackingKey = process.env.REACT_APP_GTM_TRACKING_KEY;
        this.parametricsBlockList = [];
    }

    handler() {
        if (this.intervalHandler === null) {
            const self = this;
            this.intervalHandler = setInterval(() => {
                if (self.isLoaded()) {
                    clearInterval(self.intervalHandler);
                    self.processQueue();
                }
            }, 100);
        }
    }

    track(data = {}) {
        if (!this.isLoaded()) {
            this.queue.push(() => this.process(data));
            this.handler();
        } else {
            this.process(data);
        }
    }

    process(data) {
        window.dataLayer.push(data);
    }

    processQueue() {
        while (this.queue.length > 0) {
            this.queue.shift()();
        }
    }

    isLoaded() {
        if (!inServer) {
            return (
                window.google_tag_manager && window.google_tag_manager[this.gtmTrackingKey] && window.Cookiebot.loaded
            );
        }
        return false;
    }

    reset() {
        if (!this.isLoaded()) {
            this.queue.push(() => {
                window.google_tag_manager[this.gtmTrackingKey].dataLayer.reset();
            });
            this.handler();
        } else {
            window.google_tag_manager[this.gtmTrackingKey].dataLayer.reset();
        }
    }

    formatPrice(price) {
        let fixedPrice = price;
        const p = price.toString();
        const onlydecimals = p.split(/[.,]+/)[1] || null;

        if (onlydecimals?.length > 2) {
            fixedPrice = Math.round(price * 100) / 100;
        }
        return fixedPrice;
    }

    /**
     * Transform product data to Google Enhanced Ecommerce format.
     * Important: Only for Norce products.
     *
     * @param {object} product - Norce product object
     * @param {number|null} position
     */
    getProduct(product, position = null) {
        if (!product) {
            return;
        }

        const {
            part_no: partNo,
            name,
            parentProductName,
            categories,
            price,
            ean,
            eanCode,
            category_id: categoryId,
            manufacturer,
            manufacturerName,
            quantity,
            format,

            parent_product_id: parentProductId,
            meta_tags: metaTags,
            type,
        } = product;

        const productId = product.product_id || product.productId || product.id;

        const vatRate = product?.vat_rate || product?.vatRate;

        const fixedPrice = price && vatRate ? this.formatPrice(price * vatRate) : 0;

        const dataProduct = {
            name: parentProductName || name || metaTags || '',
            id: partNo || product.partNo || '',
            ean: ean || product.ean_code || eanCode || '',
            price: fixedPrice,
            priceExVat: price || '',
            brand: manufacturer?.name || product?.manufacturer_name || manufacturerName || '',
            productId: `${productId || ''}`,
        };

        if (quantity) {
            dataProduct.quantity = quantity;
        }

        if (categoryId) {
            let allCategories;
            if (Array.isArray(categories) && categories.length > 0) {
                allCategories = [...categories];
                if (allCategories.length > 1 && allCategories[0].id === categoryId) {
                    allCategories.reverse();
                }
            }

            if (allCategories) {
                const categoryString = allCategories.map((category) => category.value || category.name).join('/');
                dataProduct.category = categoryString;
                const category = allCategories.find((category) => category.id === categoryId) || {};
                dataProduct.dimension2 = category.code || allCategories.pop().code;
            }
        }

        let isVariantProduct = true; // type !== PACKAGE_TYPE_ID; // Default to true if not package
        if (Array.isArray(product.variants)) {
            isVariantProduct = !!product.variants.length; // exist if product comes from PDP (storm)
        }

        const parametrics = format?.variant_parametrics; // ?? product.variant_parametrics
        if (isVariantProduct && Array.isArray(parametrics) && parametrics.length > 0) {
            dataProduct.variant = parametrics
                .map((data) => data.value)
                .filter((p) => p)
                .join('|');
        }

        if (product.list !== undefined) {
            dataProduct.list = product.list;
        }

        if (position !== null) {
            dataProduct.position = position + 1;
        }

        if (parentProductId) {
            dataProduct.groupId = parentProductId;
        }

        return dataProduct;
    }

    getProducts(items) {
        const products = [];
        for (let i = 0; i < items.length; i++) {
            const excludePartNos = [FREIGHT_PART_NO];
            if (!excludePartNos.includes(items[i].productId)) {
                products.push(this.getProduct(items[i], i));
            }
        }
        return products.filter((item) => item);
    }

    getImpressions(items) {
        const impressions = [];
        for (let i = 0; i < items.length; i++) {
            impressions.push(this.getImpression(items[i], i));
        }
        return impressions.filter((item) => item);
    }

    /**
     * Transform product data to Google Enhanced Ecommerce format.
     * Important: Only for Algolia products.
     *
     * @param {object} product - Algolia product object
     * @param {number|null} position
     */
    getImpression(product, position = null) {
        if (!product) {
            return;
        }

        const { categories, categoryPrimary, list, manufacturer, name, objectID, pricing } = product;

        const dataProduct = {
            name: name || '',
            id: objectID || '',
            price: pricing?.pricelists?.web && this.round(pricing.pricelists.web.exVat),
            brand: manufacturer?.name || '',
        };

        if (categoryPrimary && Array.isArray(categories) && categories.length > 0) {
            dataProduct.category = categories.find((c) => c.id === categoryPrimary)?.name || '';
        }

        if (list) {
            dataProduct.list = list;
        }

        if (position !== null) {
            dataProduct.position = position + 1;
        }

        return dataProduct;
    }

    round(amount) {
        return parseFloat(amount, 10);
    }
}

const instance = new DataLayer();

export default instance;
