import React from 'react';
import styled from '@grebban/style-system-react';
import Menu from 'assets/media/icons/Menu';
import { useTranslation } from 'react-i18next';
import Text from 'components/Text';
import { useHeaderNavigationContext } from '@sportson/core-web/contexts/HeaderNavigationContext';
import Cross from 'assets/media/icons/Cross';
import Breakpoint from '@sportson/core-web/components/Breakpoint';
import { Cart } from 'assets/media/icons/Cart';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { show as showOverlay } from '@sportson/core-web/state/application/overlay';
import CoreButton from '@grebban/react-core-components/Button';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import Cookies from 'js-cookie';
import { UserIcon } from 'assets/media/icons/User';
import { above } from '@sportson/core-web/utils/mediaqueries';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 20px;

    ${above['desktop.sm']} {
        gap: 30px;
    }
`;

const MenuButton = styled('button')`
    display: flex;
    gap: 8px;
    color: var(--color-base-white);

    &.open {
        color: var(--color-neutrals-400);
    }
`;

const AmountText = styled(Text)`
    position: absolute;
    top: -8px;
    right: -10px;
    min-width: 16px;
    padding: 2px;
    border-radius: 100px;
    background-color: var(--color-brand-yellow);
`;

const CartButton = styled(CoreButton)`
    position: relative;
`;
const MyAccountButton = styled(CoreButton)`
    position: relative;
`;

type Props = {
    isMenuOpen: boolean;
};

const Toolbox = ({ isMenuOpen = false }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { headerNavigationState, setHeaderNavigationState } = useHeaderNavigationContext();

    const items = useAppSelector(({ ecommerce }) => ecommerce.basket.items);
    const basketLoaded = useAppSelector(({ ecommerce }) => ecommerce.basket.status) === 'idle';

    const itt = items.reduce((acc, curr) => {
        acc += curr.quantity;
        return acc;
    }, 0);
    const itemsAmount = basketLoaded ? itt : Cookies.get('basket_items_amount') || 0;

    const isMobile = useBreakpoint({ to: 'desktop.sm' });
    const menuIsOpen = headerNavigationState.mobileMenuActive;

    const handleMobileMenuClick = () => {
        setHeaderNavigationState({
            ...headerNavigationState,
            mobileMenuActive: !menuIsOpen,
        });
    };

    const showBasket = () => {
        dispatch(
            showOverlay({
                view: 'basketOverlay',
                transition: isMobile ? 'from-left' : 'from-right',
            }),
        );
    };

    const showLoginOverlay = () => {
        dispatch(
            showOverlay({
                view: 'myAccountLoginOverlay',
                transition: isMobile ? 'from-left' : 'from-right',
            }),
        );
    };
    return (
        <Wrapper>
            {isMenuOpen && (
                <>
                    <MyAccountButton aria-label={t('aria_labels.login')} onClick={showLoginOverlay}>
                        <UserIcon color="var(--color-base-white)" width="14px" height="14px" />
                    </MyAccountButton>
                    <CartButton aria-label={t('aria_labels.open_basket')} onClick={showBasket}>
                        <Cart color="white" width="16px" height="16px" />
                        {itemsAmount > 0 && <AmountText typography="UI/12_100_0_450">{itemsAmount}</AmountText>}{' '}
                    </CartButton>
                </>
            )}
            <Breakpoint
                to="desktop.sm"
                render={
                    <MenuButton className={menuIsOpen ? 'open' : ''} onClick={() => handleMobileMenuClick()}>
                        {menuIsOpen ? (
                            <>
                                <Text typography="UI/16_100_0_450">{t('close')}</Text>
                                <Cross
                                    color={menuIsOpen ? 'var(--color-neutrals-400)' : ' var(--color-base-white)'}
                                    size="small"
                                />
                            </>
                        ) : (
                            <>
                                <Menu />
                                <Text typography="UI/16_100_0_450">{t('menu')}</Text>
                            </>
                        )}
                    </MenuButton>
                }
            />
        </Wrapper>
    );
};

export default Toolbox;
