import PropTypes from 'prop-types';
import React from 'react';
import styled from '@grebban/style-system-react';

const Ul = styled('ul')`
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1.6rem;

    ul,
    ol {
        list-style-position: inside;
        margin-left: 15px;
    }

    ul {
        list-style-type: circle;
    }

    ol {
        list-style-type: lower-latin;
    }
`;

const UnorderedList = ({ children }) => <Ul>{children}</Ul>;

UnorderedList.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UnorderedList;
