import React, { useEffect, useRef, useState, type InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from '@grebban/style-system-react';
import { type WordpressACFWysiwyg } from 'libs/wordpress/types/acf/fields';
import Text from 'components/Text';
import { type CssVariable } from 'config/branding/types';
import { type CssColorKey } from 'config/branding/colors';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';

const InputWrapper = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const Label = styled('label')`
    font-family: 'emeric';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-decoration: unset;

    display: flex;
    align-items: center;
    gap: 8px;
    &:hover {
        cursor: pointer;
    }
`;

const Input = styled('input', {
    shouldForwardProp: (prop) => ['checkboxColor'].indexOf(prop) === -1,
})`
    border-radius: 2px;
    width: 12px;
    height: 12px;

    color: var(--color-neutrals-400);
    border: 1px solid ${({ checkboxColor }) => checkboxColor};

    appearance: none;

    :hover {
        cursor: pointer;
    }

    &:checked {
        background-color: transparent;

        &::after {
            content: '';
            background-color: ${({ checkboxColor }) => checkboxColor};
            border-radius: 1px;
            height: calc(100% - 4px);
            display: block;
            margin-top: 2px;
            width: calc(100% - 4px);
            margin-left: 2px;
        }
    }
`;

const ErrorWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0 0;
`;

const ErrorMessage = styled(Text)`
    color: ${({ errorMsgColor }) => errorMsgColor || 'var(--text-color-error)'};
`;

type ValidityErrors = keyof ValidityState;

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'type'> {
    name: string;
    label: string | WordpressACFWysiwyg;
    translationsErrorPrefix?: string;
    customErrors?: Partial<Record<ValidityErrors, string>>;
    id?: string;
    checkboxColor?: CssVariable<CssColorKey>;
    errorMsgColor?: CssVariable<CssColorKey>;
}
const Checkbox = ({
    checked,
    required,
    label,
    name,
    translationsErrorPrefix = '',
    onBlur,
    customErrors = {},
    id,
    checkboxColor = 'var(--color-base-white)',
    errorMsgColor = 'var(--color-base-white)',
    ...props
}: InputProps) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [touched, setTouched] = useState<boolean>(false);
    const [inputErrors, setInputErrors] = useState<string[]>([]);
    const location = useLocation();

    useEffect(() => {
        setInputErrors([]);
    }, [location.pathname]);

    const validateInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!inputRef.current) return;

        const { validity } = ev.target;
        // https://www.w3schools.com/js/js_validation_api.asp
        // customError	    Set to true, if a custom validity message is set.
        // patternMismatch	Set to true, if an element's value does not match its pattern attribute.
        // rangeOverflow	Set to true, if an element's value is greater than its max attribute.
        // rangeUnderflow	Set to true, if an element's value is less than its min attribute.
        // stepMismatch	    Set to true, if an element's value is invalid per its step attribute.
        // tooLong	        Set to true, if an element's value exceeds its maxLength attribute.
        // typeMismatch	    Set to true, if an element's value is invalid per its type attribute.
        // valueMissing	    Set to true, if an element (with a required attribute) has no value.
        // valid	        Set to true, if an element's value is valid.

        const validityStateKeys: string[] = [];
        const errors: string[] = [];

        for (const error in validity) {
            if (error === 'customError' || error === 'valid') {
                continue;
            }
            validityStateKeys.push(error);
            if (validity[error]) {
                // @ts-expect-error: Reason:  Temp remove this since it throws an error but not in other projects with the same code.
                const customError = customErrors[error] ?? t(`${translationsErrorPrefix}${name}.${error}`);
                errors.push(customError);
            }
        }

        // Since we don't have any errors, the input should be valid.
        if (errors.length) {
            const firstErrorIsCustomMessage = validityStateKeys.indexOf(errors[0]) === -1;
            inputRef.current.setCustomValidity(
                firstErrorIsCustomMessage ? errors[0] : inputRef.current.validationMessage,
            );
            setInputErrors(errors);
            return;
        }

        inputRef.current.setCustomValidity('');
        setInputErrors([]);
    };

    return (
        <InputWrapper touched={touched} hasErrors={!!inputErrors.length}>
            <Label touched={touched} hasErrors={!!inputErrors.length} htmlFor={id ?? name}>
                <Input
                    checkboxColor={checkboxColor}
                    touched={touched}
                    hasErrors={!!inputErrors.length}
                    {...props}
                    ref={inputRef}
                    required={required}
                    type="checkbox"
                    checked={checked}
                    name={name}
                    id={id ?? name}
                    onBlur={(ev) => {
                        setTouched(true);
                        validateInput(ev);
                        onBlur?.(ev);
                    }}
                    onInvalid={validateInput}
                />
                {/* TODO: NOTE: Solution to not break PROD when WYSIWYG object is passed */}
                {/* TODO: NOTE: included links (a-tags) should open in new window when used in wysiwyg? */}
                <Wysiwyg
                    data={label}
                    TextComponent={(props) => <Text typography="Content/14_130_0_350" {...props} />}
                />
            </Label>
            {inputErrors.length > 0 && (
                <ErrorWrapper>
                    {inputErrors.map((error) => (
                        <ErrorMessage
                            key={error}
                            $as="p"
                            typography="UI/12_100_0_450_uppercase"
                            errorMsgColor={errorMsgColor}
                        >
                            {error}
                        </ErrorMessage>
                    ))}
                </ErrorWrapper>
            )}
        </InputWrapper>
    );
};

export default Checkbox;
