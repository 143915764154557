import PropTypes from 'prop-types';
import React from 'react';
import Text from 'components/Text';

const Heading = ({ type, children }) => (
    <Text
        $as={type}
        typography={[
            'Primary/Headlines/24-120-mobile',
            null,
            'Primary/Headlines/32-110-tablet',
            null,
            'Primary/Headlines/32-110-desktop',
        ]}
        mb={['40px', null, null, '48px']}
    >
        {children}
    </Text>
);

Heading.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
};

export default Heading;
