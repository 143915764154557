import { combineReducers } from '@reduxjs/toolkit';
import { reducer as basket } from './basket';
import { reducer as checkout } from './checkout';

// import { reducer as markets } from './markets';
// import { reducer as pricelists } from './pricelists';
// import { reducer as countries } from './countries';
// import { reducer as orders } from './orders';
// import { reducer as shipping } from './shipping';

export const reducers = {
    basket,
    checkout,
    // countries,
    // shipping,
    // markets,
    // pricelists,
    // orders,
};
export const reducer = combineReducers({
    basket,
    // countries,
    // shipping,
    // markets,
    // pricelists,
    // orders,
});
