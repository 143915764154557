import BackgroundColor, { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import BackgroundImage, { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import BackgroundVideo, { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import React from 'react';

export interface BackgroundProps {
    backgroundVideo?: BackgroundVideoProps;
    backgroundImage?: BackgroundImageProps;
    backgroundColor?: BackgroundColorProps;
    children?: React.ReactNode | typeof React.Children;
}

export type BackgroundPropsBackgrounds = Exclude<keyof BackgroundProps, 'children'>;

const Background = ({ backgroundVideo, backgroundImage, backgroundColor, ...rest }: BackgroundProps) => {
    if (backgroundVideo?.src) {
        return <BackgroundVideo {...backgroundVideo} {...rest} />;
    }

    if (backgroundImage?.src) {
        return <BackgroundImage {...backgroundImage} {...rest} />;
    }

    if (backgroundColor) {
        return <BackgroundColor {...backgroundColor} {...rest} />;
    }

    return null;
};

export default Background;
