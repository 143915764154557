const isBrowser = typeof window !== 'undefined';
const isIOS = isBrowser && window.CSS && CSS.supports('-webkit-overflow-scrolling: touch');
/**
 * Scroll to a specified position on the page
 *
 * @version 1.0
 * @param {Object} options - Object with options
 * @param {boolean} options.smooth - Specifies whether the scrolling should animate smoothly, or happen instantly in a single jump.
 * @param {number} options.left - Specifies the number of pixels along the X axis to scroll the window.
 * @param {number} options.top - Specifies the number of pixels along the Y axis to scroll the window.
 */

interface scrollToOptions {
    smooth: boolean;
    left: number;
    top: number;
}

export const scrollTo = ({ smooth = true, left = 0, top = 0 }: scrollToOptions): void => {
    if (isBrowser) {
        if (document.documentElement && 'scrollBehavior' in document.documentElement.style) {
            window.scrollTo({
                behavior: smooth !== false ? 'smooth' : 'auto',
                left,
                top,
            });
        } else {
            window.scrollTo(left, top);
        }
    }
};

/**
 * Scroll lib to prevent scroll on all browsers.
 * Replacement for preventScroll in GC's application model.
 *
 * @author Timmie Sarjanen & Kristoffer Forsgren
 * @version 1.1.1
 */

const DATA_ATTR = 'data-prevent-scroll';

export const isScrollAllowed = (): boolean => document.body.getAttribute(DATA_ATTR) === null;

export const preventScroll = (): void => {
    if (!isBrowser || !isScrollAllowed()) {
        return;
    }

    const { body } = document;
    const scrollTop = window.pageYOffset;
    const scrollbarGap = window.innerWidth - document.documentElement.clientWidth;

    body.setAttribute(DATA_ATTR, 'true');

    if (isIOS) {
        if (scrollTop) {
            body.style.top = `-${scrollTop}px`;
        }
        body.style.position = 'fixed';
        body.style.width = '100%';
    }
    body.style.overflow = 'hidden';

    if (scrollbarGap > 0) {
        body.style.paddingRight = `${scrollbarGap}px`;
    }
};

export const allowScroll = (): void => {
    if (isBrowser && !isScrollAllowed()) {
        const { body } = document;
        const scrollTop = Math.abs(parseInt(body.style.top, 10));

        if (isIOS) {
            // Had to set this timeout to solve problem of page always scrolling to the top when allowing scroll
            // But some times you can se the top of the page for a fraction of a second before it scrolls back
            // to the correct position, wich is not ideal, but better than the alternative for now.
            setTimeout(() => {
                if (scrollTop) {
                    body.style.removeProperty('top');
                }
            }, 1);
            body.style.removeProperty('position');
            body.style.removeProperty('width');
            window.scrollTo(0, scrollTop);
        }

        body.style.removeProperty('overflow');
        body.style.removeProperty('padding-right');

        body.removeAttribute(DATA_ATTR);
    }
};

export default {
    scrollTo,
    allowScroll,
    isScrollAllowed,
    preventScroll,
};
