import styled, { stylesToResponsiveCssPropsByKeys } from '@grebban/style-system-react';
import brandingTypography, { type Typography } from 'config/branding/typography';
import { type ButtonTheme, STYLES, THEMES } from 'config/branding/buttons';
import CoreButton from '@grebban/react-core-components/Button';
import React from 'react';

const StyledButton = styled(CoreButton)`
    border-radius: 48px;
    gap: 6px;
    background-color: ${({ buttonTheme }) => buttonTheme.backgroundColor};
    color: ${({ buttonTheme }) => buttonTheme.color};
    border: ${({ buttonTheme }) => buttonTheme.border};
    white-space: pre;
    text-transform: ${({ textTransform }) => textTransform || 'none'};
    padding: ${({ size }) => (size === 'xsmall' && '12px') || (size === 'small' && '10px 16px') || '12px 16px'};

    transition: all var(--transition-primary-fast);

    & > span {
        line-height: ${({ lineHeight }) => lineHeight ?? '100%'};
    }

    svg:not(.loading-icon) path {
        stroke: ${({ buttonTheme }) => buttonTheme.color};
        fill: ${({ buttonTheme }) => buttonTheme.color};
    }

    &:hover {
        background-color: ${({ buttonTheme }) => buttonTheme.hover?.backgroundColor ?? buttonTheme.backgroundColor};
        color: ${({ buttonTheme }) => buttonTheme.hover?.color ?? buttonTheme.color};
        border: ${({ buttonTheme }) => buttonTheme.hover?.border ?? buttonTheme.border};

        svg:not(.loading-icon) path {
            fill: ${({ buttonTheme }) => buttonTheme.hover?.color || buttonTheme.color};
            stroke: ${({ buttonTheme }) => buttonTheme.hover?.color || buttonTheme.color};
        }
    }

    &:active {
        background-color: ${({ buttonTheme }) => buttonTheme.active?.backgroundColor ?? buttonTheme.backgroundColor};
        color: ${({ buttonTheme }) => buttonTheme.active?.color ?? buttonTheme.color};
        border: ${({ buttonTheme }) => buttonTheme.active?.border ?? buttonTheme.border};

        svg:not(.loading-icon) path {
            fill: ${({ buttonTheme }) => buttonTheme.active?.color || buttonTheme.color};
            stroke: ${({ buttonTheme }) => buttonTheme.active?.color || buttonTheme.color};
        }
    }

    &:disabled {
        background-color: ${({ buttonTheme }) => buttonTheme.disabled?.backgroundColor ?? buttonTheme.backgroundColor};
        color: ${({ buttonTheme }) => buttonTheme.disabled?.color ?? buttonTheme.color};
        border: ${({ buttonTheme }) => buttonTheme.disabled?.border ?? buttonTheme.border};
        pointer-events: none;
        cursor: not-allowed;

        svg:not(.loading-icon) path {
            fill: ${({ buttonTheme }) => buttonTheme.disabled?.color ?? buttonTheme.color};
        }

        &:hover {
            background-color: ${({ buttonTheme }) =>
                buttonTheme.disabled?.backgroundColor ?? buttonTheme.backgroundColor};
            color: ${({ buttonTheme }) => buttonTheme.disabled?.color ?? buttonTheme.color};
            border: ${({ buttonTheme }) => buttonTheme.disabled?.border ?? buttonTheme.border};
        }
    }

    &:focus-visible {
        outline: none;
        border: ${({ buttonTheme }) => buttonTheme.focusOutlineColor};
    }
`;

export interface ButtonProps extends Record<string, unknown> {
    children: React.ReactNode | typeof React.Children | string;
    disabled?: boolean;
    fullWidth?: boolean;
    loading?: boolean;
    theme?: ButtonTheme;
    url?: string;
    rightIcon?: React.ReactNode;
    size?: 'xsmall' | 'small' | 'large';
    typography?: Typography | Array<Typography | '' | null>;
}

const Button = ({
    children,
    disabled = false,
    fullWidth = false,
    loading = false,
    type = 'button',
    theme = 'primary',
    url = '',
    typography = 'UI/14_100_0_450',
    size = 'large',
    rightIcon,
    ...rest
}: ButtonProps) => {
    if (size === 'small') {
        typography = 'UI/12_100_0_450';
    }

    return (
        <StyledButton
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- The style package is actually incorrect typed, it should be "string | Array<string | null>" and not only strings.
            // @ts-ignore
            {...stylesToResponsiveCssPropsByKeys(typography, brandingTypography)}
            type={type}
            style={STYLES}
            disabled={disabled}
            buttonTheme={THEMES[theme]}
            to={url}
            width={fullWidth ? '100%' : 'fit-content'}
            size={size}
            {...rest}
        >
            {children}
            {rightIcon}
        </StyledButton>
    );
};

export default Button;
