import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from '@grebban/style-system-react';
import { useHeaderNavigationContext } from '@sportson/core-web/contexts/HeaderNavigationContext';
import { above } from '@sportson/core-web/utils/mediaqueries';
import Breakpoint from '@sportson/core-web/components/Breakpoint';
import CoreButton from '@grebban/react-core-components/Button';
import Text from 'components/Text';
import Cross from 'assets/media/icons/Cross';
import { useLocation } from 'react-router-dom';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import CrawlerLinks from './CrawlerLinks';

const Div = styled('div')``;
const MenuBar = styled('nav')`
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    padding: 0px;
    ${above['desktop.sm']} {
        padding: 0 28px 8px 28px;
        &.menu-active {
            background-color: var(--color-base-white);
            border-bottom: 1px solid var(--color-neutrals-200);
            box-shadow: 0px -8px 0px 0px var(--color-base-white);

            & button:not(.active) {
                color: var(--color-base-black);
            }
        }
    }
`;

const MenuItem = styled(CoreButton)`
    padding: 8px 12px;
    height: 32px;
    color: ${({ menuOpen }) => (menuOpen ? 'var(--color-base-black)' : 'var(--color-base-white)')};

    border-radius: 4px;

    &:hover {
        color: ${({ menuOpen }) => (menuOpen ? 'var(--color-neutrals-500)' : 'var(--color-neutrals-300)')};
        cursor: pointer;
    }

    &.active {
        background-color: var(--color-base-black);
        color: var(--color-base-white);
    }
`;

const NavigationBackdrop = styled(`div`)`
    height: calc(100vh - var(--header-height-total));
    position: fixed;
    top: var(--header-height-total);
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-base-black);
    opacity: 0.3;
    z-index: 10;
    display: ${({ menuOpen }) => (menuOpen ? 'block' : 'none')};
`;

const CloseButton = styled(`button`)`
    display: flex;
    align-items: center;
    gap: 4px;
`;

interface IMenuLink {
    url: string;
    text: string;
}

const Nav = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const { headerNavigationState, setHeaderNavigationState } = useHeaderNavigationContext();

    const menuLinks = useSelector(({ application }) => application.header.data.navigation?.categories?.mainNavigation);
    const guideCustomPages = useSelector(
        ({ application }) => application.header.data.navigation?.categories?.pageLinks,
    );
    const { links } = useSelector(({ application }) => application.header.data.navigation?.rightNavigation) || {};
    const menuOpen = !!headerNavigationState.activeSubMenu.length;

    const handleMenuItemClick = (linkText: string) => {
        if (headerNavigationState.activeSubMenu === linkText) {
            setHeaderNavigationState({
                ...headerNavigationState,
                activeSubMenu: '',
                desktopMenuActive: false,
            });
        } else {
            setHeaderNavigationState({
                ...headerNavigationState,
                activeSubMenu: linkText,
                desktopMenuActive: true,
            });
        }
    };

    const handleClose = () => {
        setHeaderNavigationState({
            ...headerNavigationState,
            activeSubMenu: '',
            desktopMenuActive: false,
        });
    };

    useEffect(() => {
        handleClose();
    }, [location]);

    return (
        <MenuBar className={menuOpen ? 'menu-active' : ''}>
            <div style={{ display: 'none' }}>
                <CrawlerLinks menuItems={menuLinks} />
            </div>
            <Breakpoint
                from="desktop.sm"
                render={
                    <>
                        <Div display="flex" gap="8px">
                            {menuLinks &&
                                menuLinks.map(({ link, links }) => (
                                    <MenuItem
                                        menuOpen={menuOpen}
                                        key={`${link.text}-${link.url}`}
                                        tabIndex={0}
                                        className={headerNavigationState.activeSubMenu === link.text ? 'active' : ''}
                                        onClick={links.length > 0 ? () => handleMenuItemClick(link.text) : null}
                                        to={links.length > 0 ? undefined : link.url}
                                    >
                                        <Text typography="UI/16_100_0_450">{link.text}</Text>
                                    </MenuItem>
                                ))}
                        </Div>

                        {menuOpen && (
                            <CloseButton aria-label={t('aria_labels.close')} onClick={handleClose}>
                                <Text typography="UI/14_100_0_450">{t('close')}</Text> <Cross size="xsmall" />
                            </CloseButton>
                        )}
                        {!!menuLinks && (
                            <DesktopMenu
                                menuOpen={menuOpen}
                                menuLinks={menuLinks}
                                guideCustomPages={guideCustomPages}
                            />
                        )}
                        <NavigationBackdrop menuOpen={headerNavigationState.desktopMenuActive} onClick={handleClose} />
                    </>
                }
            />
            <Breakpoint
                to="desktop.sm"
                render={
                    <MobileMenu
                        menuOpen={headerNavigationState.mobileMenuActive}
                        menuLinks={menuLinks}
                        guideCustomPages={guideCustomPages}
                        rightLinks={links}
                    />
                }
            />
        </MenuBar>
    );
};

export default Nav;
