import { reducer as config } from './config';
import { reducer as footer } from './footer';
import { reducer as header } from './header';
import { reducer as notifications } from './notifications';
import { reducer as overlay } from './overlay';
import { reducer as popups } from './popups';
import { reducer as scroll } from './scroll';
import { reducer as site } from './site';

export const reducers = {
    config,
    footer,
    header,
    notifications,
    overlay,
    popups,
    scroll,
    site,
};
