import React, { useEffect, useRef, useMemo } from 'react';
import Wrapper from 'Popups/Wrapper';
import { shallowEqual } from 'react-redux';
import loadable from '@loadable/component';
import Loading from 'components/Loading';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import { TransitionGroup, Transition } from 'react-transition-group';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import { close as closePopup } from '@sportson/core-web/state/application/popups';
import { DEFAULT_POPUP_TIMEOUT_SECONDS } from '@sportson/core-web/state/models/Popups/constants';

// This is where we add new popups
const popupViews = {
    examplePopup: loadable(() => import('Popups/views/Example'), { fallback: <Loading /> }),
    addToCartPopup: loadable(() => import('Popups/views/AddToCartPopup'), { fallback: <Loading /> }),
};

const Popups = () => {
    const dispatch = useAppDispatch();
    const view = useAppSelector(({ application }) => application?.popups?.view || '');
    const transition = useAppSelector(({ application }) => application?.popups?.transition || '');
    const data = useAppSelector(({ application }) => application?.popups?.data || {}, shallowEqual);
    const seconds = useAppSelector(({ application }) => application?.popups?.seconds ?? DEFAULT_POPUP_TIMEOUT_SECONDS);
    const reference = useRef(null);

    // Get the content component that should be used for the active popup
    const PopupComponent = popupViews?.[view];

    useEffect(() => {
        if (view) {
            const timer = setTimeout(() => {
                dispatch(closePopup());
            }, seconds * 1000);

            return () => clearTimeout(timer);
        }
    }, [view, seconds, dispatch]);

    return (
        <TransitionGroup>
            {PopupComponent && (
                <Transition nodeRef={reference.current} in={!!PopupComponent} timeout={{ enter: 0, exit: 300 }}>
                    {(transitionState) => (
                        <Wrapper ref={reference} transition={transition} showing={transitionState === 'entered'}>
                            <PopupComponent {...data} />
                        </Wrapper>
                    )}
                </Transition>
            )}
        </TransitionGroup>
    );
};

export default Popups;
