import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Events from '@sportson/core-web/libs/Events';
import { CheckoutEvents } from '@sportson/core-web/libs/Events/constants';
import GetCheckout from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/GetCheckout';
import getBasketId from '@sportson/core-web/state/ecommerce/norce/utils/getBasketId';
import buildCartProductTree from '@sportson/core-web/utils/buildCartProductTree';
import getErrorMesage from '@sportson/core-web/utils/getErrorMessage';
import { initialState } from './index';

export interface GetCheckoutData {
    basketId?: string;
}

export default createAsyncThunk(
    `checkout/get`,
    async (data: GetCheckoutData = {}, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
        let { basketId } = data;

        if (!basketId) {
            basketId = getBasketId();
        }

        if (!basketId) {
            return rejectWithValue({ error: 'BasketId is not set.' });
        }

        const mutexLock = await mutex('basket');

        try {
            const response = await GetCheckout(basketId);

            const responseIsOk = await response.ok();

            if (!responseIsOk) {
                throw await response.status();
            }

            mutexLock();
            const responseBody = await response.body();
            const { basket, ...checkout } = responseBody.data;
            return fulfillWithValue({ ...checkout, basket: { ...basket, items: buildCartProductTree(basket.items) } });
        } catch (error) {
            mutexLock();
            console.log({ error });
            return rejectWithValue({ error: getErrorMesage(error) });
        }
    },
);

const pending = (state, action) => {
    state.status = 'pending';
};

const fulfilled = (state, action) => {
    const checkout = objectKeysToCamelCase(action.payload, {
        recursive: true,
        modifyInputObject: false,
    });

    const basketId = checkout.basket.id;
    const basketItems = checkout.basket.items;

    try {
        Events.trigger(CheckoutEvents.VIEW, { basketId, items: basketItems });
    } catch (e) {
        //
    }
    return { ...state, ...checkout, status: 'idle' };
};

const rejected = (state, action) => {
    console.error(action.payload);

    return {
        ...initialState,
        status: 'idle',
    };
};

export const getCheckoutStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
