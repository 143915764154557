import styled from '@grebban/style-system-react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';

import Text from 'components/Text';
import Row from '@sportson/core-web/App/layouts/Row';
import Link from 'components/Link';
import { type WordpressFooterLinks } from 'libs/wordpress/types/contentSystem/groups';

import Accordion from 'components/Accordion';

const LinkSection = styled('div')`
    display: flex;
    flex-direction: column;
`;

const AccordionWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

type NavigationLinks = WordpressFooterLinks;

export const NavigationLinks = ({ linkSections }: NavigationLinks) => {
    const isDesktop = useBreakpoint({ from: 'tablet.lg' });
    if (!linkSections || !linkSections?.length) {
        return null;
    }

    if (isDesktop) {
        return (
            <Row gap="24px">
                {linkSections.map(({ heading, links }) => {
                    const sectionWidth = 100 / linkSections.length;
                    return (
                        <LinkSection key={heading} width={`${sectionWidth}%`}>
                            <Text
                                as="h3"
                                textAlign="left"
                                color="var(--color-brand-yellow)"
                                typography="UI/12_100_0_450_uppercase"
                                marginBottom="8px"
                            >
                                {heading}
                            </Text>

                            {links.map(({ link: { text, url } }) => (
                                <Link noUnderline isVolatile key={text} to={url} width="fit-content">
                                    <Text
                                        as="p"
                                        textAlign="left"
                                        color="var(--text-color-white)"
                                        typography="UI/16_100_0_450"
                                        display="flex"
                                        alignItems="center"
                                        height="32px"
                                    >
                                        {text}
                                    </Text>
                                </Link>
                            ))}
                        </LinkSection>
                    );
                })}
            </Row>
        );
    }

    return (
        <AccordionWrapper>
            {linkSections.map(({ heading, links }, i) => {
                const numOfSections = linkSections.length;
                const displayBorderBottom = numOfSections === i + 1;
                return (
                    <Accordion
                        wrappingLabel
                        hideBorder
                        closeOnLabelClick
                        label={heading}
                        key={heading}
                        color="var(--color-brand-yellow)"
                        borderBottom={displayBorderBottom ? '1px solid var(--color-neutrals-600)' : 'none'}
                    >
                        <LinkSection width="100%" textAlign="left">
                            {links.map(({ link: { text, url } }) => (
                                <Link noUnderline isVolatile key={text} to={url}>
                                    <Text
                                        as="p"
                                        textAlign="left"
                                        color="var(--text-color-white)"
                                        typography="UI/16_100_0_450"
                                        display="flex"
                                        alignItems="center"
                                        height="32px"
                                    >
                                        {text}
                                    </Text>
                                </Link>
                            ))}
                        </LinkSection>
                    </Accordion>
                );
            })}
        </AccordionWrapper>
    );
};
