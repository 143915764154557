import Gtm from '@grebban/gtm';
import { getModel } from '@sportson/core-web/state';
import { hashString } from '@sportson/core-web/utils/hashString';
import { AFFILIATION, SHIPPING_COST } from 'config/constants';
import DataLayer from './DataLayer';

export default async (props) => {
    try {
        const { promotions, orderNumber: id, items, summary, email, payerId, customer } = props;
        const { cellPhone } = customer;

        const application = getModel('application');
        const {
            handling_cost: handlingCost = '0',
            packing_material: packingMaterial = '0',
            fallback_shipping_cost: fallbackShippingCost = '0',
            transaction_cost_percentage: transactionCostPercentage = '1.2',
        } = getModel('page').data?.checkout_response?.fulfillment_costs || {};
        const floatHandlingCost = parseFloat(handlingCost, 10);
        const floatPackingMaterial = parseFloat(packingMaterial, 10);
        const floatFallbackShippingCost = parseFloat(fallbackShippingCost, 10);
        const floatTransactionCostPercentage = parseFloat(transactionCostPercentage, 10);

        const { items: itemsSummary, freight, handling_fee: handlingFee } = summary || {};
        const { amount: itemsExVat, vat: itemsVat } = itemsSummary || {};
        const { amount: shippingExVat, vat: shippingVat } = freight || {};
        const { amount: handlingFeeExVat } = handlingFee || {};

        const revenue = DataLayer.round(itemsExVat + itemsVat + shippingExVat + shippingVat);
        const revenueExVat = DataLayer.round(itemsExVat + shippingExVat);

        const shipping = DataLayer.round(shippingExVat + shippingVat);
        const tax = DataLayer.round(itemsVat + shippingVat);

        const coupons = promotions.filter((promotion) =>
            promotion.requirement_seed
                ? promotion.requirement_seed.includes('10') || promotion.requirement_seed.includes('22')
                : false,
        );

        const lowerCaseEmail = email?.toLowerCase();
        const hashedPhoneNumber = await hashString(cellPhone, 'SHA-256');
        const hashedPayerEmailSHA1 = await hashString(email, 'SHA-1');
        const hashedEmailSHA256 = await hashString(lowerCaseEmail, 'SHA-256');
        const hashedPhoneNumberSHA256 = await hashString(cellPhone, 'SHA-256');

        const transactionCost = revenue * (floatTransactionCostPercentage / 100);

        const highestOrderShippingCost = items.reduce((highestShippingCost, basketItem) => {
            const parametrics = basketItem.format?.parametrics || [];
            const shippingCost = parametrics.find((parametric) => parametric.code === SHIPPING_COST);
            const shippingCostValue = (shippingCost?.value || '0').replace(',', '.');
            const shippingCostAsFloat = parseFloat(shippingCostValue, 10);

            return shippingCostAsFloat > highestShippingCost ? shippingCostAsFloat : highestShippingCost;
        }, 0);

        const shippingCost = highestOrderShippingCost > 0 ? highestOrderShippingCost : floatFallbackShippingCost;

        const orderFulfillmentCost =
            floatHandlingCost +
            floatPackingMaterial +
            transactionCost +
            shippingCost -
            shippingExVat -
            handlingFeeExVat;

        Gtm.addDataLayerEvent('transaction', {
            ecommerce: {
                purchase: {
                    actionField: {
                        id,
                        payerId,
                        affiliation: AFFILIATION,
                        revenue,
                        revenueExVat,
                        shipping,
                        shippingExVat,
                        tax,
                        itemsVat,
                        shippingVat,
                        coupon: coupons.length > 0 ? coupons.map((c) => c.discount_code).join('|') : '',
                    },
                    products: DataLayer.getProducts(items),
                },
            },
            currency: application.site.currency.code,
            orderFulfillmentCost,
            hashedPhoneNumber,
            hashedEmailSHA256,
            hashedPhoneNumberSHA256,
            hashedPayerEmailSHA1,
            // hashedUserEmailSHA1,
            // userId: userId || userIdCookie,
        });
    } catch (e) {
        //
    }
};
