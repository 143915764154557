import React from 'react';
import styled, { stylesToResponsiveCssPropsByKeys } from '@grebban/style-system-react';
import { type ButtonTheme, STYLES, THEMES } from 'config/branding/buttons';
import brandingTypography, { type Typography } from 'config/branding/typography';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';

const StyledButton = styled('button', {
    shouldForwardProp: (prop: string) => ['textTransform', 'lineHeight'].indexOf(prop) === -1,
})`
    border-radius: 48px;
    background-color: ${({ theme }) => theme?.backgroundColor};
    color: ${({ theme }) => theme?.color};
    border: ${({ theme }) => theme?.border};
    white-space: pre;
    text-transform: ${({ textTransform }) => textTransform || 'none'};

    & > span {
        line-height: ${({ lineHeight }) => lineHeight || '100%'};
    }

    svg:not(.loading-icon) path {
        fill: ${({ theme }) => theme.color};
        stroke: ${({ theme }) => theme.color};
        transition: stroke var(--transition-primary);
    }

    &:hover {
        background-color: ${({ theme }) => theme.hover?.backgroundColor || theme.backgroundColor};
        color: ${({ theme }) => theme.hover?.color || theme.color};
        border: ${({ theme }) => theme.hover?.border || theme.border};

        svg:not(.loading-icon) path {
            fill: ${({ theme }) => theme.hover?.color || theme.color};
            stroke: ${({ theme }) => theme.hover?.color || theme.color};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.active?.backgroundColor || theme.backgroundColor};
        color: ${({ theme }) => theme.active?.color || theme.color};
        border: ${({ theme }) => theme.active?.border || theme.border};

        svg:not(.loading-icon) path {
            stroke: ${({ theme }) => theme.active?.color || theme.color};
            fill: ${({ theme }) => theme.active?.color || theme.color};
        }
    }

    &:disabled {
        background-color: ${({ theme }) => theme.disabled?.backgroundColor || theme.backgroundColor};
        color: ${({ theme }) => theme.disabled?.color || theme.color};
        border: ${({ theme }) => theme.disabled?.border || theme.border};
        pointer-events: none;
        cursor: not-allowed;

        svg:not(.loading-icon) path {
            fill: ${({ theme }) => theme.disabled?.color || theme.color};
            stroke: ${({ theme }) => theme.disabled?.color || theme.color};
        }

        &:hover {
            background-color: ${({ theme }) => theme.disabled?.backgroundColor || theme.backgroundColor};
            color: ${({ theme }) => theme.disabled?.color || theme.color};
            border: ${({ theme }) => theme.disabled?.border || theme.border};
        }
    }
`;

export interface ButtonProps extends Record<string, unknown> {
    children: React.ReactNode | typeof React.Children | string;
    disabled?: boolean;
    fullWidth?: boolean;
    loading?: boolean;
    theme?: ButtonTheme;
    typography?: Typography | Array<Typography | '' | null>;
}

export const SubmitButton = ({
    children = null,
    disabled = false,
    fullWidth = false,
    loading = false,
    theme = 'primary',
    typography = 'UI/16_100_0_450',
    ...rest
}: ButtonProps) => (
    <StyledButton
        // @TODO The style package is actually incorrect typed, it should be "string | Array<string | null>" and not only strings.
        // @ts-expect-error: Reason:  See above.
        {...stylesToResponsiveCssPropsByKeys(typography, brandingTypography)}
        type="submit"
        style={STYLES}
        disabled={disabled}
        theme={THEMES[theme]}
        width={fullWidth ? '100%' : 'fit-content'}
        p="12px 16px"
        {...rest}
    >
        <>
            {children}
            {!loading && <ArrowIcon color={disabled ? 'var(--color-neutrals-500)' : 'var(--color-base-black)'} />}
        </>
    </StyledButton>
);
