import { useMediaQuery } from 'react-responsive';
import { type MediaQueryFeatures } from 'react-responsive/types/types';
import isObject from '@grebban/utils/object/isObject';
import { getPrevBreakpoint } from '@sportson/core-web/utils/mediaqueries';
import { type ValidBreakpointLabels, getBreakpoint } from '@sportson/core-web/config/breakpoints';

type RenderBreakpoint =
    | ValidBreakpointLabels
    | {
          from?: ValidBreakpointLabels;
          to?: ValidBreakpointLabels;
      };
export type UseBreakpointOptions = Omit<MediaQueryFeatures, 'minWidth' | 'maxWidth'>;

const useBreakpoint = (renderBreakpoint: RenderBreakpoint, options: UseBreakpointOptions = {}) => {
    const mediaQuery: MediaQueryFeatures = {};

    if (typeof renderBreakpoint === 'string') {
        const breakpoint = getBreakpoint(renderBreakpoint);
        if (breakpoint) {
            mediaQuery.minWidth = breakpoint.from;
            mediaQuery.maxWidth = breakpoint.to;
        }
    } else if (isObject(renderBreakpoint)) {
        if (renderBreakpoint.from) {
            const breakpoint = getBreakpoint(renderBreakpoint.from);
            if (breakpoint) {
                mediaQuery.minWidth = breakpoint.from;
            }
        }
        if (renderBreakpoint.to) {
            const breakpoint = getPrevBreakpoint(renderBreakpoint.to);
            if (breakpoint) {
                mediaQuery.maxWidth = breakpoint.to;
            }
        }
    }

    return useMediaQuery({ ...options, ...mediaQuery });
};

export default useBreakpoint;
