import PropTypes from 'prop-types';
import React from 'react';
import styled from '@grebban/style-system-react';

const Strong = styled('strong')`
    font-weight: 500;
`;

const Bold = ({ children }) => <Strong>{children}</Strong>;

Bold.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Bold;
