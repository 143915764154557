import { post } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';

export default async (productId, categoryId) => {
    const response = await post(`/frontend/product/id/${productId}/view`, {
        application_id: getModel('application').site.applicationId,
        category_id: categoryId,
    });

    return await response.body();
};
