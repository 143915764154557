export const toHash = (str: string): string => {
    let hash = 5381;
    let i = str.length;

    while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i);
    }

    return (hash >>> 0).toString(36);
};

export const toSnakeCase = (s: string) => s.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const toKebabCase = (s: string) => s.replace(/\s+/g, '-').toLowerCase().replace(/[åä]/g, 'a').replace(/ö/g, 'o');

export const stringReplace = (s: string, replace: Record<string, string>) => {
    Object.keys(replace).forEach((key) => {
        const re = new RegExp(key, 'g');
        s = s.replace(re, replace[key]);
    });
    return s;
};

export const jsonParse = (s: any) => {
    let result = s;
    try {
        if (typeof s === 'string') {
            result = JSON.parse(s);
        }
    } catch (e) {
        console.error(e);
    }

    return result;
};

export const toDoubleDigitString = (n: number) => (n > 9 ? `${n}` : `0${n}`);
export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const endWithSlah = (string: string) => (string.charAt(string.length - 1) === '/' ? string : `${string}/`);

export const randomKey = () => Math.random().toString(36).substring(7);

/**
 * Can be used to format "type: text"-fields from cms when you want to enable cms user to replace a word with something else.
 * Text example in cms text-field: Your search for "{search}" returned no hits.
 *
 * @param {string} text - Text from cms
 * @param {string} replace - Word to replace. {search} in the example above
 * @param {string} replacement - Word to replace with. query in the example above.
 */

export const searchAndReplaceString = (text: string, replace: string, replacement: string) =>
    text?.replace(replace, replacement).replace(/\\/g, '');
