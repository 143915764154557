const decodeHTMLEntities = function (text) {
    if (!text) {
        return null;
    }
    const entities = [
        ['&amp;', '&'],
        ['&#038;', '&'],
        ['&apos;', "'"],
        ['&#x27;', "'"],
        ['&#x2F;', '/'],
        ['&#39;', "'"],
        ['&#8217;', "'"],
        ['&#47;', '/'],
        ['&lt;', '<'],
        ['&gt;', '>'],
        ['&nbsp;', ' '],
        ['&quot;', '"'],
        ['&ndash;', '-'],
        ['&#8211;', '-'],
        ['&shy;', ''],
        ['&%C3%A5;', 'å'],
        ['&%C3%A4;', 'ä'],
        ['&%C3%B6;', 'ö'],
        ['\\\\"', '"'],
    ];
    for (let i = 0, max = entities.length; i < max; ++i) {
        text = text.replace(new RegExp(`${entities[i][0]}`, 'g'), entities[i][1]);
    }
    return text;
};

export default decodeHTMLEntities;
